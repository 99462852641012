import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { NavComponent, FooterComponent } from "../components";

function NotFoundLayout() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="bg-[--dark]">
        <NavComponent />
      </div>
      <div className="dark:bg-[--dark]">
        <div className="mainContainer min-h-screen mainContainer">
          {/* <NavComponent /> */}
          <div className="">
            <Outlet />
          </div>
          {/* <FooterComponent /> */}
        </div>
      </div>
      <div className="bg-[--dark]">
        <FooterComponent />
      </div>
    </>
  );
}

export default NotFoundLayout;
