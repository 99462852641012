import React, { useState, memo } from "react";
import { AuctionCardProps } from "../../type/Auction.type";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
import Services from "../../services";
import moment from "moment";
import { useTranslation } from "react-i18next";

const AuctionCardCommentComponent = memo(
  ({ getListings, item }: AuctionCardProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const role = Services.Storage.getCurrentRole();
    const [loading, setLoading] = useState<boolean>(false);

    const addToWatchList = () => {
      setLoading(true);
      const data = {
        listing_id: item.id,
      };
      Services.WatchList.AddToWatchList(data)
        .then((res) => {
          setLoading(false);
          getListings();
        })
        .catch((error) => {
          setLoading(false);
        });
    };

    const deleteFromWatchList = () => {
      setLoading(true);

      Services.WatchList.DeleteFromWatchList(item.id)
        .then((res) => {
          setLoading(false);
          getListings();
        })
        .catch((error) => {
          setLoading(false);
        });
    };

    const timeDifference = () => {
      const auctionEndDate = new Date(
        moment(item.auction.auction_end).format("LLLL")
      );
      const todayDate = new Date();
      let timeDifference = auctionEndDate.getTime() - todayDate.getTime();
      let dayMilliSeconds = 1000 * 60 * 60 * 24;
      let totalDays = Math.abs(timeDifference / dayMilliSeconds); // it returns negative value if start date < end date
      totalDays = Math.floor(totalDays);
      return totalDays;
    };

    return (
      <div className="" key={item?.uuid}>
        <div className="auction-card">
          <div className="relative">
            {item?.listing?.photos[0] && (
              <img
                className="auction-top-img cursor-pointer"
                src={`${process.env.REACT_APP_HOST_API_KEY}/${item.listing.photos[0]}`}
                alt={
                  item?.year +
                  " " +
                  item?.make +
                  " " +
                  item?.model +
                  (item.title ? " " + item.title : "")
                }
                loading="lazy"
                onClick={() =>
                  navigate(
                    `/auctions/${
                      item?.year?.replace(/\s+/g, "-") +
                      "-" +
                      item?.make?.replace(/\s+/g, "-") +
                      "-" +
                      item?.model?.replace(/\s+/g, "-") +
                      (item?.title
                        ? "-" + item?.title?.replace(/\s+/g, "-")
                        : "")
                    }`,
                    {
                      state: { uuid: item?.listing?.uuid },
                    }
                  )
                }
              />
            )}
            <div
              className={`bgColor text-white absolute bottom-3 left-3 rounded-lg px-2`}
            >
              <div className="flex items-center flex-wrap  h-7">
                {item?.auction?.auction_end && (
                  <span
                    className={`text-white  inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm  py-1 ${
                      item?.current_bid?.price || item?.has_reserve_price
                        ? "border-r-2 border-gray-200 pr-2"
                        : ""
                    } `}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-5 h-5 mr-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    {item?.auction?.auction_end && timeDifference() > 1 ? (
                      timeDifference() +
                      " " +
                      (timeDifference() > 1
                        ? t("card_data.days")
                        : t("card_data.day"))
                    ) : (
                      <Countdown date={item?.auction?.auction_end} daysInHours={true} />
                    )}
                  </span>
                )}
                {item?.current_bid?.price || item?.reserve_price ? (
                  <>
                    {" "}
                    <span className="text-white ml-1 inline-flex items-center leading-none text-sm">
                      Bid:
                    </span>
                    <span className="text-white ml-1 inline-flex items-center leading-none text-sm">
                      {item?.current_bid?.price ? item.current_bid.price : 0}{" "}
                      CHF
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="flex justify-between items-start">
              <h1
                className="auction-item-title cursor-pointer"
                onClick={() =>
                  navigate(
                    `/auctions/${
                      item?.year?.replace(/\s+/g, "-") +
                      "-" +
                      item?.make?.replace(/\s+/g, "-") +
                      "-" +
                      item?.model?.replace(/\s+/g, "-") +
                      (item?.title
                        ? "-" + item?.title?.replace(/\s+/g, "-")
                        : "")
                    }`,
                    {
                      state: { uuid: item?.listing?.uuid },
                    }
                  )
                }
              >
                {item?.year +
                  " " +
                  item?.make +
                  " " +
                  item?.model +
                  (item.title ? " " + item.title : "")}
              </h1>
              {role === "user" && (
                <button
                  className={`textColor flex-col gap-1 cursor-pointer ${
                    item?.watching ? "" : "displayONHover"
                  }  transition-all dhrOnhover`}
                  onClick={() =>
                    item?.watching ? deleteFromWatchList() : addToWatchList()
                  }
                  disabled={loading}
                >
                  {item?.watching ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5"
                      viewBox="0 0 576 512"
                    >
                      <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
                    </svg>
                  )}
                  <hr className="bgColor rounded p-[1px] shadow-sm" />
                </button>
              )}
            </div>
            <p className="auction-item-description ">
              {item?.has_reserve_price === false && (
                <button className="desButn capitalize">No RESERVE</button>
              )}
              {item?.description}
            </p>
            <p className="auction-card-address">{`${
              item?.title_country ? item.title_country : ""
            } ${item?.title_state ? item.title_state : ""} ${
              item?.title_province ? item.title_province : ""
            } ${item?.zip_code ? item.zip_code : ""}`}</p>
          </div>
        </div>
      </div>
    );
  }
);

export default AuctionCardCommentComponent;
