import { ApiConfig } from "../config";
import Auth from "./Auth.service";

const getSettings = () => {
  return ApiConfig.get("/api/user/settings", {
  }).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
       window.location.reload();
    }
    return error.response.data;
  });
};

const saveSettings = (data: any) => {
  return ApiConfig.post("/api/user/settings", data).then((res: any) => {
    return res.data;
  }).catch(async (error: any) => {
    if (error?.response?.status == 401) {
      await Auth.Logout();
       window.location.reload();
    }
    return error.response.data;
  });
};

const Settings = {
  getSettings,
  saveSettings,
};

export default Settings;
