import React, { useState, memo } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { Modal, ModalClose, Sheet, Autocomplete } from "@mui/joy";
import Utils from "../../utils";
import Services from "../../services";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router";
import { PreLoaderComponent2 } from "../../components";
import moment from "moment";
import { handleTime } from "../main/Scheduling.main.page";
import { useTranslation } from "react-i18next";
import { AppImages } from "../../assets";

interface ListingDetailsFormType {
  open: boolean;
  setOpen: any;
  list_details: any;
  getList: any;
  statusType: string;
  setStatusType: any;
}

const ListingDetailsForm = memo(function ListingDetailsForm({
  open,
  setOpen,
  list_details,
  getList,
  statusType,
  setStatusType,
}: ListingDetailsFormType) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const themes = Utils.Themes();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [status, setStatus] = useState<string>("pending");
  const [loadingdata, setLoadingdata] = useState<boolean>(false);

  const changeApplicationStatus = () => {
    const data = {
      application_status: status,
    };
    if (location?.state?.listing_id) {
      setLoadingdata(true);
      Services.Listing.ChangeApplicationStatus(
        location?.state?.listing_id,
        data
      )
        .then((res) => {
          setLoadingdata(false);
          if (res.status) {
            Swal.fire(
              t("listing_details_page.vf9vb7469"),
              t("listing_details_page.v2qx0lxy0")
            );
            getList();
            setOpen(false);
            setStatusType("");
            return;
          }
        })
        .catch((error) => {
          setLoadingdata(false);
        });
    }
  };

  const changeDetailsStatus = () => {
    const data = {
      details_submission_status: status,
    };
    if (location?.state?.listing_id) {
      setLoadingdata(true);
      Services.Listing.ChangeDetailsStatus(location?.state?.listing_id, data)
        .then((res) => {
          setLoadingdata(false);
          if (res.status) {
            Swal.fire(
              t("listing_details_page.vf9vb7469"),
              t("listing_details_page.nzy9708tq")
            );
            getList();
            setOpen(false);
            setStatusType("");
            return;
          }
        })
        .catch((error) => {
          setLoadingdata(false);
        });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let noOfErrors = 0;
    let firstKey = "";
    Object.values(errors)?.map((objValue) => {
      if (objValue !== "" && objValue !== null) {
        ++noOfErrors;
      }
    });
    for (let i = 0; i <= Object.keys(errors).length - 1; i++) {
      if (
        Object.values(errors)[i] !== "" &&
        Object.values(errors)[i] !== null
      ) {
        firstKey = Object.keys(errors)[i];
        break;
      }
    }
    if (noOfErrors > 0) {
      let getFirstErrorElement: HTMLElement | null =
        document.getElementById(firstKey);
      if (getFirstErrorElement) {
        return getFirstErrorElement.focus();
      }
    } else {
      statusType === "change applicaiton status"
        ? changeApplicationStatus()
        : statusType === "change details status" && changeDetailsStatus();
    }
  };

  const timeDifference = () => {
    const date = list_details?.schedule_time_slot?.date; // Assuming this is in 'YYYY-MM-DD' format
    const time = list_details?.schedule_time_slot?.end_time; // Assuming this is in 'HH:mm' or 'HH:mm:ss' format
    const listing_date = new Date(moment(`${date}T${time}`).format("LLLL"));
    const todayDate = new Date();
    let timeDifference = listing_date.getTime() - todayDate.getTime();
    return timeDifference;
  };

  const handleScheduleReview = () => {
    const scheduledEnd = timeDifference();
    return scheduledEnd < 0;
  };

  return (
    <>
      {loadingdata && <PreLoaderComponent2 />}
      <div>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={open}
          hideBackdrop={true}
          onClose={() => {
            setOpen(false);
          }}
          sx={themes.JoyModalStyle_2}
        >
          <Sheet variant="outlined" sx={themes.JoyModalSheetStyle_2}>
            <ModalClose
              variant="outlined"
               size="sm"
              sx={themes.JoyModalCloseStyle_2}
            />
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className=" w-100">
                <div className="flex flex-row ">
                  <div className="w-full">
                    <div className="flex items-center justify-center  gap-2 p-2 pb-8 pt-6">
                      <img
                        src={AppImages.LogoImage}
                        alt="AutoBids Logo"
                        className="w-auto h-10"
                      />
                      <h3 className="head text-center font-medium">
                        {t("listing_details_page.llaul9t16")}
                      </h3>
                    </div>
                    <div className="px-4 pb-6">
                      {(statusType === "change applicaiton status" ||
                        statusType === "change details status") && (
                        <div className="py-2">
                          <label htmlFor="updateStatus" className="pb-2 p1">
                            {statusType === "change applicaiton status"
                              ? t("listing_details_page.wu2h8fj16")
                              : statusType === "change details status" &&
                                t("listing_details_page.u1cpknjjr")}
                            <sup className="requireField">*</sup>
                          </label>
                          <Autocomplete
                            disableClearable={true}
                            id="updateStatus"
                            name="updateStatus"
                            defaultValue={"pending"}
                            options={[
                              {
                                label: t("listing_details_page.l0qww3zjo"),
                                value: "pending",
                              },
                              {
                                label: t("listing_details_page.of2ky6i8c"),
                                value: "approved",
                              },
                              {
                                label: t("listing_details_page.81kfvcskz"),
                                value: "rejected",
                              },
                            ]}
                            onChange={(e: any, value_ob: any) => {
                              setStatus(value_ob?.value);
                            }}
                            required={true}
                            placeholder="Select application status"
                            sx={themes.JoyGrayShadeInputStyle2_2}
                          />
                        </div>
                      )}
                      {statusType === "view schedule" && (
                        <div className="py-2">
                          <h2 className="text-[var(--main-color)] font-semibold tracking-widest	p00 text-center dark:text-white">
                            {t("listing_details_page.290dbgwxj")}
                          </h2>
                          <div className="flex gap-2 justify-center items-center p1">
                            <p className="">
                              {list_details?.schedule_time_slot?.date &&
                                moment(
                                  list_details.schedule_time_slot.date
                                ).format("ddd, DD MMM YYYY")}
                            </p>
                            <p className="">
                              ({" "}
                              {handleTime(
                                list_details?.schedule_time_slot?.start_time
                              )}{" "}
                              -{" "}
                              {handleTime(
                                list_details?.schedule_time_slot?.end_time
                              )}{" "}
                              )
                            </p>
                          </div>
                        </div>
                      )}

                      {statusType === "review" && (
                        <div className="py-2">
                          <h2 className="text-[var(--main-color)] font-semibold tracking-wider	p00 text-left dark:text-white">
                            {t("listing_details_page.e8hfrt0nu")}
                          </h2>
                          <div className="flex gap-2 justify-center items-justify p1">
                            <p className="">{list_details.review}</p>
                          </div>
                        </div>
                      )}
                      {statusType !== "view schedule" &&
                        statusType !== "review" && (
                          <div className="py-2">
                            <ThemeProvider theme={themes.ButtonTheme_2}>
                              <Button
                                variant="contained"
                                type="submit"
                                className=""
                                disabled={isLoading || status === "pending"}
                                fullWidth
                              >
                                {t("listing_details_page.o1ch9yjuj")}
                              </Button>
                            </ThemeProvider>
                          </div>
                        )}
                      {statusType == "view schedule" &&
                        handleScheduleReview() && (
                          <div className="py-2">
                            <ThemeProvider theme={themes.ButtonTheme_2}>
                              <Button
                                variant="contained"
                                type="submit"
                                className=""
                                onClick={() =>
                                  navigate(
                                    `/auction-review/${location.state?.listing_id}`,
                                    { state: location.state }
                                  )
                                }
                                fullWidth
                              >
                                {t("listing_details_page.o2n9ohthv")}
                              </Button>
                            </ThemeProvider>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Sheet>
        </Modal>
      </div>
    </>
  );
});

export default ListingDetailsForm;
