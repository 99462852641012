import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Context from "../context";
import Utils from "../utils";
import { Button, ThemeProvider, Popover } from "@mui/material";
import Hooks from "../hooks";

type LanguageSwitcherProps = {
  showOtherStyle?: boolean;
};

const LanguageSwitcher = ({ showOtherStyle }: LanguageSwitcherProps) => {
  const { lngs, lng, setLng, i18n } = useContext(Context.LanguageContext);
  const themes = Utils.Themes();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorLanguage, setAnchorLanguage] =
    useState<HTMLButtonElement | null>(null);
  const [toggleLng, setToggleLng] = useState<boolean>(false);

  const idLanguage = open ? "Language-popover" : undefined;
  const openLanguage = Boolean(anchorLanguage);

  const handleClickLanguage = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorLanguage(event.currentTarget);
  };

  const handleCloseLanguage = () => {
    setAnchorLanguage(null);
  };

  const languageContent: string =
    (lngs &&
      (lng && lngs[lng]
        ? lngs[lng].name || "English"
        : lngs["en"]?.name?.toLocaleLowerCase())) ||
    "English";
  // (lngs &&
  //   (lng && lngs[lng]
  //     ? t(lngs[lng].name?.toLocaleLowerCase() || "English")
  //     : t(lngs["en"]?.name?.toLocaleLowerCase() || "English"))) ||
  // "English";

//   console.log('lngs', lngs)

  return (
    <>
      {/* language toggler */}
      <div className="">
        {showOtherStyle ? (
          <ThemeProvider theme={themes.ButtonActiveTheme_2}>
            <Button
              className="md:py-2.5 p-1.5 text-black md:px-4 px-2  font-semibold  bg-yellow-300 hover:bg-yellow-200 focus:outline-none rounded-lg  text-center min-w-20 flex items-center justify-between md:block "
              id="lngDropDown"
              aria-describedby={idLanguage}
              onClick={handleClickLanguage}
            >
              <div className="flex justify-between gap-2 p1">
                <p>{languageContent}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                  className={`w-2 ${openLanguage ? "rotate-180" : ""}`}
                >
                  <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
                </svg>
              </div>
            </Button>
          </ThemeProvider>
        ) : (
          <button
            className="navButn secondary "
            id="lngDropDown"
            aria-describedby={idLanguage}
            onClick={handleClickLanguage}
            style={{ width: "100%" }}
          >
            <div className="flex justify-between items-center gap-1">
              <p className="dark:text-[--dark-text-color]">{languageContent}</p>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                  className={`w-2 ${openLanguage ? "rotate-180" : ""}`}
                >
                  <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
                </svg>
              </span>
            </div>
          </button>
        )}
      </div>
      {/* language popover */}
      <Popover
        id={idLanguage}
        open={openLanguage}
        anchorEl={anchorLanguage}
        onClose={handleCloseLanguage}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <nav className="min-w-36 p-4 menusDarkStyling">
          <ul className="">
            <ThemeProvider theme={themes.ButtonActiveTheme_2}>
              {lngs &&
                Object.keys(lngs)?.length > 0 &&
                Object.keys(lngs)?.map((lng2CodeKey: string, index) => (
                  <li className="menuList">
                    <button
                      onClick={(e) => {
                        setToggleLng(false);
                        setLng && setLng(lng2CodeKey);
                        i18n?.changeLanguage(lng2CodeKey);
                        handleCloseLanguage();
                      }}
                      className={`navSettingLink ${lng2CodeKey === lng ? " activeMenu" : ""}`}
                    >
                      {/* {t(
                        lngs[lng2CodeKey]?.name?.toLocaleLowerCase() ||
                          "english"
                      )} */}
                      {lngs[lng2CodeKey]?.name || "English"}
                    </button>
                  </li>
                ))}
            </ThemeProvider>
          </ul>
        </nav>
      </Popover>
    </>
  );
};

export default LanguageSwitcher;
