import { useState, useRef, memo, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, ThemeProvider } from "@mui/material";
import Utils from "../utils";
import Services from "../services";
import { useTranslation } from "react-i18next";
import { AppImages } from "../assets";

const DashboardSidebar = memo(({ showSidebar, setShowSidebar }: any) => {
  const { t } = useTranslation();
  const sideRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const themes = Utils.Themes();

  const [toggleNavWidth, setToggleNavWidth] = useState<boolean>(false);
  const role = Services.Storage.getCurrentRole();

  const style = {
    boxShadow: "none",
    paddingY: toggleNavWidth ? "8px" : "10px",
    paddingX: toggleNavWidth ? "6px" : "12px",
  };

  useEffect(() => {
    setToggleNavWidth(Services.Storage.getToggleNavWidth() === "true");
  }, []);

  return (
    <>
      <aside
        className={`${
          toggleNavWidth ? "" : "w-64 md:w-72 2xl:w-80"
        } dashSidebar  min-h-screen absolute	lg:relative bg-white z-50 dark:bg-[--dark] ${
          showSidebar ? "block" : "hidden lg:block"
        } `}
        ref={sideRef}
      >
        {/*  sidebar toggle btn */}
        <div className="rounded-full border-2 border-dotted	 p-0 m-0 shadow-sm absolute top-[30px] z-50 right-[-16px] bg-white hidden lg:block dark:bg-[--dark] dark:border-[--gray-color]">
          <Button
            variant="text"
            color="inherit"
            sx={{
              width: "40px",
              height: "40px",
              padding: "4px",
              minWidth: "40px",
              background: "transparent",
              boxShadow: "none",
              borderRadius: "100%",
              transform: `rotate(${toggleNavWidth ? 0 : 180}deg)`,
            }}
            onClick={() => {
              setToggleNavWidth(!toggleNavWidth);
              Services.Storage.setToggleNavWidth(`${!toggleNavWidth}`);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 320 512"
              stroke="currentColor"
              className="dark:text-white dark:fill-white"
            >
              <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
            </svg>
          </Button>
        </div>
        <div className=" absolute top-[10px] z-50 right-[10px] lg:hidden">
          <Button
            variant="text"
            color="inherit"
            sx={{
              minWidth: "fit-content",
            }}
            onClick={() => {
              setShowSidebar(!showSidebar);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              stroke="currentColor"
              className="iconDiv"
            >
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </Button>
        </div>
        {/* sidebar menus */}
        <div
          className={`border-r-2 border-dotted z-40 scroll-smooth overflowScrollY overflow-y-auto h-full dark:border-[--gray-color]`}
        >
          <div
            className={`px-3 py-8 flex flex-col ${
              toggleNavWidth ? "gap-1 px-1" : "gap-3  sm:px-5"
            }`}
          >
            {/* logo */}
            <div
              className={`flex items-center ${
                toggleNavWidth ? "justify-center " : "justify-start "
              }`}
            >
              <Link
                to="/dashboard"
                className={`flex title-font font-medium items-center text-gray-900  md:mb-0 ${
                  toggleNavWidth ? "flex-col" : "flex-row"
                }`}
              >
                <img
                  src={AppImages.LogoImage}
                  alt="AutoBids Logo"
                  className="md:h-12 h-10"
                />
                <h1
                  className={`ml-1 sm:ml-3  capitalize ${
                    toggleNavWidth ? "p00" : "head"
                  }`}
                >
                  {t("site_title")}
                </h1>
              </Link>
            </div>
            <div
              className={`deactive pb-2  ${toggleNavWidth ? "pt-6" : "pt-4"}`}
            >
              <div
                className={`flex flex-col gap-2 ${
                  toggleNavWidth ? "py-1" : "py-2"
                }`}
              >
                <ThemeProvider
                  theme={
                    location.pathname === "/dashboard"
                      ? themes.MenuActiveButtonTheme_2
                      : themes.MenuButtonTheme_2
                  }
                >
                  <Button
                    variant={
                      location.pathname === "/dashboard" ? "contained" : "text"
                    }
                    fullWidth
                    sx={{
                      ...style,
                    }}
                    onClick={() => {
                      navigate("/dashboard");
                      setShowSidebar(false);
                    }}
                  >
                    <div
                      className={`sidebarButnStyle ${
                        location.pathname === "/dashboard" ? "" : "deactive"
                      }   ${
                        toggleNavWidth ? "toggleNavWidth" : "normalNavWidth"
                      }`}
                    >
                      <div className="iconDiv">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          className=" m-auto"
                        >
                          <path
                            opacity="0.32"
                            d="M21.1808 16.9703C20.8971 17.6255 20.2225 18 19.5086 18H14.8154C14.8462 17.9145 14.8735 17.8269 14.8971 17.7373C15.1709 16.6974 14.8825 15.639 14.2214 14.8963C14.4654 12.9091 14.6177 10.8733 14.7108 9.26516C14.7569 8.46731 13.7795 8.20081 13.4274 8.91526C12.7178 10.3553 11.8493 12.1958 11.0842 14.041C10.1467 14.3479 9.3768 15.1177 9.10295 16.1576C8.93642 16.7899 8.97782 17.4291 9.18451 18H4.49141C3.77747 18 3.10288 17.6255 2.81918 16.9703C2.29212 15.7533 2 14.4108 2 13C2 7.47715 6.47715 3 12 3C17.5229 3 22 7.47715 22 13C22 14.4108 21.7079 15.7533 21.1808 16.9703Z"
                          />
                          <path d="M14.7108 9.26516C14.7569 8.46731 13.7795 8.20081 13.4274 8.91526C12.7178 10.3553 11.8493 12.1958 11.0842 14.041C10.1467 14.3479 9.3768 15.1177 9.10295 16.1576C8.6742 17.7856 9.62375 19.459 11.2238 19.8953C12.8238 20.3315 14.4684 19.3654 14.8971 17.7373C15.1709 16.6974 14.8825 15.639 14.2214 14.8963C14.4654 12.9091 14.6177 10.8733 14.7108 9.26516Z" />
                        </svg>
                      </div>
                      <p className="p1 dark:text-white">
                        {t("dashboard_sidebar.u6a5hj5pr")}
                      </p>
                    </div>
                  </Button>
                </ThemeProvider>
                <ThemeProvider
                  theme={
                    location.pathname === "/lists"
                      ? themes.MenuActiveButtonTheme_2
                      : themes.MenuButtonTheme_2
                  }
                >
                  <Button
                    variant={
                      location.pathname === "/lists" ? "contained" : "text"
                    }
                    fullWidth
                    sx={{
                      ...style,
                    }}
                    onClick={() => {
                      navigate("/lists");
                      setShowSidebar(false);
                    }}
                  >
                    <div
                      className={`sidebarButnStyle ${
                        location.pathname === "/lists" ? "" : "deactive"
                      }  ${toggleNavWidth ? "toggleNavWidth" : "normalNavWidth"}`}
                    >
                      <div className="iconDiv">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={20}
                          viewBox="0 0 512 512"
                          fill="currentColor"
                          className=" m-auto"
                        >
                          <path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z" />
                        </svg>
                      </div>
                      <p className={toggleNavWidth ? "p1" : "p0"}>
                        {t("dashboard_sidebar.0bxx2kvj7")}
                      </p>
                    </div>
                  </Button>
                </ThemeProvider>
                <ThemeProvider
                  theme={
                    location.pathname === "/schedule"
                      ? themes.MenuActiveButtonTheme_2
                      : themes.MenuButtonTheme_2
                  }
                >
                  <Button
                    variant={
                      location.pathname === "/schedule" ? "contained" : "text"
                    }
                    fullWidth
                    sx={{
                      ...style,
                    }}
                    onClick={() => {
                      navigate("/schedule");
                      setShowSidebar(false);
                    }}
                  >
                    <div
                      className={`sidebarButnStyle ${
                        location.pathname === "/schedule" ? "" : "deactive"
                      }  ${toggleNavWidth ? "toggleNavWidth" : "normalNavWidth"}`}
                    >
                      <div className="iconDiv">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={20}
                          fill="currentColor"
                          className=" m-auto"
                          viewBox="0 0 448 512"
                        >
                          <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z" />
                        </svg>
                      </div>
                      <p className={toggleNavWidth ? "p1" : "p0"}>
                        {t("dashboard_sidebar.routjmzd0")}
                      </p>
                    </div>
                  </Button>
                </ThemeProvider>
                <ThemeProvider
                  theme={
                    location.pathname === "/auction"
                      ? themes.MenuActiveButtonTheme_2
                      : themes.MenuButtonTheme_2
                  }
                >
                  <Button
                    variant={
                      location.pathname === "/auction" ? "contained" : "text"
                    }
                    fullWidth
                    sx={{
                      ...style,
                    }}
                    onClick={() => {
                      navigate("/auction");
                      setShowSidebar(false);
                    }}
                  >
                    <div
                      className={`sidebarButnStyle ${
                        location.pathname === "/auction" ? "" : "deactive"
                      }  ${toggleNavWidth ? "toggleNavWidth" : "normalNavWidth"}`}
                    >
                      <div className="iconDiv">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          fill="currentColor"
                          className=" m-auto"
                          viewBox="0 0 512 512"
                        >
                          <path d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
                        </svg>
                      </div>
                      <p className={toggleNavWidth ? "p1" : "p0"}>
                        {t("dashboard_sidebar.de5uidl30")}
                      </p>
                    </div>
                  </Button>
                </ThemeProvider>
                <ThemeProvider
                  theme={
                    location.pathname === "/reviewers"
                      ? themes.MenuActiveButtonTheme_2
                      : themes.MenuButtonTheme_2
                  }
                >
                  <Button
                    variant={
                      location.pathname === "/reviewers" ? "contained" : "text"
                    }
                    fullWidth
                    sx={{
                      ...style,
                    }}
                    onClick={() => {
                      navigate("/reviewers");
                      setShowSidebar(false);
                    }}
                  >
                    <div
                      className={`sidebarButnStyle ${
                        location.pathname === "/reviewers" ? "" : "deactive"
                      }  ${toggleNavWidth ? "toggleNavWidth" : "normalNavWidth"}`}
                    >
                      <div className="iconDiv">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          fill="currentColor"
                          className=" m-auto"
                          viewBox="0 0 512 512"
                        >
                          <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zm-312 8v64c0 13.3 10.7 24 24 24s24-10.7 24-24l0-64c0-13.3-10.7-24-24-24s-24 10.7-24 24zm80-96V280c0 13.3 10.7 24 24 24s24-10.7 24-24V120c0-13.3-10.7-24-24-24s-24 10.7-24 24zm80 64v96c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                        </svg>
                      </div>
                      <p className={toggleNavWidth ? "p1" : "p0"}>
                        {t("dashboard_sidebar.39vo0n64t")}
                      </p>
                    </div>
                  </Button>
                </ThemeProvider>
                <ThemeProvider
                  theme={
                    location.pathname === "/faqs"
                      ? themes.MenuActiveButtonTheme_2
                      : themes.MenuButtonTheme_2
                  }
                >
                  <Button
                    variant={
                      location.pathname === "/faqs" ? "contained" : "text"
                    }
                    fullWidth
                    sx={{
                      ...style,
                    }}
                    onClick={() => {
                      navigate("/faqs");
                      setShowSidebar(false);
                    }}
                  >
                    <div
                      className={`sidebarButnStyle ${
                        location.pathname === "/faqs" ? "" : "deactive"
                      }  ${toggleNavWidth ? "toggleNavWidth" : "normalNavWidth"}`}
                    >
                      <div className="iconDiv">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          width={24}
                          height={24}
                          fill="currentColor"
                          className=" m-auto"
                        >
                          <path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM105.8 229.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L216 328.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V314.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H158.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM160 416a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
                        </svg>
                      </div>
                      <p className={toggleNavWidth ? "p1" : "p0"}>
                        {t("dashboard_sidebar.x6inl78ae")}
                      </p>
                    </div>
                  </Button>
                </ThemeProvider>
                <ThemeProvider
                  theme={
                    location.pathname === "/users"
                      ? themes.MenuActiveButtonTheme_2
                      : themes.MenuButtonTheme_2
                  }
                >
                  <Button
                    variant={
                      location.pathname === "/users" ? "contained" : "text"
                    }
                    fullWidth
                    sx={{
                      ...style,
                    }}
                    onClick={() => {
                      navigate("/users");
                      setShowSidebar(false);
                    }}
                  >
                    <div
                      className={`sidebarButnStyle ${
                        location.pathname === "/users" ? "" : "deactive"
                      }  ${toggleNavWidth ? "toggleNavWidth" : "normalNavWidth"}`}
                    >
                      <div className="iconDiv">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          fill="currentColor"
                          className=" m-auto"
                          viewBox="0 0 640 512"
                        >
                          <path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
                        </svg>
                      </div>
                      <p className={toggleNavWidth ? "p1" : "p0"}>
                        {t("dashboard_sidebar.ko6324nbt")}
                      </p>
                    </div>
                  </Button>
                </ThemeProvider>
                {role == "super_admin" && (
                  <ThemeProvider
                    theme={
                      location.pathname === "/admin-users"
                        ? themes.MenuActiveButtonTheme_2
                        : themes.MenuButtonTheme_2
                    }
                  >
                    <Button
                      variant={
                        location.pathname === "/admin-users"
                          ? "contained"
                          : "text"
                      }
                      fullWidth
                      sx={{
                        boxShadow: "none",
                        paddingY: toggleNavWidth ? "8px" : "10px",
                        paddingX: toggleNavWidth ? "4px" : "12px",
                      }}
                      onClick={() => {
                        navigate("/admin-users");
                        setShowSidebar(false);
                      }}
                    >
                      <div
                        className={`sidebarButnStyle ${
                          location.pathname === "/admin-users" ? "" : "deactive"
                        }  ${toggleNavWidth ? "toggleNavWidth" : "normalNavWidth"}`}
                      >
                        <div className="iconDiv">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            fill="currentColor"
                            className=" m-auto"
                            viewBox="0 0 640 512"
                          >
                            <path d="M224 0a128 128 0 1 1 0 256A128 128 0 1 1 224 0zM178.3 304h91.4c11.8 0 23.4 1.2 34.5 3.3c-2.1 18.5 7.4 35.6 21.8 44.8c-16.6 10.6-26.7 31.6-20 53.3c4 12.9 9.4 25.5 16.4 37.6s15.2 23.1 24.4 33c15.7 16.9 39.6 18.4 57.2 8.7v.9c0 9.2 2.7 18.5 7.9 26.3H29.7C13.3 512 0 498.7 0 482.3C0 383.8 79.8 304 178.3 304zM436 218.2c0-7 4.5-13.3 11.3-14.8c10.5-2.4 21.5-3.7 32.7-3.7s22.2 1.3 32.7 3.7c6.8 1.5 11.3 7.8 11.3 14.8v30.6c7.9 3.4 15.4 7.7 22.3 12.8l24.9-14.3c6.1-3.5 13.7-2.7 18.5 2.4c7.6 8.1 14.3 17.2 20.1 27.2s10.3 20.4 13.5 31c2.1 6.7-1.1 13.7-7.2 17.2l-25 14.4c.4 4 .7 8.1 .7 12.3s-.2 8.2-.7 12.3l25 14.4c6.1 3.5 9.2 10.5 7.2 17.2c-3.3 10.6-7.8 21-13.5 31s-12.5 19.1-20.1 27.2c-4.8 5.1-12.5 5.9-18.5 2.4l-24.9-14.3c-6.9 5.1-14.3 9.4-22.3 12.8l0 30.6c0 7-4.5 13.3-11.3 14.8c-10.5 2.4-21.5 3.7-32.7 3.7s-22.2-1.3-32.7-3.7c-6.8-1.5-11.3-7.8-11.3-14.8V454.8c-8-3.4-15.6-7.7-22.5-12.9l-24.7 14.3c-6.1 3.5-13.7 2.7-18.5-2.4c-7.6-8.1-14.3-17.2-20.1-27.2s-10.3-20.4-13.5-31c-2.1-6.7 1.1-13.7 7.2-17.2l24.8-14.3c-.4-4.1-.7-8.2-.7-12.4s.2-8.3 .7-12.4L343.8 325c-6.1-3.5-9.2-10.5-7.2-17.2c3.3-10.6 7.7-21 13.5-31s12.5-19.1 20.1-27.2c4.8-5.1 12.4-5.9 18.5-2.4l24.8 14.3c6.9-5.1 14.5-9.4 22.5-12.9V218.2zm92.1 133.5a48.1 48.1 0 1 0 -96.1 0 48.1 48.1 0 1 0 96.1 0z" />
                          </svg>
                        </div>
                        <p className={toggleNavWidth ? "p1" : "p0"}>
                          {t("dashboard_sidebar.dtel3bzab")}
                        </p>
                      </div>
                    </Button>
                  </ThemeProvider>
                )}
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
});

export default DashboardSidebar;
