import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Services from "../../services";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";

export default function Application() {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [listing, setListing] = useState<any>({});

  const getAuctionInfo = () => {
    params.list_id &&
      Services.Listing.GetOneListingForUser(params.list_id).then((res) => {
        if (res.status) {
          const data = res.data;
          setListing(data);
          if (data?.application_status === "approved") {
            if (data?.auction) {
              return navigate("/auction/" + data?.uuid);
            } else {
              if (
                data?.title &&
                data?.description &&
                data?.location &&
                data?.engine &&
                data?.drive_train &&
                data?.body_style &&
                data?.exterior_color &&
                data?.interior_color
              ) {
                if (data?.service_history) {
                  if (
                    data?.photos &&
                    data?.photos?.exterior?.length > 3 &&
                    data?.photos?.interior?.length > 3 &&
                    data?.photos?.mechanical?.length > 1
                    // &&
                    // data?.photos?.docs?.length > 1
                  ) {
                    if (
                      !data?.owner_is_dealer &&
                      (data?.schedule_date_time_slot ||
                        data?.schedule_time_slot)
                    ) {
                      if (data?.review) {
                        return navigate("/review/" + data?.uuid);
                      }
                      return navigate("/scheduling/" + data?.uuid);
                    }
                    return navigate("/specs/" + data?.uuid);
                  }
                  return navigate("/media/" + data?.uuid);
                }
                return navigate("/condition/" + data?.uuid);
              }
              return navigate("/details/" + data?.uuid);
            }
          }
        }
      });
  };

  useEffect(() => {
    getAuctionInfo();
  }, [params]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <PageComponents.MetaComponent title={t("application_page.title")} />
      <div className="flex flex-col px-3">
        <div className="flex justify-between items-center ">
          <h1 className="p1 font-normal">
            <span
              className="text-[var(--main-color)] hover:text-[var(--dark-color)] cursor-pointer"
              onClick={() => navigate("/listings")}
            >
              {t("application_page.gk6jviu22")}
            </span>{" "}
            {listing?.year ? "—" : ""} {listing?.year} {listing?.make}{" "}
            {listing?.model} {listing?.title ? listing?.title : ""}
          </h1>
        </div>
        <div className="flex">
          <div className="w-full lg:w-9/12 pt-8">
            <div className="w-full xl:w-9/12 ">
              <h2 className="head font-bold pb-4">
                {listing.application_status &&
                listing.application_status === "pending"
                  ? t("application_page.xsmlu38df")
                  : listing.application_status === "rejected"
                    ? t("application_page.wx5bunrx1")
                    : listing.application_status === "approved" &&
                      t("application_page.zqjdy52u")}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
