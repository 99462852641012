import { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import Services from "../../services";
import PageComponents from "../components";
import { PreLoaderComponent2, WarningAlert } from "../../components";
import { Button, ThemeProvider } from "@mui/material";
import Utils from "../../utils";
import { Textarea } from "@mui/joy";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../context/theme.context";

const AddReview = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const { isDark } = useContext(ThemeContext);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      rating: 0,
      review: "",
    },
    validationSchema: Yup.object().shape({
      rating: Yup.number().required(),
      review: Yup.string().required().trim().min(10).max(120),
    }),
    onSubmit: async (values) => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      handleSubmit();
    },
  });

  const handleSubmit = () => {
    const data = {
      rating: formik?.values?.rating,
      review: formik?.values?.review,
      list_id: params?.list_id,
    };
    setLoading(true);
    Services.Listing.SaveListingReview(data)
      .then((res: any) => {
        setLoading(false);
        Swal.fire({
          icon: res.status ? "success" : "error",
          title: res.message,
        });
        return res.status ? navigate("/") : "";
      })
      .catch((error: any) => {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message.split(".")[0],
          });
        }
        return;
      });
  };

  return (
    <div className="dark:bg-[--dark]">
      <PageComponents.MetaComponent title={t("add_review_page.title")} />
      {loading && <PreLoaderComponent2 />}
      <div className="containerSpacing py-16 pt-24">
        <div className="flex flex-col px-3  lg:px-5 py-6 lg:py-12 lg:w-3/4  justify-center items-center border shadow-md drop-shadow mx-auto rounded-lg darkShadow dark:border-[--gray]">
          <div className="flex justify-between items-center mb-4">
            <h1 className="head font-bolder text-[var(--main-color)] hover:text-[var(--dark-color)] cursor-pointer">
              {t("add_review_page.k0c1do818")}
            </h1>
          </div>
          <form noValidate onSubmit={formik.handleSubmit} className="w-full">
            <div className="flex flex-col  gap-4 mt-3">
              <div className="InputFieldSetting py-2">
                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="hover-feedback"
                    value={formik?.values?.rating}
                    precision={0.5}
                    onChange={(event, newValue) => {
                      formik?.setFieldTouched("rating", true, false);
                      formik?.setFieldValue("rating", newValue);
                    }}
                    onBlur={formik?.handleBlur}
                    emptyIcon={
                      <StarIcon sx={{ opacity: 0.55, color: isDark? "white" : "black" }} fontSize="inherit" />
                    }
                  />
                </Box>
              </div>
              <div className="InputFieldSetting py-2">
                <label className="p1 px-1" htmlFor="review">
                  {t("add_review_page.k0c1do818")}
                  <sup className="requireField">*</sup>
                </label>
                <Textarea
                  id="review"
                  name="review"
                  variant="outlined"
                  required={true}
                  value={formik?.values?.review}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  minRows={1}
                  maxRows={3}
                  slotProps={{
                    textarea: {
                      maxLength: 300,
                    },
                  }}
                  sx={
                    formik?.errors?.review && formik?.touched?.review
                      ? themes.JoyInputError_2
                      : themes.JoyGrayShadeInputStyle2_2
                  }
                  onBlur={formik?.handleBlur}
                />
                <WarningAlert
                  message={
                    formik?.touched?.review ? formik?.errors?.review : ""
                  }
                />
              </div>

              <div className="py-2 flex justify-end items-center">
                <ThemeProvider theme={themes.ButtonTheme_2}>
                  <Button
                    type="submit"
                    variant="contained"
                    className="hover:underline-offset-1 cursor-pointer"
                    disabled={loading || formik.isSubmitting}
                    fullWidth
                  >
                    {t("add_review_page.4xaon9y0o")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="CurrentColor"
                      viewBox="0 0 448 512"
                      className="w-4 h-4 ml-2"
                    >
                      <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                    </svg>
                  </Button>
                </ThemeProvider>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddReview;
