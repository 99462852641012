import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import {
  NavComponent,
  FooterComponent,
  CookieBannerComponent,
  FloatingScroller,
} from "../components";

let mainFontFamily = ["Signika Negative", "sans-serif"];

function Main() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="bg-[--dark]">
        <NavComponent />
      </div>
      <div className="dark:bg-[--dark]">
        <div className="mainContainer min-h-screen ">
          {/* <NavComponent /> */}
          <div className="my-6">
            <Outlet />
          </div>
          {/* <FooterComponent /> */}
          <CookieBannerComponent />
          <FloatingScroller />
        </div>
      </div>
      <div className="bg-[--dark]">
        <FooterComponent />
      </div>
    </>
  );
}

export default Main;

export { mainFontFamily };
