import React, { useState, useEffect, memo, useContext } from "react";
import { ThemeProvider, Button, Avatar, Tooltip } from "@mui/material";
import Utils from "../../utils";
import Services from "../../services";
import PageComponents from ".";
import { isUser_loged_in } from "../../utils/checkUserType.utils";
import { useTranslation } from "react-i18next";
import InvertColorByName from "../../utils/invertColorByName.utils";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../context/theme.context";

interface FactsInfoType {
  data: any;
}

const FactsInfo = memo(({ data }: FactsInfoType) => {
  const isUserLogedIn = isUser_loged_in();
  const profile = Services.Storage.getCurrentUser();
  const { t } = useTranslation();
  const { isDark } = useContext(ThemeContext);
  const themes = Utils.Themes();
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showContact, setShowContact] = useState<boolean>(false);

  useEffect(() => {
    setIsUserAdmin(Services.Storage.getCurrentRole() !== "user");
  }, []);

  return (
    <>
      <div className="py-4 ">
        <div className="w-full border dark:border-[--gray] flex flex-wrap rounded p1 capitalize">
          <dl className="facts_dl">
            <dt className="facts_dt">{t("facts_info.make")}</dt>
            <dd className="facts_dd">{data?.make}</dd>
          </dl>
          <dl className="facts_dl">
            <dt className="facts_dt">{t("facts_info.engine")}</dt>
            <dd className="facts_dd">{data?.engine}</dd>
          </dl>
          <dl className="facts_dl">
            <dt className="facts_dt">{t("facts_info.model")}</dt>
            <dd className="facts_dd">{data?.model}</dd>
          </dl>
          <dl className="facts_dl">
            <dt className="facts_dt">{t("facts_info.drivetrain")}</dt>
            <dd className="facts_dd">{data?.drive_train}</dd>
          </dl>
          <dl className="facts_dl">
            <dt className="facts_dt">{t("facts_info.mile")}</dt>
            <dd className="facts_dd">{data?.mileage + " KM"}</dd>
          </dl>
          <dl className="facts_dl">
            <dt className="facts_dt">{t("facts_info.transmission_type")}</dt>
            <dd className="facts_dd">{data?.transmission}</dd>
          </dl>
          <dl className="facts_dl">
            <dt className="facts_dt">{t("facts_info.vin")}</dt>
            <dd className="facts_dd">{data?.vin}</dd>
          </dl>
          <dl className="facts_dl">
            <dt className="facts_dt">{t("facts_info.body_style")}</dt>
            <dd className="facts_dd">{data?.body_style}</dd>
          </dl>
          <dl className="facts_dl">
            <dt className="facts_dt">{t("facts_info.title_status")}</dt>
            <dd className="facts_dd">{data?.title_status}</dd>
          </dl>
          <dl className="facts_dl">
            <dt className="facts_dt">{t("facts_info.exterior_colors")}</dt>
            <dd className="facts_dd">
              <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                <Tooltip title={data.exterior_color}>
                  <Button
                    sx={{
                      background: data.exterior_color
                        ? data.exterior_color
                        : "var(--main-color)",
                      height: "100%",
                      "&:hover": {
                        background: data.exterior_color
                          ? data.exterior_color
                          : "var(--main-color)",
                        opacity: 0.9,
                      },
                      "&:focus": {
                        background: data.exterior_color
                          ? data.exterior_color
                          : "var(--main-color)",
                        opacity: 0.9,
                      },
                      border:
                        "1px solid " + (isDark ? "var(--gray)" : " #e3e3e3"),
                      color: InvertColorByName(data?.exterior_color),
                    }}
                  >
                    {data.exterior_color}
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </dd>
          </dl>
          <dl className="facts_dl">
            <dt className="facts_dt">{t("facts_info.location")}</dt>
            <dd className="facts_dd">
              {`${
                data?.country?.toLowerCase() === "us"
                  ? data?.zip_code
                    ? data?.zip_code
                    : ""
                  : data?.country?.toLowerCase() === "ca"
                    ? data?.city_and_province
                    : ""
              } ${data?.country ? data?.country : ""} `}
            </dd>
          </dl>
          <dl className="facts_dl">
            <dt className="facts_dt">{t("facts_info.interior_color")}</dt>
            <dd className="facts_dd">
              <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                <Tooltip title={data.interior_color}>
                  <Button
                    sx={{
                      background: data.interior_color
                        ? data.interior_color
                        : "var(--main-color)",
                      height: "100%",
                      "&:hover": {
                        background: data.interior_color
                          ? data.interior_color
                          : "var(--main-color)",
                        opacity: 0.9,
                      },
                      "&:focus": {
                        background: data.interior_color
                          ? data.interior_color
                          : "var(--main-color)",
                        opacity: 0.9,
                      },
                      border:
                        "1px solid " + (isDark ? "var(--gray)" : " #e3e3e3"),
                      color: InvertColorByName(data?.interior_color),
                    }}
                  >
                    {data.interior_color}
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </dd>
          </dl>
          {isUserLogedIn && isUserAdmin && (
            <>
              <dl className="facts_dl">
                <dt className="facts_dt">{t("facts_info.has_flaws")}</dt>
                <dd className="facts_dd">
                  {data?.has_flaws
                    ? t("facts_info.yes_text")
                    : t("facts_info.no_text")}
                </dd>
              </dl>
              <dl className="facts_dl">
                <dt className="facts_dt">{t("facts_info.car_status")}</dt>
                <dd className="facts_dd">{data?.vehicle_stock}</dd>
              </dl>
              <dl className="facts_dl">
                <dt className="facts_dt cellTextCenter">
                  {t("facts_info.price_status")}
                </dt>
                <dd className="facts_dd">
                  <ThemeProvider theme={themes.ButtonTheme_2}>
                    <Button
                      variant={data?.has_reserve_price ? "contained" : "text"}
                      sx={{ paddingX: "6px", paddingY: "3px" }}
                    >
                      {data?.has_reserve_price
                        ? t("facts_info.reserve_text")
                        : t("facts_info.no_reserve_text")}
                    </Button>
                  </ThemeProvider>
                </dd>
              </dl>
              {data?.has_reserve_price && (
                <dl className="facts_dl">
                  <dt className="facts_dt cellTextCenter">
                    {t("facts_info.reserve_price")}
                  </dt>
                  <dd className="facts_dd	cellTextCenter">
                    <ThemeProvider theme={themes.ButtonTheme_2}>
                      <Button
                        variant="text"
                        sx={{
                          paddingX: 0,
                          paddingY: 0,
                          margin: 0,
                          width: "fit-content",
                        }}
                      >
                        {data?.reserve_price ? data?.reserve_price : 0} CHF
                      </Button>
                    </ThemeProvider>
                  </dd>
                </dl>
              )}
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("facts_info.vehicle_title_text")}
                </dt>
                <dd className="facts_dd">
                  {data?.owner_has_title
                    ? t("facts_info.owner_itself")
                    : t("facts_info.other_person")}
                </dd>
              </dl>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("facts_info.vehicle_title_name")}
                </dt>
                <dd className="facts_dd">
                  {data?.owner_has_title ? data?.name : data?.title_owner}
                </dd>
              </dl>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("facts_info.car_listed_for_sale_txt")}
                </dt>
                <dd className="facts_dd">
                  {data?.listed_elsewhere
                    ? t("facts_info.yes_text")
                    : t("facts_info.no_text")}
                </dd>
              </dl>
              <dl className="facts_dl">
                <dt className="facts_dt">{t("facts_info.refferral")}</dt>
                <dd className="facts_dd">
                  {data?.referral
                    ? data?.referral
                    : t("facts_info.no_referral_text")}
                </dd>
              </dl>
            </>
          )}
          <dl className="facts_dl">
            <dt className="facts_dt">{t("facts_info.seller")}</dt>
            <dd className="facts_dd">
              <div className="flex justify-start items-center gap-2 flex-wrap">
                <Link to={"/user/" + data?.seller?.username}>
                  <div className="flex justify-start items-center gap-2 flex-wrap">
                    <Avatar
                      src={
                        data?.seller?.avatar &&
                        process.env.REACT_APP_HOST_API_KEY +
                          "/" +
                          data?.seller?.avatar
                      }
                      sx={{
                        width: 32,
                        height: 32,
                        boxShadow: isDark
                          ? "0 1px 2px 4px rgb(255 255 255 / 0.05)"
                          : "0 1px 2px 4px rgb(0 0 0 / 0.05)",
                      }}
                    />
                    <p className="normal-case	hover:underline">
                      {data?.seller?.username}
                    </p>
                  </div>
                </Link>
                {data?.stats?.auction_end &&
                  Utils.CalculateDays(data?.stats?.auction_end) > 0 && (
                    <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                      <Button
                        variant="contained"
                        sx={{ paddingX: "6px", paddingY: "3px" }}
                        onClick={() =>
                          isUserLogedIn
                            ? profile?.is_registered_bidder
                              ? setShowContact(true)
                              : setShow(true)
                            : document.getElementById("signin")?.click()
                        }
                      >
                        {t("facts_info.contact_butn_text")}
                      </Button>
                    </ThemeProvider>
                  )}
              </div>
            </dd>
          </dl>
          <dl className="facts_dl">
            <dt className="facts_dt">{t("facts_info.seller_type")}</dt>
            <dd className="facts_dd">
              {data?.owner_is_dealer === 1
                ? t("facts_info.dealer_title")
                : t("facts_info.private_party_title")}
            </dd>
          </dl>
        </div>
      </div>
      {show && (
        <PageComponents.VerifiedBidderComponent
          show={show}
          setShow={setShow}
          auction_info={data}
        />
      )}
      {showContact && (
        <PageComponents.AuctionContact
          show={showContact}
          setShow={setShowContact}
          listId={data?.id}
        />
      )}
    </>
  );
});

export default FactsInfo;
