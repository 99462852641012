// import & export components

import CarShowCase from "./CarShowCase.component.page";
import EditProfile from "./EditProfile.component.page";
import RightArrowSvg from "./RightArrowSvg.component.page";
import LeftArrowSvg from "./LeftArrowSvg.component.page";
import AuctionQuestion from "./AuctionQuestion.component";
import AuctionComments from "./AuctionComments.component";
import AuctionListing from "./AuctionListing.component";
import NewListing from "./NewListing.component";
import AddCreditCard from "./AddCreditCard.component";
import FAQ from "./FAQ.component";
import VerificationBrowse from "./VerificationBrowse";
import AuctionCardComponent from "./AuctionCard.component";
import ListingCardComponent from "./ListingCard.component.page";
import OldSaleComponent from "./OldSale.component";
import CarInfoFirstStep from "./CarInfoFirstStep.component";
import CarInfoSecondStep from "./CarInfoSecondStep.component";
import FactsInfo from "./FactsInfo.component";
import AuthorQuote from "./AuthorQuote.component";
import CarVideos from "./CarVideos.component";
import SellerQA from "./SellerQA.component";
import Quote from "./Quote.component";
import BiddingInfo from "./BiddingInfo.component";
import QuestionAnswers from "./QuestionAnswers.component";
import QuoteModal from "./QuoteModal.component";
import Shipping from "./Shipping.component";
import Financing from "./Financing.component";
import AskQuestion from "./AskQuestion.component";
import CarImagesSwiper from "./CarImagesSwiper.component";
import ApplicationAuctionInfo from "./ApplicationAuctionInfo.component";
import ApplicationBids from "./ApplicationBids.component";
import ApplicationQA from "./ApplicationQA.component";
import ApplicationComments from "./ApplicationComments.main.page";
import ProfileBidHistoryCard from "./ProfileBidHistoryCard.component";
import ProfileCommentHistory from "./ProfileCommentHistory.component";
import CreateBid from "./CreateBid.components";
import AuctionDetail from "./AuctionDetail.component";
import UserProfile from "./UserProfile.component";
import UserListings from "./UserListings.component";
import UserBids from "./UserBids.component";
import UserComments from "./UserComments.component";
import UserAuctions from "./UserAuctions.component";
import ListingDetailsForm from "./ListingDetailsForm.component.page";
import CarAuctionsCarousel from "./CarAuctionsCarousel.component";
import DashboardCard from "./DashboardCard.component";
import DetailedFactsInfo from "./DetailedFactsInfo.component";
import DealerFactsInfo from "./DealerFactsInfo.component";
import AuctionCardCommentComponent from "./AuctionCardCommment.component";
import SearchCardComponent from "./SearchCard.component";
import CardCommment from "./CardCommment.component";
import MetaComponent from "./Meta.component";
import FeatureContentComponent from "./FeatureContent.component";
import VerifiedBidderComponent from "./VerifiedBidder.component";
import AuctionContact from "./AuctionContact.component";
import ContactComponent from "./Contact.component";
import SearchComponent from "./Search.component";
import SavedSearch from "./SavedSearch.component";
import TimeMask from "./TimeMask.component";
import FiltersComponent from "./filters.component";
import CircleIcon from "./CircleIcon.component";
import ColorPicker from "./ColorPicker.component";
import CardSkeletonComponent, {
  CardSkeletonElements,
} from "./CardsSkeleton.component";
import FeaturedContentComponent from "./FeaturedContent.component";
import FeaturedCardComponent from "./FeaturedCard.component";
import FeaturedSkeletonComponent from "./FeaturedSkeleton.component";

const PageComponents = {
  FeaturedSkeletonComponent,
  FeaturedCardComponent,
  FeaturedContentComponent,
  CardSkeletonElements,
  CardSkeletonComponent,
  ColorPicker,
  CircleIcon,
  TimeMask,
  SavedSearch,
  SearchComponent,
  ContactComponent,
  AuctionContact,
  VerifiedBidderComponent,
  CardCommment,
  SearchCardComponent,
  AuctionCardCommentComponent,
  DealerFactsInfo,
  DashboardCard,
  DetailedFactsInfo,
  CarAuctionsCarousel,
  ListingDetailsForm,
  UserProfile,
  UserListings,
  UserBids,
  UserComments,
  UserAuctions,
  AuctionDetail,
  CreateBid,
  ProfileCommentHistory,
  ProfileBidHistoryCard,
  ApplicationComments,
  ApplicationQA,
  ApplicationBids,
  ApplicationAuctionInfo,
  CarImagesSwiper,
  AskQuestion,
  Financing,
  Shipping,
  QuoteModal,
  QuestionAnswers,
  Quote,
  BiddingInfo,
  SellerQA,
  CarVideos,
  AuthorQuote,
  FactsInfo,
  CarInfoFirstStep,
  CarInfoSecondStep,
  OldSaleComponent,
  ListingCardComponent,
  AuctionCardComponent,
  VerificationBrowse,
  AddCreditCard,
  CarShowCase,
  NewListing,
  EditProfile,
  RightArrowSvg,
  LeftArrowSvg,
  AuctionQuestion,
  AuctionComments,
  AuctionListing,
  FAQ,
  MetaComponent,
  FeatureContentComponent,
  FiltersComponent,
};

export default PageComponents;
