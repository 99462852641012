import { useState, useEffect, useContext } from "react";
import {
  Paper,
  ThemeProvider,
  Button,
  Breadcrumbs,
  Box,
  ButtonGroup,
  Alert,
} from "@mui/material";
import Utils from "../../utils";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Input } from "@mui/joy";
import _ from "lodash";
import { IconButton } from "@mui/material";
import Services from "../../services";
import { PreLoaderComponent2, WarningAlert } from "../../components";
import Swal from "sweetalert2";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";
import { isAdmin_and_loged_in } from "../../utils/checkUserType.utils";
import { ThemeContext } from "../../context/theme.context";

const UpdateTimeScheduler = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const themes = Utils.Themes();
  const isLogedIn = isAdmin_and_loged_in();
  const { isDark } = useContext(ThemeContext);
  const [noOfTimeSlots, setNoOfTimeSLots] = useState<number>(0);
  const [timeSlots, setTimeSlots] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [schedule, setSchedule] = useState<any>({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    location.state?.schedule
      ? setSchedule(location?.state?.schedule)
      : navigate("/schedule");
  }, [location.state?.schedule]);

  const setTimeSlotValue = () => {
    if (schedule && Object.keys(schedule).length > 0) {
      let arr: any[] = [];
      schedule?.time_slots?.length > 0 &&
        schedule?.time_slots?.map((item: any) => {
          if (item.status === "available") {
            arr.push(item);
          }
        });
      setNoOfTimeSLots(arr.length);
      setTimeSlots(arr);
    }
  };

  useEffect(() => {
    isLogedIn && setTimeSlotValue();
  }, [schedule]);

  const AddMoreExisitng = () => {
    setErrorMessage("");
    const endedTimeSlot = document.getElementById(
      `endedTimeSlot-${noOfTimeSlots - 1}`
    ) as HTMLInputElement;
    const startedTimeSlot = document.getElementById(
      `startedTimeSlot-${noOfTimeSlots - 1}`
    ) as HTMLInputElement;
    if (
      noOfTimeSlots === 0 ||
      (startedTimeSlot &&
        startedTimeSlot.value &&
        endedTimeSlot &&
        endedTimeSlot.value)
    ) {
      const errorsExist = checkErrors();
      if (errorsExist) {
        return;
      }
      return noOfTimeSlots < 96
        ? setNoOfTimeSLots(noOfTimeSlots + 1)
        : setErrorMessage(t("update_time_schedule_dashboard_page.0w8tsbvjt"));
    } else {
      return startedTimeSlot && !startedTimeSlot.value
        ? startedTimeSlot?.focus()
        : !endedTimeSlot?.value && endedTimeSlot?.focus();
    }
  };

  const timeAlreadyExist = (slot: {
    start_time: string;
    end_time: string;
    error: string;
    index?: number;
  }) => {
    let t_slot = { ...slot };
    let st_slot = t_slot.start_time.split(":");
    let et_slot = t_slot.end_time.split(":");

    st_slot && st_slot[0] === "00" && (t_slot.start_time = "24:" + st_slot[1]);
    et_slot && et_slot[0] === "00" && (t_slot.end_time = "24:" + et_slot[1]);

    let error = false;
    timeSlots.map((t, t_i) => {
      let ot = { ...t };
      let ost_slot = ot.start_time.split(":");
      let oet_slot = ot.end_time.split(":");

      ost_slot && ost_slot[0] === "00" && (ot.start_time = "24:" + ost_slot[1]);
      oet_slot && oet_slot[0] === "00" && (ot.end_time = "24:" + oet_slot[1]);

      // if (
      //   t_slot["index"] !== t_i &&
      //   (t_slot["start_time"] == ot["start_time"] ||
      //     t_slot["end_time"] == ot["end_time"] ||
      //     t_slot["start_time"] == ot["end_time"] ||
      //     t_slot["end_time"] == ot["start_time"] ||
      //     (t_slot["start_time"] >= ot["start_time"] &&
      //       t_slot["start_time"] <= ot["end_time"]) ||
      //     (t_slot["end_time"] >= ot["start_time"] &&
      //       t_slot["start_time"] <= ot["end_time"]))
      // ) {
      //   error = true;
      // }
      if (
        (t_slot["index"] != t_i &&
          (t_slot["start_time"] == ot["start_time"] ||
            t_slot["end_time"] == ot["end_time"])) ||
        (t_slot["start_time"] > ot["start_time"] &&
          t_slot["start_time"] < ot["end_time"]) ||
        (t_slot["end_time"] < ot["end_time"] &&
          t_slot["end_time"] > ot["start_time"]) ||
        (t_slot["start_time"] < ot["start_time"] &&
          t_slot["end_time"] > ot["end_time"])
      ) {
        error = true;
      }
    });
    return error;
  };

  const handleChangeValue = (
    value: string,
    id: string,
    index: number,
    rangeType: string
  ) => {
    let time_slots: any[] = [...timeSlots];
    // function that will check time slots exist or not
    let timeSlotExist = false;
    let slot = { start_time: "", end_time: "", error: "" };
    time_slots?.length > 0 &&
      time_slots?.map((item: any, i: number) => {
        if (i === index) {
          timeSlotExist = true;
          slot = item;
        }
      });
    timeSlotExist
      ? (time_slots[index][rangeType] = value)
      : (slot = {
          start_time: rangeType === "start_time" ? value : "",
          end_time: rangeType === "end_time" ? value : "",
          error: "",
        });

    slot.error = timeAlreadyExist({ ...slot, index })
      ? t("update_time_schedule_dashboard_page.f2c7pb6h3")
      : "";
    slot.error !== t("update_time_schedule_dashboard_page.f2c7pb6h3") &&
      (slot.error = timeComparison(value, rangeType, index, slot));
    setTimeSlots(timeSlotExist ? time_slots : [...timeSlots, slot]);
  };

  const timeComparison = (
    time: string,
    comparsionType: string,
    index: number,
    existTimeSlot: any
  ) => {
    const Time = time.split(":");
    let isValidTimeSlot = false;
    let error = existTimeSlot.error ? existTimeSlot.error : "";
    timeSlots?.length > 0 &&
      timeSlots?.map((slot, i) => {
        if (index === i) {
          const tempTime =
            comparsionType === "start_time"
              ? Time
              : slot["start_time"].split(":");
          const timeSlot =
            comparsionType === "start_time"
              ? slot["end_time"].split(":")
              : Time;

          timeSlot[0] && timeSlot[0] === "00" && (timeSlot[0] = "24");
          tempTime[0] && tempTime[0] === "00" && (tempTime[0] = "24");

          const hourlyDifference = timeSlot[0] - tempTime[0];
          const differenceInMinutes = timeSlot[1] - tempTime[1];
          const totalDifference = hourlyDifference * 60 + differenceInMinutes;
          if (totalDifference < 15) {
            error = t("update_time_schedule_dashboard_page.0d2akzmom");
          } else if (totalDifference > 60) {
            error = t("update_time_schedule_dashboard_page.dnf2brw54");
          } else {
            isValidTimeSlot = true;
            error = "";
          }
        }
      });
    return error;
  };

  const handleInputValue = (field: string, index: number) => {
    let value = "";
    if (timeSlots.length > 0) {
      timeSlots.filter((item, i) => {
        if (i === index) {
          value = item[field];
        }
      });
    }
    return value;
  };

  const handleSubmit = (e: any) => {
    e?.preventDefault();
    setErrorMessage("");
    const errorsExist = checkErrors();
    if (errorsExist) {
      return;
    }
    let slots: any[] = [];
    timeSlots?.length > 0 &&
      timeSlots?.map((item) => {
        item?.start_time !== "" &&
          item?.end_time !== "" &&
          slots.push({
            start_time: item?.start_time,
            end_time: item?.end_time,
          });
      });

    if (slots?.length == 0) {
      return setErrorMessage(
        t("add_new_time_schedule_dashboard_page.qxhaitixg")
      );
    }

    setLoading(true);

    const data = { time_slots: slots };
    Services.Schedule.UpdateSchedule(schedule?.id, data)
      .then((res) => {
        if (res.status) {
          setLoading(false);
          return navigate("/schedule");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message,
          });
        }
      });
  };

  const checkErrors = () => {
    let errors = false;
    timeSlots?.length > 0 &&
      timeSlots?.map((slot_item: any) => {
        if (slot_item["error"]) {
          errors = true;
        }
      });
    errors
      ? setErrorMessage(t("update_time_schedule_dashboard_page.5yeqv6rmg"))
      : setErrorMessage("");
    return errors;
  };

  const delete_a_slot = (index: number) => {
    let slots_of_time: string[] = [];
    timeSlots?.length > 0 &&
      timeSlots?.map((item: string) => {
        slots_of_time.push(item);
      });
    slots_of_time.splice(index, 1);
    setTimeSlots(slots_of_time);
    setNoOfTimeSLots(noOfTimeSlots - 1);
  };

  const handleEndInputDisabled = (index: number) => {
    return (
      timeSlots.length === 0 ||
      (timeSlots.length > 0 && timeSlots[index]?.start_time === "")
    );
  };

  return (
    <>
      <PageComponents.MetaComponent
        title={t("update_time_schedule_dashboard_page.ovvk4hzwb")}
      />
      {loading && <PreLoaderComponent2 />}
      <div className=" ">
        <div className=" ">
          <div className=" ">
            {/* Heading */}
            <div className="flex justify-between items-center py-6">
              <div className="flex flex-col justify-center items-start gap-2">
                <h3 className="breadCrumbsMianTitle">
                  {t("update_time_schedule_dashboard_page.gjeyitcn7")}
                </h3>
                <div role="presentation">
                  <ThemeProvider theme={themes.MuiBreadCrumbsTheme}>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link to="/dashboard" className="hover:underline">
                        {t("update_time_schedule_dashboard_page.qdych26m0")}
                      </Link>
                      <Link to={`/schedule`} className="hover:underline">
                        {t("time_schedule_dashboard_page.47tas5py8")}
                      </Link>
                      <Link
                        to={location.pathname}
                        state={{ schedule }}
                        className="hover:underline"
                      >
                        {t("update_time_schedule_dashboard_page.gjeyitcn7")}
                      </Link>
                    </Breadcrumbs>
                  </ThemeProvider>
                </div>
              </div>
              <ThemeProvider theme={themes.ButtonTheme_2}>
                <Button variant="contained" onClick={() => navigate(-1)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 mr-2"
                    viewBox="0 0 448 512"
                    fill="CurrentColor"
                  >
                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                  </svg>
                  {t("update_time_schedule_dashboard_page.n4q7g2mgx")}
                </Button>
              </ThemeProvider>
            </div>
            <form onSubmit={handleSubmit}>
              {/* Schedule Auction Time */}
              <div className="clayEffect">
                <div className=" px-4 py-4">
                  {errorMessage && (
                    <Alert
                      severity="error"
                      sx={{
                        marginBottom: "10px",
                        ".MuiAlert-icon": {
                          alignSelf: "center",
                        },
                      }}
                    >
                      <p className="p1">{errorMessage}</p>
                    </Alert>
                  )}
                  <div className="flex flex-col gap-2 ">
                    <div className="InputFieldSetting py-2">
                      <label className="p1 px-1" htmlFor="date">
                        {t("update_time_schedule_dashboard_page.w81jpk13e")}
                        <sup className="requireField">*</sup>
                      </label>
                      <Input
                        name="date"
                        type="date"
                        id="date"
                        value={schedule?.date}
                        sx={themes.JoyGrayShadeInputStyle2_2}
                        disabled={true}
                      />
                    </div>
                    <div
                      className={`InputFieldSetting ${
                        noOfTimeSlots ? "py-2" : ""
                      }`}
                    >
                      {noOfTimeSlots > 0 && (
                        <label className="p1 px-1" htmlFor="timeSlot">
                          {t("update_time_schedule_dashboard_page.xo1yfhq3i")}
                          <sup className="requireField">*</sup>
                        </label>
                      )}
                      {_.times(noOfTimeSlots, (index: number) => (
                        <div key={index}>
                          <div className="flex justify-between items-center gap-2 sm:gap-4">
                            <div className="w-full">
                              <PageComponents.TimeMask
                                name="startedTimeSlot"
                                id={`startedTimeSlot-${index}`}
                                t={handleInputValue("start_time", index)}
                                setT={(time: string) => {
                                  handleChangeValue(
                                    time,
                                    `startedTimeSlot-${index}`,
                                    index,
                                    "start_time"
                                  );
                                }}
                              />
                            </div>
                            <div className="w-full">
                              <PageComponents.TimeMask
                                name="endedTimeSlot"
                                id={`endedTimeSlot-${index}`}
                                setT={(time: string) => {
                                  handleChangeValue(
                                    time,
                                    `endedTimeSlot-${index}`,
                                    index,
                                    "end_time"
                                  );
                                }}
                                t={handleInputValue("end_time", index)}
                                isDisabled={
                                  handleEndInputDisabled(index) ? true : false
                                }
                              />
                            </div>
                            <IconButton
                              className="text-[--dark-text-color] dark:text-white"
                              onClick={() => delete_a_slot(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-3.5 sm:w-4 m-auto"
                                fill="currentColor"
                                viewBox="0 0 384 512"
                              >
                                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                              </svg>
                            </IconButton>
                          </div>
                          {timeSlots[index]?.error && (
                            <WarningAlert message={timeSlots[index]?.error} />
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-col sm:flex-row justify-between items-center gap-2 pt-4 pb-2">
                      <div className="flex justify-start items-center gap-2 w-full sm:w-fit">
                        <ThemeProvider theme={themes.ButtonTheme_2}>
                          <ButtonGroup
                            variant="text"
                            aria-label="outlined primary button group"
                            fullWidth
                          >
                            <Button
                              variant={"outlined"}
                              onClick={AddMoreExisitng}
                              fullWidth
                              sx={{
                                border:
                                  "1px solid var(--light-color) !important",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-4 h-4 mr-2 dark:text-[--light-color]"
                                viewBox="0 0 448 512"
                                fill="CurrentColor"
                              >
                                <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                              </svg>
                              <p className="min-w-max pr-8 pl-8 p1 dark:text-[--light-color]">
                                {t(
                                  "update_time_schedule_dashboard_page.xo1yfhq3q"
                                )}
                              </p>
                            </Button>
                            <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                              <Button variant="contained" type="submit">
                                {t(
                                  "update_time_schedule_dashboard_page.3xcwfvy3t"
                                )}{" "}
                              </Button>
                            </ThemeProvider>
                          </ButtonGroup>
                        </ThemeProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateTimeScheduler;
