import { useState, useEffect } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import Services from "../services";
import { useTranslation } from "react-i18next";

const navigateListing = (data: any) => {
  let navigate: null | String = null;
  if (data?.auction) {
    navigate = `/auction/${data?.uuid}`;
  } else if (data?.application_status !== "approved") {
    navigate = `/application/${data?.uuid}`;
  } else if (
    !data?.title ||
    !data?.description ||
    !data.location ||
    !data?.engine ||
    !data?.drive_train ||
    !data?.body_style ||
    !data?.exterior_color ||
    !data?.interior_color
  ) {
    navigate = `/details/${data?.uuid}`;
  } else if (!data?.service_history) {
    navigate = `/condition/${data?.uuid}`;
  } else if (
    !data?.photos ||
    !data?.photos?.exterior ||
    !data?.photos?.interior ||
    !data?.photos?.mechanical ||
    data?.photos?.exterior?.length < 4 ||
    data?.photos?.interior?.length < 4 ||
    data?.photos?.mechanical?.length < 2
  ) {
    navigate = `/media/${data?.uuid}`;
  } else if (data?.details_submission_status !== "approved") {
    navigate = `/specs/${data?.uuid}`;
  } else if (!data?.owner_is_dealer && !data?.review) {
    navigate = `/scheduling/${data?.uuid}`;
  } else if ((data?.owner_is_dealer ? true : data?.review) && !data?.auction) {
    navigate = `/review/${data?.uuid}`;
  }

  return navigate;
};

const ApplicationSidebar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const [listing, setListing] = useState<any>({});
  const [isDisabled, setIsDisabled] = useState<any>({
    application: false,
    details: true,
    condition: true,
    media: true,
    specs: true,
    scheduling: true,
    review: true,
    auction: true,
  });

  const getAuctionInfo = () => {
    params?.list_id &&
      Services.Listing.GetOneListingForUser(params.list_id).then((res) => {
        if (res.status) {
          const data = res.data;
          setListing(data);
          let disabledObj = {
            application: false,
            details: true,
            condition: true,
            media: true,
            specs: true,
            scheduling: true,
            review: true,
            auction: true,
          };
          if (data?.application_status === "approved" && !data?.auction) {
            disabledObj.application = true;
            disabledObj.details = false;
            if (
              data?.title &&
              data?.description &&
              data.location &&
              data?.engine &&
              data?.drive_train &&
              data?.body_style &&
              data?.exterior_color &&
              data?.interior_color
            ) {
              disabledObj.condition = false;
              if (data?.service_history) {
                disabledObj.media = false;
                if (
                  data?.photos &&
                  data?.photos?.exterior?.length > 3 &&
                  data?.photos?.interior?.length > 3 &&
                  data?.photos?.mechanical?.length > 1
                ) {
                  disabledObj.specs = false;
                  // if (
                  //   data?.details_submission_status === "approved" &&
                  //   !data?.owner_is_dealer
                  // ) {
                  //   disabledObj.scheduling = false;
                  //   if (data?.review) {
                  //     disabledObj.review = false;
                  //     if (data?.review) {
                  //       disabledObj.review = false;
                  //     }
                  //   }
                  // }
                  if (data?.details_submission_status === "approved") {
                    data?.review && (disabledObj.review = false);
                    !data?.owner_is_dealer && (disabledObj.scheduling = false);
                  }
                }
              }
            }
          } else if (data?.auction) {
            disabledObj.application = true;
            disabledObj.auction = false;
          }
          setIsDisabled(disabledObj);
        }
      });
  };

  useEffect(() => {
    getAuctionInfo();
  }, [params]);

  useEffect(() => {
    if (location?.state?.refreshAuction) {
      getAuctionInfo();
    }
  }, [location]);

  const handleDisabled = (e: any, field: string) => {
    isDisabled[field] && e?.preventDefault();
  };

  return (
    <>
      <div className="w-full sm:w-4/12 md:w-3/12 2xl:w-2/12 py-5 ">
        <div className="h-full w-full overflow-y-auto pr-4">
          <ol className="py-2 font-medium flex sm:flex-col overflow-x-auto h-full gap-2">
            {/* application approval by admin */}
            <li>
              <NavLink
                to={`/application/${params.list_id}`}
                className={`applicationProcess ${
                  isDisabled["application"] ? "opacity-70" : ""
                }`}
                onClick={(e: any) => handleDisabled(e, "application")}
              >
                <span className="p1 font-normal">
                  1. {t("application_sidebar_component.eenoo12kj")}
                </span>
              </NavLink>
            </li>
            {/* details tab */}
            <li>
              <NavLink
                to={`/details/${params.list_id}`}
                className={`applicationProcess ${
                  isDisabled["details"] ? "opacity-70" : ""
                }`}
                onClick={(e: any) => handleDisabled(e, "details")}
              >
                <span className="p1 font-normal">
                  2. {t("application_sidebar_component.ut0um54og")}
                </span>
              </NavLink>
            </li>
            {/* condition tab */}
            <li>
              <NavLink
                to={`/condition/${params.list_id}`}
                className={`applicationProcess ${
                  isDisabled["condition"] ? "opacity-70" : ""
                }`}
                onClick={(e: any) => handleDisabled(e, "condition")}
              >
                <span className="p1 font-normal">
                  3. {t("application_sidebar_component.tq4mx8z9s")}
                </span>
              </NavLink>
            </li>
            {/* media tab */}
            <li>
              <NavLink
                to={`/media/${params.list_id}`}
                className={`applicationProcess ${
                  isDisabled["media"] ? "opacity-70" : ""
                }`}
                onClick={(e: any) => handleDisabled(e, "media")}
              >
                <span className="p1 font-normal">
                  4. {t("application_sidebar_component.xlyn0ly24")}
                </span>
              </NavLink>
            </li>
            {/* specs tab */}
            <li>
              <NavLink
                to={`/specs/${params.list_id}`}
                className={`applicationProcess ${
                  isDisabled["specs"] ? "opacity-70" : ""
                }`}
                onClick={(e: any) => handleDisabled(e, "specs")}
              >
                <span className="p1 font-normal">
                  5. {t("application_sidebar_component.onjcp73j5")}
                </span>
              </NavLink>
            </li>
            {/* schedule tab */}
            {!listing?.owner_is_dealer && (
              <li>
                <NavLink
                  to={`/scheduling/${params.list_id}`}
                  className={`applicationProcess ${
                    isDisabled["scheduling"] ? "opacity-70" : ""
                  }`}
                  onClick={(e: any) => handleDisabled(e, "scheduling")}
                >
                  <span className="p1 font-normal">
                    6. {t("application_sidebar_component.d7804eiqe")}
                  </span>
                </NavLink>
              </li>
            )}
            {/* review tab */}
            <li>
              <NavLink
                to={`/review/${params.list_id}`}
                className={`applicationProcess ${
                  isDisabled["review"] ? "opacity-70" : ""
                }`}
                onClick={(e: any) => handleDisabled(e, "review")}
              >
                <span className="p1 font-normal">
                  {!listing?.owner_is_dealer ? "7." : "6."}{" "}
                  {t("application_sidebar_component.8j5t3io9x")}
                </span>
              </NavLink>
            </li>
            {/* auction tab */}
            <li>
              <NavLink
                to={`/auction/${params.list_id}`}
                className={`applicationProcess ${
                  isDisabled["auction"] ? "opacity-70" : ""
                }`}
                onClick={(e: any) => handleDisabled(e, "auction")}
              >
                <span className="p1 font-normal">
                  {listing?.owner_is_dealer ? "7." : "8."}{" "}
                  {t("application_sidebar_component.rcl72cbct")}
                </span>
              </NavLink>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default ApplicationSidebar;
export { navigateListing };
