import { useState, memo } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { Modal, ModalClose, Sheet, Input } from "@mui/joy";
import Utils from "../../utils";
import Services from "../../services";
import Swal from "sweetalert2";
import { WarningAlert } from "../../components";
import { useTranslation } from "react-i18next";
import { AppImages } from "../../assets";

const DailyEmail = memo(function DailyEmail({ setIsModalOpen }: any) {
  const { t } = useTranslation();
  const themes = Utils.Themes();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({ email: "" });

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (errors.email !== "") {
      let emailInput = document.getElementById("email");
      if (emailInput !== null) {
        return emailInput?.focus();
      }
    } else {
      // submit the form if no error occurs
      setIsLoading(true);
      const data = {
        email: email?.trim(),
      };

      Services.Auth.SubscribeToDailyEmail(data)
        .then((res) => {
          setIsLoading(false);
          setIsModalOpen(false);
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: res.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: res.message,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setIsModalOpen(false);

          if (error?.response?.data?.message) {
            Swal.fire({
              icon: "error",
              title: error?.response?.data?.message,
            });
          }
        });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={true}
        hideBackdrop={true}
        onClose={() => {
          setIsModalOpen(false);
        }}
        sx={themes.JoyModalStyle_2}
      >
        <Sheet
          sx={themes.JoyModalSheetStyle_2}
          className="w-11/12 sm:8/12 md:w-6/12 lg:w-5/12 xl:w-4/12"
        >
          <ModalClose
            variant="outlined"
            size="sm"
            sx={themes.JoyModalCloseStyle_2}
          />
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="modalForm"
            autoComplete={"off"}
            noValidate
          >
            <div className="w-100">
              <div className="flex flex-row">
                <div className="text-center">
                  <div className="mx-auto flex items-center justify-center h-auto w-12 ">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-26 h-26 mb-3 dark:text-white"
                    >
                      <path
                        strokeLinecap="round"
                        d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
                      />
                    </svg> */}
                    <img
                      src={AppImages.LogoImage}
                      className="h-8 xs-h-12 sm:h-10 2xl:h-12 xs-h-6"
                      alt="AutoBids Logo"
                    />
                  </div>
                  <h3 className="head leading-6 font-bold text-gray-900">
                    {t("daily_email_modal.heading")}
                  </h3>
                  <div className="mt-2 w-full mb-6">
                    <p className="p1 text-start  text-gray-500 w-full ">
                      {t("daily_email_modal.description")}
                    </p>
                  </div>
                  <div className="flex flex-col sm:flex-row gap-y-1 sm:gap-y-0 gap-x-3 muiInput">
                    <Input
                      type="email"
                      placeholder={t("daily_email_modal.input_placeholder")}
                      variant="outlined"
                      color={errors.email === "" ? "neutral" : "danger"}
                      required={true}
                      style={{ width: "100%" }}
                      value={email}
                      id="email"
                      onChange={(e) => setEmail(e.target.value?.trim())}
                      onBlur={() =>
                        Utils.Validator.HandleForm(email, "email", setErrors)
                      }
                      sx={
                        errors.email
                          ? themes.JoyInputError_2
                          : themes.JoyGrayShadeInputStyle2_2
                      }
                    />
                    {window.matchMedia("(max-width: 640px)").matches && (
                      <WarningAlert message={errors.email} />
                    )}
                    <ThemeProvider theme={themes.ButtonTheme_2}>
                      <Button
                        variant="contained"
                        type="submit"
                        className=""
                        disabled={email === "" || isLoading}
                      >
                        {t("daily_email_modal.subscribe_butn_text")}
                      </Button>
                    </ThemeProvider>
                  </div>
                  {window.matchMedia("(min-width: 640px)").matches && (
                    <WarningAlert message={errors.email} />
                  )}
                </div>
              </div>
            </div>
          </form>
        </Sheet>
      </Modal>
    </div>
  );
});

export default DailyEmail;
