import { useState, useEffect } from "react";
import { ThemeProvider, Button } from "@mui/material";
import Utils from "../../utils/index";
import { PreLoaderComponent2, WarningAlert } from "../../components";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Services from "../../services";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";

type conditionType = {
  modifications?: string;
  flaws?: string;
  service_history: string;
};

const Condition = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [serviceHistory, setServiceHistory] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [listing, setListing] = useState<any>({});
  const [listId, setListId] = useState<number>(0);
  const [detailApproved, setDetailsApproved] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      serviceHistory: "",
    },
    validationSchema: Yup.object().shape({
      serviceHistory: Yup.string()
        .label("Service history")
        .required()
        .trim()
        .min(8)
        .max(2000),
    }),
    onSubmit: async (values) => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      handleSubmit();
    },
  });

  const resetForm = () => {
    setServiceHistory("");
  };

  const getListingInfo = () => {
    params.list_id &&
      Services.Listing.GetOneListingForUser(params.list_id).then((res) => {
        if (res.status && res.data) {
          setListing(res.data);
          const data = res.data;
          if (data.application_status !== "approved") {
            return navigate(`/application/${params?.list_id}`);
          } else if (data.details_submission_status === "approved") {
            if (data?.auction) {
              return navigate(`/auction/${params?.list_id}`);
            } 
            // else if (!data?.owner_is_dealer && !data?.review) {
            //   return navigate(`/scheduling/${params?.list_id}`);
            // } else if (data?.review) {
            //   return navigate(`/review/${params?.list_id}`);
            // }
          }
          setDetailsApproved(
            data.details_submission_status === "approved" ? true : false
          );
          setListId(data.id);
          setServiceHistory(data.service_history ? data.service_history : "");
          const qlEditor = document.querySelector(".ql-editor");
          qlEditor && (qlEditor.innerHTML = data.service_history);
          const { text } = Utils.HtmlToText(data.service_history);
          formik?.setFieldValue("serviceHistory", text);
        }
      });
  };

  useEffect(() => {
    getListingInfo();
  }, [params]);

  const handleSubmit = () => {
    const data: conditionType = {
      service_history: serviceHistory.trim(),
    };
    setLoading(true);
    Services.Listing.UpdateLisitng(listId, data)
      .then((res) => {
        setLoading(false);
        if (res.status) {
          Swal.fire(
            t("condition_page.f5bodkubi"),
            t("condition_page.gz4ewcre9")
          );
          resetForm();
          return navigate("/media/" + params.list_id);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message.split(".")[0],
          });
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleServiceHistory = (value: string) => {
    const { text } = Utils.HtmlToText(value);
    formik?.setFieldTouched("serviceHistory", true);
    formik?.setFieldValue("serviceHistory", text);
    setServiceHistory(value);
  };

  const handleQuillValue = (field: string, text: string) => {
    formik?.setFieldTouched(field, true);
    formik?.setFieldValue(field, text);
    return;
  };

  return (
    <>
      <PageComponents.MetaComponent title={t("condition_page.title")} />
      {loading && <PreLoaderComponent2 />}
      <div className="">
        <div className="flex flex-col px-1 lg:px-3">
          <div className="flex justify-between items-center">
            <h1 className="p1 font-normal">
              <span
                className="text-[var(--main-color)] hover:text-[var(--dark-color)] cursor-pointer"
                onClick={() => navigate("/listings")}
              >
                {t("condition_page.ukek1tbdf")}{" "}
              </span>{" "}
              {listing?.year ? "—" : ""} {listing?.year} {listing?.make}{" "}
              {listing?.model} {listing?.title ? listing?.title : ""}
            </h1>
          </div>
          <form noValidate onSubmit={formik.handleSubmit} className="pt-8">
            <div className="flex flex-col  gap-4 mt-3 sm:w-3/4">
              <div className="InputFieldSetting">
                <label className="p1 px-1 font-bold" htmlFor="title">
                  {t("condition_page.uzq8sgyb3")}
                  <sup className="requireField">*</sup>
                </label>
                <ReactQuill
                  theme="snow"
                  placeholder={"Service History"}
                  bounds={".app"}
                  onChange={(value) => handleServiceHistory(value)}
                  onBlur={(previousSelection, source, editor) =>
                    handleQuillValue("serviceHistory", editor.getText())
                  }
                  className={`fadedStyle ${formik?.errors?.serviceHistory && formik?.touched?.serviceHistory ? "error" : ""}`}
                />
                <WarningAlert
                  message={
                    formik.touched?.serviceHistory
                      ? formik.errors?.serviceHistory
                      : ""
                  }
                />
              </div>
              <div className="py-2 flex justify-start items-center">
                <ThemeProvider theme={Utils.Themes().ButtonTheme_2}>
                  <Button
                    type="submit"
                    variant="contained"
                    className="hover:underline-offset-1 cursor-pointer"
                    disabled={
                      detailApproved ||
                      listing?.details_submission_status !== "pending" 
                    }
                  >
                    {t("condition_page.ytelognq6")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="CurrentColor"
                      viewBox="0 0 448 512"
                      className="w-4 h-4 ml-2"
                    >
                      <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32sw14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                    </svg>
                  </Button>
                </ThemeProvider>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Condition;
