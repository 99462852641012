import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ThemeProvider,
  Button,
  Breadcrumbs,
  Box,
  IconButton,
  Popover,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonGroup,
  Pagination,
  Avatar,
} from "@mui/material";
import Utils from "../../utils";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Services from "../../services";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { PreLoaderComponent } from "../../components";
import Swal from "sweetalert2";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";
import { isAdmin_and_loged_in } from "../../utils/checkUserType.utils";

const AdminUsers = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const themes = Utils.Themes();
  const isLogedIn = isAdmin_and_loged_in();
  const role = Services.Storage.getCurrentRole();
  const [anchorEl, setAnchorEl] = useState(null);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [loadingdata, setLoadingdata] = useState<boolean>(false);
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [admins, setAdmins] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [search, setSearch] = useState<string>("");
  const [search_text, setSearchText] = useState<string>("");

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    document.addEventListener("click", (e: any) => {
      let clickonActionButton = false;
      rows?.map((row, index) => {
        const actionButton = document.getElementById(`actionMenu-${index}`);
        if (e.target.contains(actionButton)) {
          clickonActionButton = true;
        }
      });
    });
  }, []);

  const getAdmins = (e?: any) => {
    e?.preventDefault();
    setLoadingdata(true);
    Services.Admin.GetAdmins(offset, limit, search_text?.toLocaleLowerCase())
      .then((res) => {
        setLoadingdata(false);
        if (res.status) {
          res.data && setAdmins(res.data);
          setTotal(Math.ceil(res.total / limit));
        }
      })
      .catch((error) => {
        setLoadingdata(false);
      });
  };

  useEffect(() => {
    isLogedIn && getAdmins();
  }, [offset, limit, search_text]);

  const resetPagenation = () => {
    setOffset(0);
    setTotal(0);
    setPage(1);
  };

  const handleSearch = (e?: any) => {
    e?.preventDefault();
    resetPagenation();
    setSearchText(search);
  };

  const deleteDelete = (id: number | string) => {
    Swal.fire({
      title: t("admins_page.db8l5i6xo"),
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: t("admins_page.tgiikqtzt"),
      denyButtonText: t("admins_page.j0icdgpbo"),
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoadingdata(true);
        Services.Admin.DeleteAdmin(id)
          .then((res) => {
            setLoadingdata(false);
            Swal.fire(t("admins_page.6qn7dinhx"), t("admins_page.3bbo631ux"));
            setSearchText("");
            resetPagenation();
            getAdmins();
          })
          .catch((error) => {
            setLoadingdata(false);
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              Swal.fire({
                icon: "error",
                title: error.response.data.message.split(".")[0],
              });
            }
          });
      }
    });
  };

  useEffect(() => {
    role !== "super_admin" && navigate(-1);
  }, [role]);

  return (
    <>
      <PageComponents.MetaComponent title={t("admins_page.gi4yj0nvw")} />
      <div className=" ">
        <div className=" ">
          {/* list items */}
          <div className="flex flex-wrap justify-between items-center py-6 gap-2">
            <div className="flex flex-col justify-center items-start gap-2">
              <h3 className="breadCrumbsMianTitle">
                {t("admins_page.elbj2v19c")}
              </h3>
              <div role="presentation">
                <ThemeProvider theme={themes.MuiBreadCrumbsTheme}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/dashboard" className="hover:underline">
                      {t("admins_page.3n2z7scn5")}
                    </Link>
                    <Link to={location.pathname} className="hover:underline">
                      {t("admins_page.elbj2v19c")}
                    </Link>
                  </Breadcrumbs>
                </ThemeProvider>
              </div>
            </div>
            <div className="grow" />
            <form onSubmit={(e) => handleSearch(e)} className="w-full sm:w-fit">
              <PageComponents.SearchComponent
                search={search}
                setSearch={setSearch}
                classes="w-full sm:w-fit"
              />
            </form>
            <div className="w-full sm:w-fit">
              <ThemeProvider theme={themes.ButtonTheme_2}>
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate("/create-admin", {
                      state: { formType: "Create" },
                    })
                  }
                  fullWidth
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="CurrentColor"
                    viewBox="0 0 448 512"
                    className="w-4 h-4 mr-2"
                  >
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                  {t("admins_page.9mow7pwq5")}
                </Button>
              </ThemeProvider>
            </div>
          </div>
          {/* loading data */}
          {loadingdata && <PreLoaderComponent />}
          {/* desktop responsive table */}
          <div className="">
            {!loadingdata && (
              <div className="hidden md:block w-full overflow-x-auto">
                <Box
                  component={Paper}
                  sx={themes.MuiTableBox}
                  className="overflow-x-auto "
                >
                  <ThemeProvider theme={themes.TableTheme_2}>
                    <TableContainer className="muiTableContainer">
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("admins_page.n6kwxbcus")}</TableCell>
                            <TableCell>{t("admins_page.z20mchaua")}</TableCell>
                            <TableCell>{t("admins_page.azhaasmi7")}</TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {admins?.length > 0 &&
                            admins?.map((item, index) => (
                              <TableRow
                                key={item.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <Avatar
                                    src={
                                      item.avatar
                                        ? `${process.env.REACT_APP_HOST_API_KEY}/${item.avatar}`
                                        : item.username
                                    }
                                    alt={item.username}
                                    className="darkShadow"
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ textTransform: "uppercase" }}
                                >
                                  {item.username}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ textTransform: "uppercase" }}
                                >
                                  {item.email}
                                </TableCell>
                                <TableCell align="right" className="relative">
                                  <IconButton
                                    onClick={(e) => {
                                      handleOpen(e);
                                      setSelectedItem(item);
                                    }}
                                    aria-describedby={`actionMenu-${index}`}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 128 512"
                                      className="popover"
                                    >
                                      <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
                                    </svg>
                                  </IconButton>
                                  <Popover
                                    id={`actionMenu-${index}`}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <div
                                      className={`MuiPopoverMenu`}
                                      id={`actionMenu-${index}`}
                                    >
                                      <ThemeProvider
                                        theme={themes.AppleButtonTheme_2}
                                      >
                                        <Button
                                          // color="inherit"
                                          fullWidth
                                          onClick={() =>
                                            navigate("/admin-details", {
                                              state: { data: selectedItem },
                                            })
                                          }
                                        >
                                          <div className="flex justify-start items-center mr-auto pl-1">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="w-3 h-3 "
                                              fill="currentColor"
                                              viewBox="0 0 576 512"
                                            >
                                              <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                            </svg>
                                            <span className="pl-4 capitalize ">
                                              {t("admins_page.6wnbrdvf8")}
                                            </span>
                                          </div>
                                        </Button>
                                        <Button
                                          // color="inherit"
                                          fullWidth
                                          onClick={() =>
                                            deleteDelete(selectedItem.id)
                                          }
                                        >
                                          <div className="flex justify-start items-center mr-auto pl-1">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 448 512"
                                              className="w-3 h-3 "
                                              fill="currentColor"
                                            >
                                              <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
                                            </svg>
                                            <span className="pl-4 capitalize ">
                                              {t("admins_page.k2q00g6ac")}
                                            </span>
                                          </div>
                                        </Button>
                                      </ThemeProvider>
                                    </div>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </ThemeProvider>
                </Box>
              </div>
            )}
          </div>
          {/* mobile responsive data table */}
          <div className="py-3">
            <div className="flex flex-wrap h-full md:hidden">
              {!loadingdata &&
                admins?.length > 0 &&
                admins?.map((item, index) => (
                  <div className=" p-1 sm:p-2 w-full" key={index} id={item.id}>
                    <ThemeProvider theme={themes.MuiAccordionTheme}>
                      <Accordion className="accordionShadow">
                        <AccordionSummary
                          expandIcon={<ExpandMoreRoundedIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography
                            sx={{ width: "100%", flexShrink: 0 }}
                            className="uppercase flex justify-start items-center gap-2"
                          >
                            <Avatar
                              src={
                                item.avatar
                                  ? `${process.env.REACT_APP_HOST_API_KEY}/${item.avatar}`
                                  : item.username
                              }
                              alt={item.username}
                              sx={{
                                width: 32,
                                height: 32,
                              }}
                              className="darkShadow"
                            />
                            <p className="p0 font-medium capitalize overflow-wrap-anywhere">
                              {item.username}
                            </p>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            <Table>
                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("admins_page.n6kwxbcus")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>
                                  <Avatar
                                    src={
                                      item.avatar
                                        ? `${process.env.REACT_APP_HOST_API_KEY}/${item.avatar}`
                                        : item.username
                                    }
                                    alt={item.username}
                                    className="darkShadow"
                                  />
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("admins_page.z20mchaua")}
                                  </TableCell>
                                </TableHead>
                                <TableCell className="uppercase overflow-wrap-anywhere">
                                  {item.username}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("admins_page.azhaasmi7")}
                                  </TableCell>
                                </TableHead>
                                <TableCell className="uppercase break-all overflow-wrap-anywhere">
                                  {item.email}
                                </TableCell>
                              </TableRow>
                            </Table>
                            <div className="py-4 w-full flex">
                              <ThemeProvider
                                theme={themes.MenuActiveButtonTheme_2}
                              >
                                <ButtonGroup
                                  variant="contained"
                                  aria-label="action button"
                                  sx={{ margin: "auto" }}
                                >
                                  <Button
                                    fullWidth
                                    onClick={() =>
                                      navigate("/admin-details", {
                                        state: { data: item },
                                      })
                                    }
                                  >
                                    <div className="flex justify-start items-center mr-auto px-6 ">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-3 h-3 "
                                        fill="currentColor"
                                        viewBox="0 0 576 512"
                                      >
                                        <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                      </svg>
                                      <span className="pl-2 capitalize ">
                                        {t("admins_page.6wnbrdvf8")}
                                      </span>
                                    </div>
                                  </Button>
                                  <Button
                                    fullWidth
                                    onClick={() => deleteDelete(item.id)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 448 512"
                                      className="w-3 h-3 "
                                      fill="currentColor"
                                    >
                                      <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
                                    </svg>
                                  </Button>
                                </ButtonGroup>
                              </ThemeProvider>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </ThemeProvider>
                  </div>
                ))}
            </div>
          </div>
          {/* pagenation */}
          <div className="flex justify-end items-center">
            {!loadingdata && total > 1 && (
              <Pagination
                count={total}
                hidePrevButton
                hideNextButton
                page={page}
                onChange={(e: any, pageNum) => {
                  setPage(pageNum);
                  setOffset((pageNum - 1) * limit);
                }}
                shape="rounded"
                sx={themes.MuiPagenationStyle_2}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUsers;
