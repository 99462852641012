import React, { useState, Suspense } from "react";
import PageComponents from "../components";
import { Tabs, Tab, Box } from "@mui/material";
import { ThemeProvider, Button, Breadcrumbs } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Utils from "../../utils";
import { PreLoaderComponent } from "../../components";
import { useTranslation } from "react-i18next";

const UserDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const themes = Utils.Themes();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <PageComponents.MetaComponent title={t("user_details_page.h64kl76ht")} />
      <div className=" ">
        <div className=" ">
          {/* heading */}
          <div className="flex flex-wrap justify-between items-center py-6">
            <div className="flex flex-col justify-center items-start gap-2">
              <h3 className="breadCrumbsMianTitle">
                {t("user_details_page.4i89l93qa")}
              </h3>
              <div role="presentation">
                <ThemeProvider theme={themes.MuiBreadCrumbsTheme}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/dashboard" className="hover:underline">
                      {t("user_details_page.xtdglppep")}
                    </Link>
                    <Link to={"/users"} className="hover:underline">
                      {t("users_page.ve294cof0")}
                    </Link>
                    <Link to={location.pathname} className="hover:underline">
                      {t("user_details_page.4i89l93qa")}
                    </Link>
                  </Breadcrumbs>
                </ThemeProvider>
              </div>
            </div>
            <ThemeProvider theme={themes.ButtonTheme_2}>
              <Button variant="contained" onClick={() => navigate(-1)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4 mr-2"
                  viewBox="0 0 448 512"
                  fill="CurrentColor"
                >
                  <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                </svg>
                {t("user_details_page.iaw96vwo4")}
              </Button>
            </ThemeProvider>
          </div>
          <div className=" ">
            <Box sx={{ maxWidth: "100%" }}>
              <ThemeProvider theme={themes.TabsStyle_2}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  sx={{ justifyContent: "center" }}
                  className="jsCenter overflow-x-auto"
                >
                  <Tab label={t("user_details_page.mz4b6ri7x")} />
                  <Tab label={t("user_details_page.3vfuo43is")} />
                  <Tab label={t("user_details_page.y1zhbdz95")} />
                  <Tab label={t("user_details_page.cyv1yutxu")} />
                  <Tab label={t("user_details_page.suume7spd")} />
                </Tabs>
              </ThemeProvider>
            </Box>
            <div className="pt-8 ">
              <Suspense fallback={<PreLoaderComponent />}>
                {value === 0 ? (
                  <PageComponents.UserProfile />
                ) : value === 1 ? (
                  <PageComponents.UserListings />
                ) : value === 2 ? (
                  <PageComponents.UserAuctions />
                ) : value === 3 ? (
                  <PageComponents.UserBids />
                ) : value === 4 ? (
                  <PageComponents.UserComments />
                ) : (
                  <></>
                )}
              </Suspense>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default UserDetails;
