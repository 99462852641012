import React, { useState, memo } from "react";
import { Button, ThemeProvider } from "@mui/material";
import Utils from "../utils";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Input } from "@mui/joy";

interface IProps {
  fieldName: string;
  setPassword: any;
  password: any;
  required: boolean;
  id?: string;
  name?: string;
  handleChange?: any;
  handleBlur?: any;
  formikProps?: any;
  maxLength?: number;
}

const PasswordInputField: React.FC<IProps> = memo(
  ({
    fieldName,
    setPassword,
    password,
    required,
    id,
    name,
    handleChange,
    handleBlur,
    formikProps,
    maxLength,
  }) => {
    const themes = Utils.Themes();
    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
      if (passwordType === "password") {
        setPasswordType("text");
        return;
      }
      setPasswordType("password");
    };

    return (
      <div className="w-full">
        <label
          className="py-1 sm:py-1.5 p1 inputLabel"
          htmlFor={id ? id : "password-input-with-icon-textfield"}
        >
          {fieldName} {required && <sup className="requireField">*</sup>}
        </label>
        <div className="InputFieldSetting">
          <Input
            name={name ? name : "password-input-name"}
            id={id ? id : "password-input-with-icon-textfield"}
            type={passwordType}
            variant="outlined"
            sx={
              name && formikProps.errors[name] && formikProps.touched[name]
                ? themes.JoyInputError_2
                : themes.JoyGrayShadeInputStyle2_2
            }
            slotProps={{
              input: {
                min: 0,
              },
            }}
            endDecorator={
              <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                <Button variant="text" onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </Button>
              </ThemeProvider>
            }
            onChange={(e) => {
              setPassword(e.target.value?.trim());
              handleChange && handleChange(e);
            }}
            value={password}
            required={required}
            onBlur={(e) => {
              handleBlur && handleBlur(e);
            }}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    );
  }
);

export default PasswordInputField;
