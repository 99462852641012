import useScreen from "./useScreen";
import useDevice from "./useDevice";

const Hooks = {
  useScreen,
  useDevice,
};

export { useScreen, useDevice };

export default Hooks;
