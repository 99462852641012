import { Outlet, useNavigate } from "react-router-dom";
import { NavComponent, FooterComponent, FloatingScroller } from "../components";
import Services from "../services";
import { useEffect } from "react";

const General = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const loginStatus = Services.Auth.IsUserLogedIn();
    if (loginStatus === false) {
      return navigate("/");
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="bg-[--dark]">
        <NavComponent />
      </div>
      <div className="dark:bg-[--dark]">
        <div className="mainContainer">
          {/* <NavComponent /> */}
          <Outlet />
          {/* <FooterComponent /> */}
          <FloatingScroller />
        </div>
      </div>
      <div className="bg-[--dark]">
        <FooterComponent />
      </div>
    </>
  );
};

export default General;
