import { useState, useEffect } from "react";
import { ThemeProvider, Button } from "@mui/material";
import { Textarea, Input, Autocomplete } from "@mui/joy";
import Utils from "../../utils/index";
import { PreLoaderComponent2, WarningAlert } from "../../components";
import DirectionsCarRoundedIcon from "@mui/icons-material/DirectionsCarRounded";
import AccessibleIcon from "@mui/icons-material/Accessible";
import Services from "../../services";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";
import PaletteIcon from "@mui/icons-material/Palette";

type detailsType = {
  title: string;
  description: string;
  location: string;
  engine: string;
  drive_train: string;
  body_style: string;
  exterior_color: string;
  interior_color: string;
};

const Details = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const carBodyStyles: string[] = [
    "Coupe",
    "Convertible",
    "Hatchback",
    "Sedan",
    "SUV/Crossover",
    "Truck",
    "Van/Minivan",
    "Wagon",
  ];
  const driveTrainOptions: string[] = [
    "Rear-wheel drive",
    "4WD/AWD",
    "Front-wheel drive",
  ];
  const [listing, setListing] = useState<any>({});
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [location, setlocation] = useState<string>("");
  const [engine, setEngine] = useState<string>("");
  const [drivetrain, setDrivetrain] = useState<string>("");
  const [bodyStyle, setBodyStyle] = useState<string>("");
  const [exteriorColor, setExteriorColor] = useState<string>("");
  const [interiorColor, setInteriorColor] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [listId, setListId] = useState<number>(0);
  const [detailApproved, setDetailsApproved] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [colorType, setColorType] = useState<string>("");

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      location: "",
      engine: "",
      drivetrain: "",
      bodystyle: "",
      exteriorcolor: "",
      interiorcolor: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required().trim().min(8).max(40),
      description: Yup.string()
        .required(t("details_submission_page.validation.7hszelj17"))
        .trim()
        .min(20, t("details_submission_page.validation.d44rofej8"))
        .max(100, t("details_submission_page.validation.r10zw3mxl")),
      location: Yup.string().required().trim().max(30),
      engine: Yup.string()
        .trim()
        .required(t("details_submission_page.validation.iqcb5d0wu"))
        .min(3)
        .max(30),
      drivetrain: Yup.string()
        .trim()
        .required(t("details_submission_page.validation.64cptmg33"))
        .min(4)
        .max(64),
      bodystyle: Yup.string()
        .trim()
        .required(t("details_submission_page.validation.xnnec0nyd")),
      exteriorcolor: Yup.string()
        .trim()
        .required(t("details_submission_page.validation.8yxs5t052")),
      interiorcolor: Yup.string()
        .trim()
        .required(t("details_submission_page.validation.dbfh0k0vl")),
    }),
    onSubmit: async (values) => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      handleSubmit();
    },
  });

  const resetForms = () => {
    setTitle("");
    setDescription("");
    setlocation("");
    setEngine("");
    setDrivetrain("");
    setBodyStyle("");
    setBodyStyle("");
    setExteriorColor("");
    setInteriorColor("");
  };

  const handleSubmit = () => {
    const data: detailsType = {
      title: title,
      description: description,
      location: location,
      engine: engine,
      drive_train: drivetrain,
      body_style: bodyStyle,
      exterior_color: exteriorColor,
      interior_color: interiorColor,
    };
    setLoading(true);
    Services.Listing.UpdateLisitng(listId, data)
      .then((res) => {
        setLoading(false);
        if (res.status) {
          Swal.fire(
            t("details_submission_page.3hapykjbd"),
            t("details_submission_page.sj1wlh9d2")
          );
          resetForms();
          return navigate("/condition/" + params.list_id);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message.split(".")[0],
          });
        }
      });
  };

  const getListingInfo = () => {
    params?.list_id &&
      Services.Listing.GetOneListingForUser(params?.list_id).then((res) => {
        if (res.status && res.data) {
          setListing(res.data);
          const data: any = res.data;
          // user can't edit details if application is not approved or meeting has been schedule or details submission is not pending
          if (data.application_status !== "approved") {
            return navigate(`/application/${params?.list_id}`);
          } else if (data.details_submission_status === "approved") {
            if (data?.auction) {
              return navigate(`/auction/${params?.list_id}`);
            }
          }
          setDetailsApproved(
            data.details_submission_status === "approved" ? true : false
          );
          formik.setValues({
            title: data.title ? data.title : "",
            description: data.description,
            location: data.location,
            engine: data.engine,
            drivetrain: data.drive_train,
            bodystyle: data.body_style,
            exteriorcolor: data.exterior_color,
            interiorcolor: data.interior_color,
          });
          setListId(data.id);
          setTitle(data.title ? data.title : "");
          setDescription(data.description ? data.description : "");
          var carLocation = [];
          if (data?.location?.length > 0) {
            carLocation = data?.location?.split(" ")?.map((str: string) => {
              if (
                str != listing.country &&
                str != listing.zip_code &&
                str != listing.city_and_province
              ) {
                return str;
              }
            });
          }
          setlocation(
            carLocation.length > 0 ? carLocation?.join(" ")?.trim() : ""
          );
          setEngine(data.engine ? data.engine : "");
          setDrivetrain(data.drive_train ? data.drive_train : "");
          setBodyStyle(data.body_style ? data.body_style : "");
          setExteriorColor(data.exterior_color ? data.exterior_color : "");
          setInteriorColor(data.interior_color ? data.interior_color : "");
        }
      });
  };

  useEffect(() => {
    getListingInfo();
  }, [params?.list_id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubTitle = (value: string, titleType: string) => {
    // add new values at the end of description
    formik.setFieldTouched("description", true);
    formik.setFieldValue(
      "description",
      description +
        (description ? ", " : "") +
        value[0]?.toUpperCase() +
        value.substring(1, value.length)
    );
    setDescription(
      description +
        (description ? ", " : " ") +
        value[0]?.toUpperCase() +
        value.substring(1, value.length)
    );
  };

  const disabledButn = (value: string, titleType: string) => {
    // if suggestion option has addded in the desciption then disable the button
    let valueArray = value.split(/[,-\s\/]/g);
    let descriptionArray = description.split(/[,-\s\/]/g);
    let isDisabled = false;
    if (descriptionArray?.length > 0) {
      descriptionArray?.map((item: string) => {
        valueArray.length > 0 &&
          valueArray?.map((val: string) => {
            if (val.toLowerCase() === item.toLowerCase()) {
              isDisabled = true;
            }
          });
      });
    }
    return isDisabled;
  };

  return (
    <>
      <PageComponents.MetaComponent
        title={t("details_submission_page.title")}
      />
      {loading && <PreLoaderComponent2 />}
      <div className="">
        <div className="flex flex-col px-1 lg:px-3">
          <div className="flex justify-between items-center">
            <h1 className="p1 font-normal">
              <span
                className="text-[var(--main-color)] hover:text-[var(--dark-color)] cursor-pointer"
                onClick={() => navigate("/listings")}
              >
                {t("details_submission_page.xqf6039rk")}{" "}
              </span>{" "}
              {listing?.year ? "—" : ""} {listing?.year} {listing?.make}{" "}
              {listing?.model}
              {listing?.title ? " — " + listing?.title : ""}
            </h1>
          </div>
          <form
            noValidate
            onSubmit={formik.handleSubmit}
            className="lg:w-3/4 pt-8"
          >
            <div className="flex flex-col gap-4">
              <div className="InputFieldSetting">
                <label className="p1 px-1" htmlFor="title">
                  {t("details_submission_page.kb5xxudvf")}
                  <sup className="requireField">*</sup>
                </label>
                <Input
                  id="title"
                  name="title"
                  variant="outlined"
                  required={true}
                  value={formik.values.title}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(
                      Utils.RegExp.carDesAllowedSymbols,
                      ""
                    );
                    formik.handleChange(e);
                    setTitle(e.target.value);
                  }}
                  slotProps={{
                    input: {
                      maxLength: 40,
                    },
                  }}
                  sx={
                    formik?.errors?.title && formik?.touched?.title
                      ?themes.JoyInputError_2
                      :themes.JoyGrayShadeInputStyle2_2
                  }
                  onBlur={formik.handleBlur}
                />
                <WarningAlert
                  message={formik.touched?.title ? formik.errors?.title : ""}
                />
              </div>
              <div className="InputFieldSetting">
                <label className="p1 px-1" htmlFor="description">
                  {t("details_submission_page.ez6nsk4ya")}
                  <sup className="requireField">*</sup>
                </label>
                <Textarea
                  id="description"
                  name="description"
                  variant="outlined"
                  required={true}
                  value={description}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(
                      Utils.RegExp.carDesAllowedSymbols,
                      ""
                    );
                    formik.handleChange(e);
                    setDescription(e.target.value);
                  }}
                  minRows={1}
                  maxRows={3}
                  slotProps={{
                    textarea: {
                      maxLength: 300,
                    },
                  }}
                  sx={
                    formik?.errors?.description && formik?.touched?.description
                      ?themes.JoyInputError_2
                      :themes.JoyGrayShadeInputStyle2_2
                  }
                  onBlur={formik.handleBlur}
                />
                <WarningAlert
                  message={
                    formik.touched?.description
                      ? formik.errors?.description
                      : ""
                  }
                />
                {Object.keys(listing)?.length > 0 && (
                  <div>
                    <label className="p1 px-1" htmlFor="description">
                      {t("details_submission_page.9q9nch6n5")}{" "}
                    </label>
                    <div className="flex justify-start items-center gap-2 flex-wrap">
                      <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                        <Button
                          variant="outlined"
                          onClick={() => handleSubTitle(listing.year, "year")}
                          disabled={disabledButn(listing.year, "year")}
                        >
                          {listing.year}
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => handleSubTitle(listing.make, "make")}
                          disabled={disabledButn(listing.make, "make")}
                        >
                          {listing.make}
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => handleSubTitle(listing.model, "model")}
                          disabled={disabledButn(listing.model, "model")}
                        >
                          {listing.model}
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() =>
                            handleSubTitle(listing.transmission, "transmission")
                          }
                          disabled={disabledButn(
                            listing.transmission,
                            "transmission"
                          )}
                        >
                          {listing.transmission}
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() =>
                            handleSubTitle(`~${listing.mileage} KM`, "mileage")
                          }
                          disabled={disabledButn(
                            `~${listing.mileage} KM`,
                            "mileage"
                          )}
                        >
                          {listing.mileage} KM
                        </Button>
                        {engine && (
                          <Button
                            variant="outlined"
                            onClick={() => handleSubTitle(engine, "engine")}
                            disabled={disabledButn(engine, "engine")}
                          >
                            {engine}
                          </Button>
                        )}
                        {drivetrain && (
                          <Button
                            variant="outlined"
                            onClick={() =>
                              handleSubTitle(drivetrain, "drivetrain")
                            }
                            disabled={disabledButn(drivetrain, "drivetrain")}
                          >
                            {drivetrain}
                          </Button>
                        )}
                        {bodyStyle && (
                          <Button
                            variant="outlined"
                            onClick={() =>
                              handleSubTitle(bodyStyle, "bodyStyle")
                            }
                            disabled={disabledButn(bodyStyle, "bodyStyle")}
                          >
                            {bodyStyle}
                          </Button>
                        )}
                      </ThemeProvider>
                    </div>
                  </div>
                )}
              </div>
              <div className="InputFieldSetting">
                <label className="p1 px-1" htmlFor="location">
                  {t("details_submission_page.v334y3w0z")}
                  <sup className="requireField">*</sup>
                </label>
                <Input
                  id="location"
                  name="location"
                  variant="outlined"
                  required={true}
                  value={location}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(
                      Utils.RegExp.carDesAllowedSymbols,
                      ""
                    );
                    formik.handleChange(e);
                    setlocation(e.target.value);
                  }}
                  slotProps={{
                    input: {
                      maxLength: 95,
                    },
                  }}
                  sx={
                    formik?.errors?.location && formik?.touched?.location
                      ?themes.JoyInputError_2
                      :themes.JoyGrayShadeInputStyle2_2
                  }
                  endDecorator={
                    <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                      <Button variant="text">
                        {listing?.country
                          ? (listing?.country?.toLowerCase() === "us"
                              ? listing?.zip_code || ""
                              : listing?.country?.toLowerCase() === "ca"
                                ? listing?.city_and_province || ""
                                : "") +
                              " " +
                              listing?.country || ""
                          : ""}
                      </Button>
                    </ThemeProvider>
                  }
                  onBlur={formik.handleBlur}
                />
                <WarningAlert
                  message={
                    formik.touched?.location ? formik.errors?.location : ""
                  }
                />
              </div>
              <div className="flex flex-col md:flex-row gap-2">
                <div className="InputFieldSetting">
                  <label className="p1 px-1" htmlFor="engine">
                    {t("details_submission_page.w9s2pg9t8")}
                    <sup className="requireField">*</sup>
                  </label>
                  <Input
                    id="engine"
                    name="engine"
                    variant="outlined"
                    required={true}
                    value={formik.values.engine}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(
                        Utils.RegExp.carDesAllowedSymbols,
                        ""
                      );
                      formik.handleChange(e);
                      setEngine(e.target.value);
                    }}
                    slotProps={{
                      input: {
                        maxLength: 60,
                      },
                    }}
                    sx={
                      formik?.errors?.engine && formik?.touched?.engine
                        ?themes.JoyInputError_2
                        :themes.JoyGrayShadeInputStyle2_2
                    }
                    onBlur={formik.handleBlur}
                  />
                  <WarningAlert
                    message={
                      formik.touched?.engine ? formik.errors?.engine : ""
                    }
                  />
                </div>
                <div className="InputFieldSetting">
                  <label className="p1 px-1" htmlFor="drivetrain">
                    {t("details_submission_page.6icpz7y9z")}
                    <sup className="requireField">*</sup>
                  </label>
                  <Autocomplete
                    id="drivetrain"
                    name="drivetrain"
                    options={driveTrainOptions}
                    value={formik?.values?.drivetrain}
                    onChange={(e: any, value: any) => {
                      setDrivetrain(value);
                      formik.setFieldValue("drivetrain", value);
                      formik.setFieldTouched("drivetrain", true);
                    }}
                    disableClearable={true}
                    required={true}
                    startDecorator={
                      <AccessibleIcon sx={{ color: "var(--main-color)" }} />
                    }
                    sx={
                      formik?.errors?.drivetrain && formik?.touched?.drivetrain
                        ?themes.JoyInputError_2
                        :themes.JoyGrayShadeInputStyle2_2
                    }
                    onBlur={formik.handleBlur}
                  />
                  <WarningAlert
                    message={
                      formik.touched?.drivetrain
                        ? formik.errors?.drivetrain
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="InputFieldSetting">
                <label className="p1 px-1" htmlFor="bodystyle">
                  {t("details_submission_page.c6y4qurtf")}
                  <sup className="requireField">*</sup>
                </label>
                <Autocomplete
                  id="bodystyle"
                  name="bodystyle"
                  options={carBodyStyles}
                  value={bodyStyle}
                  onChange={(e: any, value: any) => {
                    formik.setFieldValue("bodystyle", value);
                    formik.setFieldTouched("bodystyle", true, false);
                    setBodyStyle(value);
                  }}
                  disableClearable={true}
                  required={true}
                  startDecorator={
                    <DirectionsCarRoundedIcon
                      sx={{ color: "var(--main-color)" }}
                    />
                  }
                  sx={
                    formik?.errors?.bodystyle && formik?.touched?.bodystyle
                      ?themes.JoyInputError_2
                      :themes.JoyGrayShadeInputStyle2_2
                  }
                  onBlur={formik.handleBlur}
                />
                <WarningAlert
                  message={
                    formik?.touched?.bodystyle ? formik?.errors?.bodystyle : ""
                  }
                />
              </div>
              <div className="flex flex-col">
                <p className="p1 px-1">
                  {t("details_submission_page.color_picker_label")}{" "}
                  <sup className="requireField">*</sup>
                </p>
                <div className="flex flex-col md:flex-row gap-2 justify-center items-start">
                  <div className="InputFieldSetting relative">
                    <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                      <Button
                        variant="contained"
                        type="button"
                        onClick={() => {
                          formik?.setFieldTouched("exteriorcolor", true);
                          setColorType("exterior");
                          setOpenModal(true);
                        }}
                        sx={{
                          background: exteriorColor
                            ? exteriorColor
                            : "var(--main-color)",
                          "&:hover": {
                            background: exteriorColor
                              ? exteriorColor
                              : "var(--main-color)",
                            opacity: 0.9,
                          },
                          "&:focus": {
                            background: exteriorColor
                              ? exteriorColor
                              : "var(--main-color)",
                            opacity: 0.9,
                          },
                          color:
                            exteriorColor.toLowerCase() === "white"
                              ? "black"
                              : "white",
                        }}
                        id="exteriror_picker"
                      >
                        <PaletteIcon className="me-2" />{" "}
                        {t("details_submission_page.select_text") +
                          " " +
                          t("details_submission_page.e5gzqci3g")}
                      </Button>
                    </ThemeProvider>

                    <WarningAlert
                      message={
                        formik?.touched?.exteriorcolor
                          ? formik?.errors?.exteriorcolor
                          : ""
                      }
                    />
                  </div>
                  <div className="InputFieldSetting relative">
                    <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                      <Button
                        variant="contained"
                        type="button"
                        onClick={() => {
                          formik?.setFieldTouched("interiorcolor", true);
                          setColorType("interior");
                          setOpenModal(true);
                        }}
                        sx={{
                          background: interiorColor
                            ? interiorColor
                            : "var(--main-color)",
                          "&:hover": {
                            background: interiorColor
                              ? interiorColor
                              : "var(--main-color)",
                            opacity: 0.9,
                          },
                          "&:focus": {
                            background: interiorColor
                              ? interiorColor
                              : "var(--main-color)",
                            opacity: 0.9,
                          },
                          color:
                            interiorColor.toLowerCase() === "white"
                              ? "black"
                              : "white",
                        }}
                        id="interiror_picker"
                      >
                        <PaletteIcon className="me-2" />{" "}
                        {t("details_submission_page.select_text") +
                          " " +
                          t("details_submission_page.2xwpepu29")}
                      </Button>
                    </ThemeProvider>
                    <WarningAlert
                      message={
                        formik?.touched?.interiorcolor
                          ? formik?.errors?.interiorcolor
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="py-2 flex justify-start items-center">
                <ThemeProvider theme={themes.ButtonTheme_2}>
                  <Button
                    type="submit"
                    variant="contained"
                    className="hover:underline-offset-1 cursor-pointer"
                    disabled={
                      detailApproved ||
                      formik.isSubmitting ||
                      listing?.details_submission_status !== "pending"
                    }
                  >
                    {t("details_submission_page.5g947t2my")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="CurrentColor"
                      viewBox="0 0 448 512"
                      className="w-4 h-4 ml-2"
                    >
                      <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                    </svg>
                  </Button>
                </ThemeProvider>
              </div>
            </div>
          </form>
        </div>
      </div>
      {openModal && (
        <PageComponents.ColorPicker
          open={openModal}
          setIsModalOpen={setOpenModal}
          colorType={colorType}
          color={
            colorType === "exterior"
              ? exteriorColor
              : colorType === "interior"
                ? interiorColor
                : ""
          }
          setColor={
            colorType === "exterior"
              ? setExteriorColor
              : colorType === "interior"
                ? setInteriorColor
                : null
          }
          formik={formik}
        />
      )}
    </>
  );
};

export default Details;
