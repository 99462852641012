import { ApiConfig } from "../config";
import Auth from "./Auth.service";

const AddToWatchList = (data: any) => {
  return ApiConfig.post("/api/user/watch-list", data)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error.response.data;
    });
};

const GetWatchLists = () => {
  return ApiConfig.get("/api/user/watch-list")
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error.response.data;
    });
};

const GetWatchList = (
  fetch: string,
  offset: number,
  limit: number,
  sort: string
) => {
  return ApiConfig.get(
    `/api/user/watch-list?fetch=${fetch}&offset=${offset}&limit=${limit}&sort=${sort}`
  )
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error.response.data;
    });
};

const DeleteFromWatchList = (id: number | string) => {
  return ApiConfig.delete(`/api/user/watch-list/${id}`)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error.response.data;
    });
};

const WatchList = {
  GetWatchLists,
  AddToWatchList,
  DeleteFromWatchList,
  GetWatchList,
};

export default WatchList;
