import React, { useState, useEffect, lazy, Suspense } from "react";
import PageComponents from "../components";
import { Tabs, Tab, Box, ThemeProvider } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Services from "../../services";
import { PreLoaderComponent } from "../../components";
import { useTranslation } from "react-i18next";
import Utils from "../../utils";

const ApplicationAuctionInfo = lazy(
  () => import("../components/ApplicationAuctionInfo.component")
);
const ApplicationBids = lazy(
  () => import("../components/ApplicationBids.component")
);
const ApplicationQA = lazy(
  () => import("../components/ApplicationQA.component")
);
const ApplicationComments = lazy(
  () => import("../components/ApplicationComments.main.page")
);

const ApplicationAuction = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [auction_info, setAuctionInfo] = useState<any>({});

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getListingInfo = () => {
    params.list_id &&
      Services.Listing.GetOneListingForUser(params.list_id).then((res: any) => {
        if (res.status && res.data) {
          const data = res.data;
          if (
            data.application_status !== "approved" ||
            !data.title ||
            !data.description ||
            !data.engine ||
            !data.drive_train ||
            !data.body_style ||
            !data.exterior_color ||
            !data.interior_color ||
            !data.service_history ||
            data.photos?.exterior?.length === 0 ||
            data.photos?.interior?.length === 0 ||
            data.photos?.mechanical?.length === 0 ||
            // data.photos?.docs?.length === 0 ||
            (!data?.owner_is_dealer && !data.schedule_time_slot) ||
            (!data?.owner_is_dealer && !data.review) ||
            !data.highlights ||
            !data.equipment ||
            !data.ownership_history ||
            data.details_submission_status !== "approved"
          ) {
            return navigate(`/application/${params?.list_id}`);
          }
        }
      });
  };

  useEffect(() => {
    getListingInfo();
  }, [params]);

  return (
    <>
      <PageComponents.MetaComponent title={t("user_application_page.title")} />
      <div className="">
        <Box
          // sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: "background.paper" }}
          // sx={{
          //   overflowX: "auto",
          // }}
        >
          <ThemeProvider theme={Utils.Themes().TabsStyle_2}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              sx={{
                ".MuiButtonBase-root.MuiTab-root.Mui-selected": {
                  color: "var(--main-color)",
                },
                ".MuiTabs-indicator ": {
                  backgroundColor: "var(--main-color)",
                },
              }}
            >
              <Tab label={t("user_application_page.opa9aj8wj")} />
              <Tab label={t("user_application_page.1mwc8xr7z")} />
              <Tab label={t("user_application_page.4051mynuf")} />
              <Tab label={t("user_application_page.2j5489mb5")} />
            </Tabs>
          </ThemeProvider>
        </Box>
        <div className="mx-0 py-0 pt-8">
          <Suspense fallback={<PreLoaderComponent />}>
            {value === 0 ? (
              <ApplicationAuctionInfo setAuctionInfo={setAuctionInfo} />
            ) : value === 1 ? (
              <ApplicationBids auction_info={{ ...auction_info }} />
            ) : value === 2 ? (
              <ApplicationQA auction_info={{ ...auction_info }} />
            ) : value === 3 ? (
              <ApplicationComments auction_info={{ ...auction_info }} />
            ) : (
              <></>
            )}
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default ApplicationAuction;
