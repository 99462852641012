// method 3
// Helper function to convert color name to RGB
function colorNameToRGB(colorName: string): number[] | null {
  const ctx = document.createElement("canvas").getContext("2d");
  if (ctx) {
    ctx.fillStyle = colorName;
    const rgb = ctx.fillStyle.match(/\d+/g)?.map(Number);
    if (rgb && rgb.length >= 3) {
      return [rgb[0], rgb[1], rgb[2]];
    }
  }
  return null; // return null if color name is invalid
}

// Helper function to convert RGB to hex
function rgbToHex(r: number, g: number, b: number): string {
  return (
    "#" +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  );
}

// Function to invert color by name
function InvertColorByName(colorName: string): string {
  // Convert the color name to RGB
  const rgb = colorNameToRGB(colorName?.toLowerCase());

  if (rgb) {
    // If RGB is valid, invert the RGB values
    const [r, g, b] = rgb;
    const invertedR = 255 - r;
    const invertedG = 255 - g;
    const invertedB = 255 - b;

    // Convert the inverted RGB back to hex color
    return rgbToHex(invertedR, invertedG, invertedB);
  } else {
    // Return a ligh color if color is black else (e.g., black) if the color name is not recognized
    return colorName?.toLowerCase() === "black" ? "#ffffff" : "#000000";
  }
}

export default InvertColorByName;
