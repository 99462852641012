 import { useEffect, useState } from "react";
import { Icons } from "../../assets";
import Services from "../../services";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { PreLoaderComponent } from "../../components";
import { useTranslation } from "react-i18next";

const UserProfile = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [userProfile, setUserProfile] = useState<any>({});
  const [loadingdata, setLoadingdata] = useState<boolean>(true);

  const getProfileInfo = () => {
    setLoadingdata(true);
    location.state.data.id &&
      Services.User.getOneUserForAdmin(location.state.data.id)
        .then((res) => {
          setLoadingdata(false);
          if (res.status) {
            res.data && setUserProfile(res.data);
          }
        })
        .catch((error) => {
          setLoadingdata(false);
        });
  };

  useEffect(() => {
    getProfileInfo();
  }, []);

  return (
    <>
      <div className="flex justify-center items-center">
        {loadingdata && <PreLoaderComponent />}
        {!loadingdata && userProfile && Object.keys(userProfile)?.length > 0 && (
          <div className="py-4 h-96 mx-auto">
            <div className="flex justify-between items-start gap-2 ">
              <div className="flex juftify-start items-center ">
                <div className="w-24 sm:w-28 md:w-36 h-24 sm:h-28 md:h-36">
                  <img
                    src={
                      userProfile?.avatar
                        ? `${process.env.REACT_APP_HOST_API_KEY}/${userProfile?.avatar}`
                        : Icons?.Profile
                    }
                    loading="lazy"
                    alt="profile-icon"
                    className="w-full h-full rounded-full shadow2 border darkShadow dark:border-[--gray]"
                  />
                </div>
                <div className=" py-2 sm:py-4 md:py-8 ps-2 sm:ps-4">
                  <h3 className="font-semibold p000 overflow-wrap-anywhere dark:text-white">
                    {userProfile?.username}
                  </h3>
                  <h4 className="font-medium p0 overflow-wrap-anywhere dark:text-white">
                    {userProfile?.email}
                  </h4>
                  <p className="text-uppercase p1 overflow-wrap-anywhere">
                    {t("user_details_page.bcnl3e2la")}{" "}
                    {moment(userProfile?.created_at).format("MMMM DD YYYY")}
                  </p>
                </div>
              </div>
            </div>
            <div className="p-3">
              <p className="p1 overflow-wrap-anywhere">{userProfile?.bio}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserProfile;
