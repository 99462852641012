import React, { useContext } from "react";
import { ThemeContext } from "../context/theme.context";
import Utils from "../utils";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ToggleThemeComponentPops {
  applyStyle2?: boolean;
}

const ToggleThemeComponent = ({ applyStyle2 }: ToggleThemeComponentPops) => {
  const { theme, setTheme, isDark } = useContext(ThemeContext);
  const { t } = useTranslation();

  // Toggle between light and dark mode
  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  return (
    <Tooltip title={isDark ? t("mo0ovncr") : t("so0qenqd")}>
      <button
        onClick={() => toggleTheme()}
        type="button"
        className={"navButn " + (applyStyle2 ? "border text-black dark:text-white dark:border-[--gray]" : "themeToggler")}
        id="dark-light-toggler"
      >
        {isDark ? (
          <LightModeIcon fontSize="small" className="w-[18px] h-auto" />
        ) : (
          <DarkModeIcon fontSize="small" className="w-[18px] h-auto" />
        )}
      </button>
    </Tooltip>
  );
};

export default ToggleThemeComponent;
