import React from "react";
import { Link } from "react-router-dom";
import { Images } from "../../assets";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";

const Inspection = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageComponents.MetaComponent
        title={t("inspection_page.title")}
        canonicalUrl={window.location.host}
      />
      <div className="containerSpacing mt-16">
        <div className="shipping">
          <h1 className="head8 font-extrabold pt-6 border-b-8 border-[var(--main-color)] w-fit 	">
            {t("inspection_page.heading")}
          </h1>
          <div className="section my-12">
            <img
              src={Images.Inspection}
              alt="Auto Bids shipping"
              className="w-full h-full"
            />
          </div>
          <div className="section">
            <h3>
              <span className="bg-[var(--main-color)] text-white rounded-[--butn-border-radius]  me-2 px-1.5 py-1 text-base uppercase">
                {t("inspection_page.i65uve2t6")}
              </span>
              {t("inspection_page.lf33wy2aq")}
            </h3>
            <p className="py-3">{t("inspection_page.6dcsan8pg")}</p>
          </div>
          <div className="section">
            <h3>{t("inspection_page.n70t6ufd0")}</h3>
            <p className="py-3">{t("inspection_page.cqmv5xu3g")}</p>
          </div>
          <div className="section">
            <h3>{t("inspection_page.70mu1m5mg")}</h3>
            <div className="flex justify-between items-start flex-wrap gap-3 pt-4">
              <div className="py-2 md:w-1/2 lg:w-1/5">
                <img
                  src={Images.Book2}
                  alt="Auto Bids shipping"
                  className="w-full h-full"
                />
                <div className="flex justify-start items-center gap-2 py-3">
                  <span className="bg-[var(--main-color)] text-white px-3 py-1 flex justify-center items-center rounded-full font-medium">
                    1
                  </span>
                  <p>{t("inspection_page.2mzwhzudg")}</p>
                </div>
              </div>
              <div className="py-2 md:w-1/2 lg:w-1/5">
                <img
                  src={Images.Inspector}
                  alt="Auto Bids shipping"
                  className="w-full h-full"
                />
                <div className="flex justify-start items-center gap-2 py-3">
                  <span className="bg-[var(--main-color)] px-3 py-1 flex justify-center items-center rounded-full font-medium text-white">
                    2
                  </span>
                  <p>{t("inspection_page.d6elrwfjh")}</p>
                </div>
              </div>
              <div className="py-2 md:w-1/2 lg:w-1/5">
                <img
                  src={Images.Review}
                  alt="Auto Bids shipping"
                  className="w-full h-full"
                />
                <div className="flex justify-start items-center gap-2 py-3">
                  <span className="bg-[var(--main-color)] px-3 py-1 flex justify-center items-center rounded-full font-medium text-white">
                    3
                  </span>
                  <p>{t("inspection_page.4w1qnrdx6")}</p>
                </div>
              </div>
              <div className="py-2 md:w-1/2 lg:w-1/5">
                <img
                  src={Images.InspectionReport}
                  alt="Auto Bids shipping"
                  className="w-full h-full"
                />
                <div className="flex justify-start items-center gap-2 py-3">
                  <span className="bg-[var(--main-color)] px-3 py-1 flex justify-center items-center rounded-full font-medium text-white">
                    4
                  </span>
                  <p>{t("inspection_page.w1vozgaz6")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <h3>{t("inspection_page.bglsvrp2w")}</h3>
            <p className="py-3">
              {t("inspection_page.efquajwd8")}{" "}
              <Link to="tel:9789552355">(978) 955-2355</Link>{" "}
              {t("inspection_page.vvkw0mmpr")}{" "}
              <Link to="mailto:shipping@auto-bids.ch">
                shipping@auto-bids.ch
              </Link>{" "}
              {t("inspection_page.6ceczm3yj")}
            </p>
          </div>
          <div className="section">
            <h3>{t("inspection_page.65oljq5k4")}</h3>

            <ul className="py-3 list-disc ps-12 text-base">
              <li>{t("inspection_page.4g1knozp7")}</li>
              <li>{t("inspection_page.nzfemd58v")}</li>

              <li>
                {t("inspection_page.wnaefc37u")}{" "}
                <Link to="tel:9789552355">(978) 955-2355</Link>{" "}
                {t("inspection_page.yb6e16757")}
                <Link to="mailto:shipping@auto-bids.ch">
                  shipping@auto-bids.ch
                </Link>
                .
              </li>
              <li>
                {t("inspection_page.btnv7vrsj")}{" "}
                <Link to="/shipping">{t("inspection_page.0k2eb5fhm1")}</Link>{" "}
                {t("inspection_page.0k2eb5fhm")}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Inspection;
