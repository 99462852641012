import React, { useState, useEffect, memo } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { Modal, ModalClose, Sheet, Input, Textarea } from "@mui/joy";
import Utils from "../../utils";
import Services from "../../services";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

interface modalProps {
  modalOpen: any;
  setIsModalOpen: any;
  getQuestions: any;
}

const AskQuestion = memo(
  ({ modalOpen, setIsModalOpen, getQuestions }: modalProps) => {
    const location = useLocation();
    const { t } = useTranslation();
    const [question, setQuestion] = useState("");
    const themes = Utils.Themes();

    function auto_grow(element: any) {
      element.style.height = "5px";
      element.style.height = element.scrollHeight + "px";
    }

    function reset_grow(element: any) {
      element.style.height = "5px";
    }

    useEffect(() => {
      let dynamicTextArea = document.getElementById("askQuestion");
      dynamicTextArea && auto_grow(dynamicTextArea);
    }, []);

    const handleSubmit = (e: any) => {
      e.preventDefault();

      const data = {
        text: question,
      };

      location?.state?.uuid &&
        Services.QA.SubmitQuestion(location.state.uuid, data).then((res) => {
          if (res.status) {
            setIsModalOpen(!modalOpen);
            Swal.fire(
              t(
                "auction_details_page.ask_question_modal.sweat_alert.success_title"
              ),
              t(
                "auction_details_page.ask_question_modal.sweat_alert.question_saved_message"
              )
            );
            setQuestion("");
            getQuestions();
          }
        });
    };

    useEffect(() => {
      let dynamicTextArea = document.getElementById("askQuestion");
      dynamicTextArea && reset_grow(dynamicTextArea);
      setQuestion("");
    }, [modalOpen]);

    return (
      <div>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={modalOpen}
          hideBackdrop={true}
          onClose={() => {
            setIsModalOpen(false);
          }}
          sx={{
            ...themes.JoyModalStyle_2,
            // animation: `${modalOpen ? "fadeIn" : "fadeOut"} 0.5s ease-in-out`,
          }}
        >
          <Sheet
            variant="outlined"
            sx={{ ...themes.JoyModalSheetStyle_2, minWidth: "35%" }}
          >
            <ModalClose
              variant="outlined"
              size="sm"
              sx={themes.JoyModalCloseStyle_2}
            />
            <form className="mt-4 w-100" onSubmit={(e) => handleSubmit(e)}>
              <div className="flex flex-col">
                <div className="mt-3 w-full">
                  <h3 className="head text-center leading-6 font-bold text-gray-900 border-b-2 pb-2 w-full dark:border-b-[--gray]">
                    {t("auction_details_page.ask_question_modal.title")}
                  </h3>
                </div>
                <div className="pt-8 p1">
                  <div className="w-full InputFieldSetting">
                    <Textarea
                      name="ask-a-question"
                      id="askQuestion"
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                      placeholder={t(
                        "auction_details_page.ask_question_modal.question_input_placeholder"
                      )}
                      sx={themes.JoyGrayShadeInputStyle2_2}
                      required={true}
                      slotProps={{
                        textarea: {
                          maxLength: 256,
                        },
                      }}
                    />
                  </div>
                  <div className=" py-1 pt-3">
                    <ThemeProvider theme={themes.ButtonTheme_2}>
                      <Button variant="contained" fullWidth type="submit">
                        {t(
                          "auction_details_page.ask_question_modal.submit_butn_text"
                        )}
                      </Button>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </form>
          </Sheet>
        </Modal>
      </div>
    );
  }
);

export default AskQuestion;
