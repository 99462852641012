import { useEffect, useState, lazy, Suspense } from "react";
import { AuctionNavComponent } from "../../components";
import { Grid, Pagination, Button, ThemeProvider } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import PageComponents from "../components";
import Services from "../../services";
import { AppImages } from "../../assets";
import { useTranslation } from "react-i18next";
import Utils from "../../utils";

const AuctionCardComponent = lazy(
  () => import("../components/AuctionCard.component")
);

type listingType = any[];

function Home() {
  const location = useLocation();
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [listings, setListings] = useState<listingType>([]);
  const [total, setTotal] = useState<number>(0);
  const [sort, setSort] = useState<string | null>(
    location.pathname === "/past-auctions" ? "recently_ended" : "ending_soon"
  );
  const [startYear, setStartYear] = useState<number | null>(null);
  const [endYear, setEndYear] = useState<number | null>(null);
  const [transmission, setTransmission] = useState<string | null>("");
  const [bodyStyle, setBodyStyle] = useState<string | null>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [listingsType, setListingsType] = useState<string>(
    location.pathname === "/past-auctions" ? "results" : "auctions"
  ); // auctions or results
  const [newAuctions, setNewAuctions] = useState<listingType>([]);
  const [search, setSearch] = useState<string | null>("");
  const [make, setMake] = useState<string | null>("");
  const [model, setModel] = useState<string | null>("");

  const getListings = (newlyListed?: true) => {
    setListings([]);
    window.scrollTo(0, 100);
    setLoading(true);
    listingsType &&
      Services.Listing.GetListings(
        offset,
        limit,
        newlyListed ? "auctions" : listingsType,
        newlyListed ? "newly_listed" : sort,
        startYear,
        endYear,
        transmission,
        search,
        bodyStyle,
        null,
        null,
        make,
        model
      )
        .then((res) => {
          setLoading(false);
          if (newlyListed) {
            // set new auction
            setNewAuctions(res.data);
          } else {
            // set list of past or current operation
            setListings(res.data);
            setTotal(Math.ceil(res.total / limit));
          }
        })
        .catch((error) => {
          setLoading(false);
        });
  };

  useEffect(() => {
    getListings(true);
  }, []);

  useEffect(() => {
    setTotal(0);
    setListingsType(
      location.pathname !== "/past-auctions" ? "auctions" : "results"
    );
    setSort(
      location.pathname !== "/past-auctions" ? "ending_soon" : "recently_ended"
    );
    setStartYear(null);
    setEndYear(null);
    setTransmission("");
    setBodyStyle("");
    setMake("");
    setModel("");
    setCurrentPage(1);
  }, [location.pathname]);

  useEffect(() => {
    if (location?.state?.refresh) {
      setCurrentPage(1);
      setOffset(0);
      // Optionally reset refresh to false to avoid infinite re-renders
      const newState = { ...location.state, refresh: false };
      window.history.replaceState(newState, "", location.pathname);
    }
  }, [location?.state?.refresh]);

  useEffect(() => {
    getListings();
  }, [
    currentPage,
    sort,
    startYear,
    endYear,
    bodyStyle,
    transmission,
    listingsType,
    make,
    model,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <PageComponents.MetaComponent
        title={"Auto Bids | Swiss No. 1 Auto Bidding Platform for premium cars"}
        canonicalUrl={window.location.host}
      />
      {/* featured content */}
      {location.pathname !== "/past-auctions" && (
        <div className="mainContainer">
          <PageComponents.FeaturedContentComponent />
        </div>
      )}

      {/* auction nav */}
      <section className="mainContainer mt-16">
        <div className="text-gray-600 body-font containerSpacing">
          {/* auction nav component */}
          <AuctionNavComponent
            setSort={setSort}
            sort={sort}
            setStartYear={setStartYear}
            setEndYear={setEndYear}
            setTransmission={setTransmission}
            setBodyStyle={setBodyStyle}
            startYear={startYear}
            endYear={endYear}
            listingsType={listingsType}
            setMake={setMake}
            setModel={setModel}
          />
          <div className="w-full ">
            <div className="h-full w-full">
              <div className="w-full lg:col-span-3 col-span-full">
                <Suspense fallback={<PageComponents.CardSkeletonElements />}>
                  <div className=" text-gray-600 body-font">
                    <div className="flex py-8 lg:py-12 mx-auto-">
                      <div className="flex flex-wrap w-full">
                        <Grid
                          container
                          spacing={3}
                          // justifyContent="center"
                          // alignItems="center"
                        >
                          {loading ? (
                            <PageComponents.CardSkeletonElements />
                          ) : listings?.length > 0 ? (
                            listings?.map((item, index) => (
                              <Grid
                                item
                                className="px-3-"
                                lg={4}
                                md={6}
                                xs={12}
                                key={index}
                              >
                                <AuctionCardComponent
                                  item={item}
                                  showDetailsInProp={false}
                                />
                              </Grid>
                            ))
                          ) : (
                            <p className="info_message">
                              {t("user_application_page.zudr5t9da")}
                            </p>
                          )}
                        </Grid>
                      </div>
                    </div>
                    {!loading && (
                      <div className="flex justify-center items-center py-4">
                        {!loading && listings?.length > 0 && total > 1 && (
                          <Pagination
                            count={total}
                            hidePrevButton
                            hideNextButton
                            page={currentPage}
                            onChange={(e: any, pageNum) => {
                              setCurrentPage(pageNum);
                              setOffset((pageNum - 1) * limit);
                            }}
                            shape="rounded"
                            sx={themes.MuiPagenationStyle_2}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* about */}
      <section className="my-8 ">
        <div
          className="w-full bg-cover bg-center h-80"
          style={{
            backgroundImage: `url('${AppImages.BannerImage}')`,
          }}
        >
          <div className="flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-50">
            <div className="text-center lg:w-3/4 xl:w-3/5 md:w-2/3 w-11/12 space-y-4 flex flex-col gap-1 sm:gap-2 items-center justify-center">
              <h3 className="text-white head6 leading-9">
                {t("home_page.about_section.title")}
              </h3>
              <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                <Link to={"/about"}>
                  <Button
                    variant="contained"
                    sx={{ textTransform: "capitalize" }}
                    size="large"
                  >
                    {t("home_page.about_section.button_text")}
                  </Button>
                </Link>
              </ThemeProvider>
            </div>
          </div>
        </div>
      </section>

      {/* new auctions */}
      <section className="mainContainer">
        <div className="py-8 sm:py-12 w-full containerSpacing">
          <div className="flex  gap-2 justify-between items-center mb-6 flex-wrap">
            <h2 className="head9">{t("home_page.new_list_section.title")}</h2>
            <div>
              <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                <Button
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => window?.scrollTo(0, 0)}
                >
                  {t("home_page.new_list_section.button_text")}
                </Button>
              </ThemeProvider>
            </div>
          </div>
          <Grid container spacing={2}>
            <Suspense fallback={<PageComponents.CardSkeletonElements />}>
              {!loading && newAuctions?.length > 0 ? (
                newAuctions?.map((item, index) => {
                  return (
                    index < 3 && (
                      <Grid
                        item
                        className="px-3"
                        lg={4}
                        md={6}
                        xs={12}
                        key={index}
                      >
                        <AuctionCardComponent
                          getListings={getListings}
                          item={item}
                          showDetailsInProp={false}
                        />
                      </Grid>
                    )
                  );
                })
              ) : loading ? (
                <PageComponents.CardSkeletonElements />
              ) : (
                <p className="info_message">
                  {t("user_application_page.zudr5t9da")}
                </p>
              )}
            </Suspense>
          </Grid>
        </div>
      </section>
    </div>
  );
}

export default Home;
