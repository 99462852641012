import { useEffect, useState, memo } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { Modal, ModalClose, Sheet } from "@mui/joy";
import Utils from "../../utils";
import Services from "../../services";
import Swal from "sweetalert2";
import { WarningAlert } from "../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

type Boolean = boolean;

type ContactFormType = {
  message: string;
};

type AuctionContactType = {
  show: boolean;
  setShow: any;
  listId: number;
};

const AuctionContact = memo(function AuctionContact({
  show,
  setShow,
  listId,
}: AuctionContactType) {
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState<Boolean>(false);

  const formik = useFormik({
    initialValues: { message: "" },
    validationSchema: Yup.object().shape({
      message: Yup.string().required().trim().min(20).max(2000),
    }),
    onSubmit: async (values) => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      handleSubmit();
    },
  });

  const resetForm = () => {
    setMessage("");
  };

  const handleSubmit = () => {
    const data: ContactFormType = {
      message: message.trim(),
    };
    setIsLoading(true);
    Services.Listing.ContactSeller(listId, data)
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          Swal.fire(
            t("auction_contact.sweat_alert.success_title"),
            t("auction_contact.sweat_alert.condition_text")
          );
          resetForm();
        }
        setShow(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message.split(".")[0],
          });
        }
        setShow(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleQuillValue = (field: string, text: string) => {
    formik?.setFieldTouched(field, true);
    formik?.setFieldValue(field, text);
    return;
  };

  return (
    <div className="overflow-y-auto">
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={show}
        hideBackdrop={true}
        onClose={() => {
          setShow && setShow(false);
        }}
        sx={themes.JoyModalStyle_2}
      >
        <Sheet
          className="w-11/12 sm:8/12 md:w-6/12 lg:w-5/12 xl:w-4/12"
          sx={themes.JoyModalSheetStyle_2}
        >
          <ModalClose variant="outlined"  size="sm" sx={themes.JoyModalCloseStyle_2} />
          <div className="flex justify-center align-items-center w-100">
            <h4 className="head capitalize ">
              <b>{t("auction_contact.title")}</b>
            </h4>
          </div>
          <hr className="hrDarkMode" />
          <form onSubmit={formik?.handleSubmit} noValidate autoComplete="">
            <div className="my-4 w-100">
              <div className="py-1 sm:py-1.5 inputLabel">
                <ReactQuill
                  theme="snow"
                  placeholder={"Message"}
                  bounds={".app"}
                  onChange={(value, delta, source, editor) => {
                    setMessage(value);
                    setTimeout(() => {
                      handleQuillValue("message", editor.getText());
                    }, 5000);
                  }}
                  onBlur={(previousSelection, source, editor) =>
                    handleQuillValue("message", editor.getText())
                  }
                  className="max-h-[300px] overflow-auto fadedStyle"
                />
                <WarningAlert
                  message={
                    formik.touched?.message ? formik.errors?.message : ""
                  }
                />
              </div>
            </div>
            <div className="text-center mt-2">
              <ThemeProvider theme={themes.ButtonTheme_2}>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  // className="two-xl-larger-text"
                  disabled={isLoading || formik?.isSubmitting}
                >
                  {t("auction_contact.submit_butn_text")}
                </Button>
              </ThemeProvider>
            </div>
          </form>
        </Sheet>
      </Modal>
    </div>
  );
});

export default AuctionContact;
