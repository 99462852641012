import React, { memo } from "react";
import { Modal, ModalClose, Sheet } from "@mui/joy";
import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import Utils from "../../utils";

type ContactPropsType = {
  open: boolean;
  setOpen: any;
  data: any;
  show_all?: boolean;
  onlyBidder?: boolean;
};

const ContactComponent = memo(function Login({
  open,
  setOpen,
  data,
  show_all,
  onlyBidder,
}: ContactPropsType) {
  const { t } = useTranslation();
  const themes = Utils.Themes();

  return (
    <>
      <div className="overflow-y-auto">
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={open}
          hideBackdrop={true}
          onClose={() => {
            setOpen && setOpen(false);
          }}
          sx={themes.JoyModalStyle_2}
        >
          <Sheet
            className="w-11/12 sm:8/12 md:w-6/12 lg:w-5/12 xl:w-4/12"
            sx={themes.JoyModalSheetStyle_2}
          >
            <ModalClose
              variant="outlined"
              size="sm"
              sx={themes.JoyModalCloseStyle_2}
            />
            <div className="flex justify-center align-items-center w-100">
              <h2 className="head capitalize ">
                <b>{t("contact_modal.f4yj7535p")}</b>
              </h2>
            </div>
            <hr className="hrDarkMode" />
            <div className="overflow-y-auto pt-2">
              <div className="py-2 w-100">
                {!onlyBidder && show_all && (
                  <h1 className="p0 dark:text-white font-bold pt-4 px-2 text-center lg:text-left">
                    {t("contact_modal.pc3c7tfte")}
                  </h1>
                )}
                {!onlyBidder && data?.seller && (
                  <div className="flex flex-col md:flex-row justify-center md:justify-start items-center py-4">
                    <div className="">
                      <Avatar
                        src={
                          data?.seller?.avatar &&
                          process.env.REACT_APP_HOST_API_KEY +
                            "/" +
                            data?.seller?.avatar
                        }
                        alt={data?.seller?.username}
                        className="hover:shadow2 border]"
                        sx={{
                          width: show_all ? "100px" : "125px",
                          height: show_all ? "100px" : "125px",
                        }}
                      />
                    </div>
                    <div className="md:ps-5 break-all	text-center md:text-left">
                      {data?.seller?.name && (
                        <h2 className="font-medium head dark:text-white">
                          {data?.seller?.name}
                        </h2>
                      )}
                      <p className="p00 capitalize font-semibold dark:text-white">
                        {data?.seller?.username}
                      </p>
                      <a href={"mailto:" + data?.seller?.email}>
                        <p className="p1 lowercase font-medium">
                          {data?.seller?.email}
                        </p>
                      </a>
                      <a href={"tel:" + data?.phone_number}>
                        <p className="p1 lowercase">{data?.phone_number}</p>
                      </a>
                    </div>
                  </div>
                )}
              </div>
              {(show_all || onlyBidder) && data?.current_bid && (
                <div className="py-2 w-100">
                  <h1 className="p0 dark:text-white font-bold pt-4 px-2 text-center lg:text-left">
                    {t("contact_modal.sb61d42zt")}
                  </h1>

                  <div className="flex flex-col md:flex-row justify-center md:justify-start items-center py-4">
                    <div className="">
                      <Avatar
                        src={
                          data?.current_bid?.bidder?.avatar &&
                          process.env.REACT_APP_HOST_API_KEY +
                            "/" +
                            data?.current_bid?.bidder?.avatar
                        }
                        alt={data?.current_bid?.bidder?.username}
                        className="hover:shadow2 border]"
                        sx={{
                          width: show_all ? "100px" : "125px",
                          height: show_all ? "100px" : "125px",
                        }}
                      />
                    </div>
                    <div className="md:ps-5 break-all	text-center md:text-left">
                      {data?.current_bid?.bidder?.name && (
                        <h2 className="font-medium head dark:text-white">
                          {data?.current_bid?.bidder?.name}
                        </h2>
                      )}
                      <p className="p00 capitalize font-semibold dark:text-white">
                        {data?.current_bid?.bidder?.username}
                      </p>
                      <a href={"mailto:" + data?.current_bid?.bidder?.email}>
                        <p className="p1 lowercase">
                          {data?.current_bid?.bidder?.email}
                        </p>
                      </a>
                      <a href={"tel:" + data?.bidder?.phone_number}>
                        <p className="p1 lowercase">
                          {data?.current_bid?.bidder?.phone_number}
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Sheet>
        </Modal>
      </div>
    </>
  );
});

export default ContactComponent;
