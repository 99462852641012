import { Grid } from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "../../context/theme.context";
import { Skeleton as MuiSkeleton } from "@mui/material";
import _ from "lodash";

interface NskeletonProps {
  classes?: string;
}

const CardSkeletonComponent = () => {
  const { isDark } = useContext(ThemeContext);

  const style = {
    borderRadius: "0.5rem",
    height: "100%",
    width: "100%",
    background: isDark ? "var(--gray)" : "var(--light)",
  };

  const Nskeleton = ({ classes }: NskeletonProps) => {
    return (
      <div className={`${classes || "w-full h-4"}`}>
        <MuiSkeleton sx={style} variant="rectangular" />
      </div>
    );
  };

  return (
    <div className="listingCard gap-2">
      <Nskeleton classes="w-[100px] h-4" />
      <Nskeleton />
      <Nskeleton classes="w-full h-[240px]" />
      {_.times(4, (index) => (
        <div key={index}>
          <Nskeleton />
        </div>
      ))}
    </div>
  );
};

export default CardSkeletonComponent;

const CardSkeletonElements = () => {
  let skeletons: JSX.Element[] = [];
  for (let i = 0; i < 3; i++) {
    skeletons.push(
      <Grid lg={4} md={6} xs={12} key={i}>
        <div className="p-1.5">
          <CardSkeletonComponent />
        </div>
      </Grid>
    );
  }

  return skeletons;
};

export { CardSkeletonElements };
