import { useEffect, useRef, useState, memo, useContext } from "react";
import { Avatar, Badge, Button } from "@mui/material";
import { IconButton } from "@mui/material";
import Services from "../services";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { isAdmin_and_loged_in } from "../utils/checkUserType.utils";
import { Menu } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { AppImages } from "../assets";
import ToggleThemeComponent from "./ToggleTheme.component";
import { ThemeContext } from "../context/theme.context";
import LanguageSwitcher from "./LanguageSwitcher";

const DashboardNav = memo(({ showSidebar, setShowSidebar }: any) => {
  const { t } = useTranslation();
  const { isDark } = useContext(ThemeContext);
  const notifyRef = useRef<HTMLDivElement>(null);
  const notifyMessageRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);
  const profileMenuRef = useRef<HTMLUListElement>(null);
  const user = Services.Storage.getCurrentUser();
  const navigate = useNavigate();
  const isUserLogedIn = isAdmin_and_loged_in();
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [loadingNotifications, setLoadingNotifications] =
    useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    document.addEventListener("click", (e: any) => {
      const cur = notifyRef.current;
      const node = e && e.target;
      const curMessage = notifyMessageRef.current;
      if (node && cur && cur.contains(node)) {
        setShowNotifications(!showNotifications);
      } else if (node && curMessage && curMessage.contains(node)) {
        setShowNotifications(true);
      } else {
        setShowNotifications(false);
      }
    });
  }, [showNotifications]);

  useEffect(() => {
    document.addEventListener("click", (e: any) => {
      const curProf = profileRef.current;
      const eNode = e && e.target;
      const curMenu = profileMenuRef.current;
      if (
        (eNode && curProf && curProf.contains(eNode)) ||
        (eNode && curMenu && curMenu.contains(eNode))
      ) {
        // show/hide profile if click on avatar
        setShowProfile(!showProfile);
      } else {
        setShowProfile(false);
      }
    });
  }, [showProfile]);

  const handleLogout = async () => {
    await Services.Auth.Logout()
      .then(async (res) => {
        await Services.DeviceToken.DeleteDeviceToken()
          .then((res) => {
            window.location.reload();
          })
          .catch((error) => {
            window.location.reload();
          });
      })
      .catch((error) => {
        window.location.reload();
      });
  };

  const getNotifications = () => {
    setLoadingNotifications(true);
    Services.Notification.GetAdminNotification(0, 15, false)
      .then((res) => {
        setLoadingNotifications(false);
        if (res.status) {
          setNotifications(res.data);
          setTotal(res.total);
        }
      })
      .catch((error) => {
        setLoadingNotifications(false);
      });
  };

  const navigateNotification = (item: any) => {
    const id: string = item?.extra_data?.listing?.id
      ? item?.extra_data?.listing?.id
      : "";

    let notificationTypeByPats = item?.notification_type?.split("_");

    let navigationPath: string =
      notificationTypeByPats[0] === "auction"
        ? "/list-detail"
        : "/auction-details/" + id;

    navigate(navigationPath, {
      state: { listing_id: id },
    });
  };

  const markNotificationAsRead = (item: any) => {
    const data = {
      notifications: [item?.id],
    };
    Services.Notification.MarkAdminNotificationAsRead(data).then((res) => {
      res?.status && getNotifications();
    });
    navigateNotification(item);
  };

  useEffect(() => {
    isUserLogedIn &&
      setTimeout(() => {
        getNotifications();
      }, 5000);
  }, []);

  return (
    <>
      <div className="flex gap-1 lg:gap-3 h-14 px-2 sm:px-6 md:px-12 py-3 justify-between lg:justify-end items-center w-full text-[var(--dashboard-gray-color)] ">
        {/* left side */}
        <div className={`flex items-center justify-start lg:hidden`}>
          <Link
            to="/dashboard"
            className={`flex title-font font-medium items-center text-gray-900 md:mb-0 flex-row`}
          >
            <img
              src={AppImages.LogoImage}
              alt="AutoBids Logo"
              className="md:h-12 h-10"
            />
            <span className={`ml-1 sm:ml-3 capitalize head hidden sm:block `}>
              {t("site_title")}
            </span>
          </Link>
        </div>
        {/* right side */}
        <div className="flex justify-center items-center gap-0.5 sm:gap-1.5 lg:gap-3">
          {/* language toggler */}
          <div className="">
            <LanguageSwitcher showOtherStyle={true} />
          </div>
          {/* notifications */}
          <div className="sm:p-0.5 ">
            <IconButton>
              <Badge
                className="cursor-pointer"
                overlap="circular"
                classes={{ badge: "notificationBadge" }}
                badgeContent={total}
                max={9}
                ref={notifyRef}
              >
                <div className="p-1 dark:text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    className="w-[24px] h-[24px]"
                    viewBox="0 0 24 24"
                  >
                    <g fill="currentColor">
                      <path
                        d="M18.75 9v.704c0 .845.24 1.671.692 2.374l1.108 1.723c1.011 1.574.239 3.713-1.52 4.21a25.794 25.794 0 0 1-14.06 0c-1.759-.497-2.531-2.636-1.52-4.21l1.108-1.723a4.393 4.393 0 0 0 .693-2.374V9c0-3.866 3.022-7 6.749-7s6.75 3.134 6.75 7Z"
                        className="opacity-50 dark:opacity-80"
                      />
                      <path d="M12.75 6a.75.75 0 0 0-1.5 0v4a.75.75 0 0 0 1.5 0V6ZM7.243 18.545a5.002 5.002 0 0 0 9.513 0c-3.145.59-6.367.59-9.513 0Z" />
                    </g>
                  </svg>
                </div>
              </Badge>
            </IconButton>
            <div
              className={`notificaton-modal-2 top-[50px] z-[9999999] ${
                showNotifications ? "block" : "hidden"
              }`}
              ref={notifyMessageRef}
            >
              {/* modal header */}
              <div className="notify-header">
                <div className="notify-title darkText">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                    />
                  </svg>
                  <p>{t("daboard_navbar.8lrxyw6m0")}</p> <span>({total})</span>
                </div>
                <div className="notify-setting ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                  </svg>
                </div>
              </div>
              {/* modal body */}
              <div className="notify-body">
                <div className="main-content">
                  {notifications?.length > 0 ? (
                    notifications?.map((item, index) => (
                      <div
                        className={`content-item cursor-pointer ${
                          item.is_unread ? "" : "active"
                        } `}
                        onClick={() => markNotificationAsRead(item)}
                        key={index}
                      >
                        <div className="item-desc">
                          <h6 className="item-title capitalize text-black dark:text-white p1">
                            {item?.notification_type?.split("_")?.join(" ")}:{" "}
                            {item.title ? item.title : ""}
                          </h6>
                          <span className="p2 text-[--gray] dark:text-[--light]">
                            {item.body}
                          </span>
                          <p className="item-ago text-[--gray] dark:text-[--light]">
                            {moment(item.created_at).fromNow()}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="info_message">
                      {t("daboard_navbar.oabv0tepe")}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* profile icon */}
          <div className="">
            <IconButton>
              <div className="p-0.5 border shadow-sm rounded-full">
                <Avatar
                  sx={{ width: 30, height: 30 }}
                  className="cursor-pointer darkShadow"
                  ref={profileRef}
                  alt={user?.username}
                  src={
                    user?.avatar
                      ? process.env.REACT_APP_HOST_API_KEY + "/" + user?.avatar
                      : user?.username
                  }
                />
              </div>
            </IconButton>
            <nav
              className={`absolute top-[60px] sm:top-[50px] right-[25px] sm:right-[50px] bg-white drop-shadow-xl px-3 py-2  border rounded min-w-[160px] z-[999] dark:bg-[--dark] dark:border-[--gray] darkShadow ${
                showProfile ? "" : "hidden"
              }`}
              id="navMenus"
              ref={profileMenuRef}
            >
              <ul className="">
                <li
                  className="p-0.5"
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  <p className="cursor-pointer text-black dark:text-white">
                    {t("daboard_navbar.18tc0ywyw")}
                  </p>
                </li>
              </ul>
            </nav>
          </div>
          {/* burger */}
          <div className="lg:hidden">
            <Button
              variant="text"
              color="inherit"
              sx={{
                minWidth: "fit-content",
              }}
              onClick={() => {
                setShowSidebar(!showSidebar);
              }}
            >
              <Menu
                sx={{
                  color: isDark ? "white" : "black",
                }}
              />
            </Button>
          </div>
          {/* dark mode toggler */}
          <div className="">
            <ToggleThemeComponent applyStyle2={true} />
          </div>
        </div>
      </div>
    </>
  );
});

export default DashboardNav;
