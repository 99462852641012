import { ApiConfig, FormapiConfig } from "../config";
import Auth from "./Auth.service";

const CreateReviewer = (data: any) => {
  return FormapiConfig.post("/api/admin/reviewers", data)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error.response.data;
    });
};

const UpdateReviewer = (id: string | number, data: any) => {
  let API = ApiConfig;
  if (data.avatar) {
    API = FormapiConfig;
  }
  return API.post(`/api/admin/reviewers/${id}`, data)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error.response.data;
    });
};

const DeleteReviewer = (id: string | number) => {
  return ApiConfig.delete(`/api/admin/reviewers/${id}`)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error.response.data;
    });
};

const GetReviewers = (
  offset?: number,
  limit?: number,
  search?: string | null
) => {
  let url = "/api/admin/reviewers";
  return ApiConfig.get(url, { params: { offset, limit, search } })
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error.response.data;
    });
};

const Reviewer = {
  CreateReviewer,
  UpdateReviewer,
  DeleteReviewer,
  GetReviewers,
};

export default Reviewer;
