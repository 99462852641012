import { Skeleton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Hooks from "../../hooks";
import _ from "lodash";
import StarIcon from "@mui/icons-material/Star";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "../../context/theme.context";

const FeaturedSkeletonComponent = () => {
  const { width } = Hooks.useScreen();
  const location = useLocation();
  const [isFeaturedPage, setIsFeaturedPage] = useState(false);
  const { isDark } = useContext(ThemeContext);


  useEffect(() => {
    setIsFeaturedPage(location?.pathname === "/featured-auctions");
  }, [location?.pathname]);

  const sx = {
    height: "100%",
    minHeight: "1.5rem",
    background: isDark ? "var(--gray)" : "var(--light)",
  };

  return (
    <div className="w-full h-full pb-2">
      {/* top bar */}
      <div
        className={`flex flex-col md:flex-row  justify-between items-start w-full h-[320px]`}
      >
        {/* left card */}
        <div className="md:w-6/12 w-full h-full ">
          <div className="h-full">
            <Skeleton animation="wave" sx={sx} variant="rectangular" />
          </div>
        </div>
        {/* right card */}
        <div
          className={`md:w-6/12 w-full overflow-hidden flex justify-between items-center flex-wrap h-[160px] md:h-full pt-0.5 md:pt-0`}
        >
          {_.times(width > 768 ? 4 : 2, (index: number) => (
            <div
              className={`h-full md:h-1/2 w-1/2  ${index == 0 || index == 1 ? "pb-0.5" : ""} ${width > 768 ? "ps-0.5" : index === 1 ? "ps-0.5" : ""}`}
              key={index}
            >
              <Skeleton animation="wave" sx={sx} variant="rectangular" />
            </div>
          ))}
        </div>
      </div>
      {/* bottom bar */}
      {/* title for large and small screens  */}
      <div className={` ${isFeaturedPage ? "" : "xl:hidden "}  pt-2`}>
        <div className="flex justify-between items-center gap-4">
          <div className="w-3/5">
            <Skeleton animation="wave" sx={sx} variant="text" />
          </div>
          <div className={isFeaturedPage ? "w-1/5" : "w-fit"}>
            {isFeaturedPage ? (
              <Skeleton animation="wave" sx={sx} variant="text" />
            ) : (
              <Skeleton animation="wave" sx={sx} variant={"circular"}>
                <StarIcon />
              </Skeleton>
            )}
          </div>
        </div>
        {/* make . year . model */}
        <Skeleton animation="wave" sx={sx} variant="text" />
      </div>
    </div>
  );
};

export default FeaturedSkeletonComponent;
