import { useState, useEffect, memo, useContext } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { Modal, Sheet, Input, ModalClose } from "@mui/joy";
import Utils from "../../utils";
import Services from "../../services";
import Swal from "sweetalert2";
import { PreLoaderComponent2, WarningAlert } from "../../components";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../context/theme.context";
import { AppImages } from "../../assets";

type bidType = {
  listing_id: number;
  price: number;
  card_id?: string;
};

interface bidProps {
  showBid: boolean;
  setShowBid: any;
  setShowCheckoutModal: any;
  auction_info: any;
  getComments: any;
  getListOfAuction: any;
  selectedCard: string;
  hideAgreementModal: any;
}

const CreateBid = memo(
  ({
    showBid,
    setShowBid,
    setShowCheckoutModal,
    auction_info,
    getComments,
    getListOfAuction,
    selectedCard,
    hideAgreementModal,
  }: bidProps) => {
    const { t } = useTranslation();
    const themes = Utils.Themes();
    const [price, setPrice] = useState<number>(0);
    const [highestBid, setHighestBid] = useState<number>(0);
    const [errors, setErrors] = useState<any>({
      biddingPrice: "",
    });
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
      auction_info?.current_bid?.price
        ? setHighestBid(auction_info?.current_bid?.price + 250)
        : setHighestBid(2500);
    }, [auction_info]);

    const handleError = (price: number) => {
      if (price !== 0) {
        setErrors((old: any) => {
          return {
            ...old,
            biddingPrice:
              price < highestBid
                ? t("create_bid.error_text_1") + " " + highestBid
                : price > 30000000
                  ? t("create_bid.error_text_2")
                  : "",
          };
        });
      }
    };

    const handleBid = (e: any) => {
      e.preventDefault();
      const data: bidType = {
        listing_id: auction_info.id,
        price: price,
        card_id: selectedCard,
      };
      setLoading(true);
      setShowBid(false);
      setShowCheckoutModal(false);
      Services.Bid.CreateBid(data)
        .then((res: any) => {
          setLoading(false);
          Swal.fire(
            {
              icon: res.status ? "success" : "error",
              title: res.message,
            }
            // t(
            //   res.status
            //     ? "create_bid.success_title.error_title"
            //     : "create_bid.sweat_alert.error_title"
            // ),
            // res.message
          );
          hideAgreementModal && hideAgreementModal();
          if (res.status) {
            getListOfAuction(auction_info?.id, false, true);
            getComments(auction_info?.id);
            const biddingSection = document.getElementById("place-bid-info");
            biddingSection?.scrollIntoView({
              behavior: "smooth", // "auto" or "smooth"
              block: "center", // "start", "center", "end", or "nearest"
              inline: "nearest",
            });
          }
        })
        .catch((error: any) => {
          setLoading(false);
          error?.response?.data?.message &&
            Swal.fire({
              icon: "error",
              title: error?.response?.data?.message,
            });
          // Swal.fire(
          //   t("create_bid.sweat_alert.error_title"),
          //   error?.response?.data?.message
          // );
          hideAgreementModal && hideAgreementModal();
        });
    };

    return (
      <>
        {loading && <PreLoaderComponent2 />}
        <div className="">
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={showBid}
            hideBackdrop={true}
            onClose={() => {
              // close bid modal
              setShowBid(false);
              // close credit card modal
              setShowCheckoutModal(false);
            }}
            sx={themes.JoyModalStyle_2}
          >
            <Sheet
              className="w-11/12 sm:8/12 md:w-6/12 lg:w-5/12 xl:w-4/12 overflow-y-auto overflow-x-hidden"
              sx={themes.JoyModalSheetStyle_2}
            >
              <ModalClose
                variant="outlined"
                size="sm"
                sx={themes.JoyModalCloseStyle_2}
              />
              <div className="flex flex-col justify-center items-center w-100 gap-2 pb-3">
                {/* <svg
                  viewBox="0 0 61 29"
                  fill="none"
                  strokeWidth="0.1"
                  stroke="currentColor"
                  className="w-16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.3309 2.5H40.974C41.1857 2.5 41.3748 2.63219 41.4476 2.83098L44.4382 11H47.1005L43.7952 1.97153C43.3617 0.787413 42.2349 0 40.974 0H27.3309C25.9223 0 24.7028 0.978688 24.3978 2.35391L22.4805 11H25.0412L26.8385 2.89517C26.8897 2.6643 27.0945 2.5 27.3309 2.5Z"
                    fill={isDark ? "white" : "#292929"}
                  ></path>
                  <path
                    d="M25.7239 0H27.3921L25.0248 3.04476L24.248 3.08484C24.3754 2.18474 24.3581 1.92343 24.4847 1.2016C24.5541 0.805277 24.7031 0 25.7239 0Z"
                    fill={isDark ? "white" : "#292929"}
                  ></path>
                  <path
                    d="M57.1106 12.5H21.8894C21.6743 12.5 21.5 12.6743 21.5 12.8894V17.5L20 19.5L19 19.6667V12.8894C19 11.2936 20.2936 10 21.8894 10H57.1106C58.7064 10 60 11.2936 60 12.8894V19.6429L59 19.5L57.5 17.5V12.8894C57.5 12.6743 57.3257 12.5 57.1106 12.5Z"
                    fill={isDark ? "white" : "#292929"}
                  ></path>
                  <path
                    d="M48.5 23.5H30.5L29.5 25L29.4167 26H49V25L48.5 23.5Z"
                    fill={isDark ? "white" : "#292929"}
                  ></path>
                  <rect
                    x="46"
                    y="14"
                    width="4"
                    height="1.5"
                    rx="0.75"
                    fill={isDark ? "white" : "#292929"}
                  ></rect>
                  <path
                    d="M27.5647 10.0158C25.1505 10.0158 25.5529 8.60752 25.8211 7.4004L24.7481 7.19922L23.3398 10.6194H27.2148L27.5647 10.0158Z"
                    fill={isDark ? "white" : "#292929"}
                  ></path>
                  <circle
                    cx="54"
                    cy="22"
                    r="5.75"
                    stroke={isDark ? "white" : "#292929"}
                    strokeWidth="2.5"
                  ></circle>
                  <circle
                    cx="25"
                    cy="22"
                    r="5.75"
                    stroke={isDark ? "white" : "#292929"}
                    strokeWidth="2.5"
                  ></circle>
                  <rect
                    x="7"
                    y="12"
                    width="7"
                    height="2"
                    rx="1"
                    fill={isDark ? "white" : "#292929"}
                  ></rect>
                  <rect
                    y="16"
                    width="12"
                    height="2"
                    rx="1"
                    fill={isDark ? "white" : "#292929"}
                  ></rect>
                  <rect
                    x="5"
                    y="20"
                    width="10"
                    height="2"
                    rx="1"
                    fill={isDark ? "white" : "#292929"}
                  ></rect>
                </svg> */}
                <img
                  src={AppImages.LogoImage}
                  className="h-8 xs-h-12 sm:h-10 2xl:h-12 xs-h-6"
                  alt="AutoBids Logo"
                />
                <h3 className="head font-bold text-gray-900">
                  {t("create_bid.title")}
                </h3>
              </div>
              <form className="py-3 p1" onSubmit={(e: any) => handleBid(e)}>
                <div className="flex flex-col sm:flex-row gap-y-1 sm:gap-y-0 gap-x-3 ">
                  <div className="InputFieldSetting">
                    <Input
                      placeholder="Bidding Price"
                      type="number"
                      variant="outlined"
                      required={true}
                      style={{ width: "100%" }}
                      id="biddingPrice"
                      onChange={(e: any) => {
                        e.target.value < 30000000 && setPrice(e.target.value);
                      }}
                      onBlur={(e: any) => handleError(e.target.value)}
                      sx={
                        errors.biddingPrice
                          ? themes.JoyInputError_2
                          : themes.JoyGrayShadeInputStyle2_2
                      }
                    />
                  </div>
                  {window.matchMedia("(max-width: 640px)").matches &&
                    (errors.biddingPrice ? (
                      <WarningAlert message={errors.biddingPrice} />
                    ) : (
                      <p className="opacity-40 py-0 dark:opacity-100 darK:text-white">
                        {t("create_bid.min_bid_label")}{" "}
                        {highestBid ? highestBid : 2000}
                        {" CHF"}
                      </p>
                    ))}
                  <ThemeProvider theme={themes.ButtonTheme_2}>
                    <Button variant="contained" type="submit">
                      {t("create_bid.submit_butn_text")}
                    </Button>
                  </ThemeProvider>
                </div>
                {window.matchMedia("(min-width: 640px)").matches &&
                  (errors?.biddingPrice ? (
                    <WarningAlert message={errors?.biddingPrice} />
                  ) : (
                    <p className="opacity-40 py-0 dark:text-white  dark:opacity-100">
                      {t("create_bid.min_bid_label")} {highestBid}
                      {" CHF"}
                    </p>
                  ))}
              </form>
            </Sheet>
          </Modal>
        </div>
      </>
    );
  }
);

export default CreateBid;
