import { memo, useContext } from "react";
import { Avatar } from "@mui/material";
import { ThemeContext } from "../../context/theme.context";

const AuthorQuote = memo(({ data }: any) => {
  const { isDark } = useContext(ThemeContext);

  return (
    <div className="py-4">
      <div className="bg-gray-100 px-5 py-7 flex justify-start items-center sm:items-start gap-3 rounded-[--butn-border-radius] flex-wrap sm:flex-nowrap dark:bg-[--gray]">
        <Avatar
          src={
            data?.reviewer?.avatar &&
            process.env.REACT_APP_HOST_API_KEY + data?.reviewer?.avatar
          }
          alt={data?.reviewer?.name}
          sx={{
            boxShadow: isDark
              ? "0 5px 5px rgb(255 255 255 / 0.15)"
              : "0 5px 5px rgb(0 0 0 / 0.15)",
            border: "1px solid rgb(0 0 0 / 0.15",
          }}
        />
        <div className="ps-0 sm:ps-2">
          <h3 className="font-bold p0 tracking-wide	mt-auto capitalize dark:text-white">
            {data?.reviewer?.name}
          </h3>
          <p className="p1 italic text-gray-800 hidden sm:block">
            {data?.review}
          </p>
        </div>
        <p className="p1 italic text-gray-800 sm:hidden w-full">
          {data?.review}
        </p>
      </div>
    </div>
  );
});

export default AuthorQuote;
