import { ApiConfig } from "../config";
import Auth from "./Auth.service";

const SubmitQuestion = (id: number, data: any) => {
  return ApiConfig.post(`/api/user/listings/${id}/questions`, data)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error.response.data;
    });
};

const SubmitAnswer = (id: string | number, question_id: number, data: any) => {
  return ApiConfig.post(
    `/api/user/listings/${id}/questions/${question_id}/answers`,
    data
  )
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error.response.data;
    });
};

const UpvoteQuestion = (id: number, question_id: number, data: any) => {
  return ApiConfig.post(
    `/api/user/listings/${id}/questions/${question_id}/upvote`,
    data
  )
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error.response.data;
    });
};

const FlagQuestion = (id: number, question_id: number, data: any) => {
  return ApiConfig.post(
    `/api/user/listings/${id}/questions/${question_id}/flag`,
    data
  )
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error.response.data;
    });
};

const GetQuestions = (
  id: number,
  offset: number,
  limit: number,
  filter: string | null
) => {
  let url = `/api/user/listings/${id}/questions?offset=${offset}&limit=${limit}`;
  if (filter != null) {
    url = url + `&filter=${filter}`;
  }

  return ApiConfig.get(url)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error.response.data;
    });
};

const QA = {
  SubmitAnswer,
  SubmitQuestion,
  GetQuestions,
  UpvoteQuestion,
  FlagQuestion,
};

export default QA;
