import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import {
  NavComponent,
  FooterComponent,
  CookieBannerComponent,
  FloatingScroller,
} from "../components";

function Main2() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="dark:bg-[--dark]">
        <div className="min-h-screen ">
          <div className="bg-[--dark]">
            <NavComponent />
          </div>
          <div className="my-6">
            <Outlet />
          </div>
          <div className="bg-[--dark]">
            <FooterComponent />
          </div>
          <CookieBannerComponent />
          <FloatingScroller />
        </div>
      </div>
    </>
  );
}

export default Main2;
