import API from "../config/api.config";
import formapiConfig from "../config/formapi.config";
import Auth from "./Auth.service";

const CreateAdmin = (data: any) => {
  let url: any;
  if (data.avatar) {
    url = formapiConfig;
  } else {
    url = API;
  }
  return url
    .post(`/api/admin/admins`, data)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error.response.data;
    });
};

const DeleteAdmin = (id: number | string) => {
  return API.delete(`/api/admin/admins/${id}`)
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
         window.location.reload();
      }
      return error.response.data;
    });
};

const GetAdmins = (offset: number, limit: number, search?: string | null) => {
  return API.get(`/api/admin/admins`, {
    params: { offset, limit, search },
  })
    .then((res: any) => {
      return res.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status == 401) {
        await Auth.Logout();
         window.location.reload();
      }
      return error.response.data;
    });
};

const Admin = {
  DeleteAdmin,
  CreateAdmin,
  GetAdmins,
};

export default Admin;
