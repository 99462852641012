import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Services from "../../services";
import { Grid, Pagination } from "@mui/material";
import PageComponents from ".";
import { useTranslation } from "react-i18next";
import { CardSkeletonElements } from "./CardsSkeleton.component";
import Utils from "../../utils";

const UserComments = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const themes = Utils.Themes();
  const [comments, setComments] = useState<any[]>([]);
  const [loadingdata, setLoadingdata] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);

  const getComments = () => {
    setLoadingdata(true);
    location?.state?.data?.id &&
      Services.User.getUserCommentsForAdmin(
        location?.state?.data?.id,
        offset,
        limit
      )
        .then((res) => {
          setLoadingdata(false);
          if (res?.status) {
            res?.data && setComments(res?.data);
            res?.total && setTotal(Math.ceil(res?.total / limit));
          }
        })
        .catch((error) => {
          setLoadingdata(false);
        });
  };

  useEffect(() => {
    getComments();
  }, [offset, limit, page]);

  return (
    <>
      {loadingdata && (
        <Grid container spacing={2}>
          <CardSkeletonElements />
        </Grid>
      )}
      <div className="flex flex-col px-3 sm:px-1 py-5 mx-auto">
        <div className="flex flex-wrap items-stretch">
          {!loadingdata &&
            (comments?.length > 0 ? (
              comments?.map((item: any, index: number) => (
                <div className="p-2 w-full md:w-1/2 xl:w-1/3" key={index}>
                  <PageComponents.CardCommment
                    item={item}
                    getListings={getComments}
                    showDetailsInProp={true}
                  />
                </div>
              ))
            ) : (
              <p className="info_message">{t("user_details_page.0ar0qj4fw")}</p>
            ))}
        </div>
        <div className="py-3 flex justify-end items-center">
          {!loadingdata && total > 1 && (
            <div className="">
              <Pagination
                count={total}
                hidePrevButton
                hideNextButton
                page={page}
                onChange={(e: any, pageNum: number) => {
                  setPage(pageNum);
                  setOffset((pageNum - 1) * limit);
                }}
                shape="rounded"
                sx={themes.MuiPagenationStyle_2}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserComments;
