import { lazy, memo, useState, Suspense, useContext, useEffect } from "react";
import { Modal, ModalClose, Sheet } from "@mui/joy";
import { Button, ThemeProvider } from "@mui/material";
import Utils from "../../utils";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../context/theme.context";
import { AppImages } from "../../assets";

interface VerifiedBidderType {
  show: boolean;
  setShow: any;
  getProfile?: any;
  auction_info?: any;
}

const AddCreditCard = lazy(
  () => import("../components/AddCreditCard.component")
);

const VerifiedBidderComponent = memo(
  ({ show, setShow, getProfile, auction_info }: VerifiedBidderType) => {
    const { t } = useTranslation();
    const themes = Utils.Themes();
    const { isDark } = useContext(ThemeContext);

    const [showCredit, setShowCredit] = useState<boolean>(false);
    const [hideBidModal, setHideBidModal] = useState<boolean>(true);


    return (
      <div className="">
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={show}
          hideBackdrop={true}
          onClose={() => {
            setShow(false);
          }}
          sx={themes.JoyModalStyle_2}
        >
          <Sheet
            className="w-11/12 sm:8/12 md:w-6/12 lg:w-5/12 xl:w-4/12 overflow-y-auto overflow-x-hidden"
            sx={themes.JoyModalSheetStyle_2}
          >
            <ModalClose
              variant="outlined"
              size="sm"
              sx={themes.JoyModalCloseStyle_2}
            />
            <div className="flex flex-col justify-center items-center w-100 gap-2 ">
              <img
                src={AppImages.LogoImage}
                className="h-8 xs-h-12 sm:h-10 2xl:h-12 xs-h-6"
                alt="AutoBids Logo"
              />
              <h3 className="head pb-4 font-bold text-gray-900">
                {t("verified_bidder.title")}
              </h3>
            </div>
            <p className="p1 text-start w-full ">
              {t("verified_bidder.description")}
            </p>
            <div className=" w-full py-2 ">
              <div className="flex justify-center items-center gap-4 py-6">
                <ThemeProvider theme={themes.TabsStyle_2}>
                  <Button variant="outlined" onClick={() => setShow(!show)}>
                    {t("verified_bidder.cancel_butn_text")}
                  </Button>
                </ThemeProvider>
                <ThemeProvider theme={themes.ButtonTheme_2}>
                  <Button
                    variant="contained"
                    onClick={() => setShowCredit(true)}
                  >
                    {t("verified_bidder.view_agreement_butn_text")}
                  </Button>
                </ThemeProvider>
              </div>
            </div>
          </Sheet>
        </Modal>
        <Suspense>
          <AddCreditCard
            showCreditCard={showCredit}
            setShowCreditCard={setShowCredit}
            auction_info={auction_info}
            hideBidModal={hideBidModal}
            hideAgreementModal={() => {
              setShow(false);
              getProfile && getProfile();
            }}
          />
        </Suspense>
      </div>
    );
  }
);

export default VerifiedBidderComponent;
