import { createTheme, ButtonGroup, Button } from "@mui/material";
import { mainFontFamily } from "../layouts/Main.layout";
import { useContext, useState } from "react";
import { ThemeContext } from "../context/theme.context";
import { BorderColor } from "@mui/icons-material";

let fontFamily = mainFontFamily.join(", ") + " !important";

// const TableTheme = createTheme({
//   components: {
//     MuiTable: {
//       styleOverrides: {
//         root: {
//           ".MuiTableCell-root": {
//             fontSize: "1rem",
//             fontFamily,
//           },
//         },
//       },
//     },
//   },
// });

// const AccordionTheme = createTheme({
//   components: {
//     MuiTable: {
//       styleOverrides: {
//         root: {
//           ".MuiTableCell-root": {
//             fontSize: "1rem",
//             fontFamily,
//           },
//         },
//       },
//     },
//   },
// });

// const ButtonTheme = createTheme({
//   palette: {
//     primary: {
//       main: "#faca15",
//       light: "#fff",
//       dark: "rgb(254 240 138)",
//       contrastText: "var(--dark-text-color)",
//     },
//   },
//   typography: {
//     button: {
//       textTransform: "capitalize",
//       fontSize: "1rem",
//       fontWeight: 600,
//       letterSpacing: "1px",
//       fontFamily,
//     },
//   },
// });

// const MUItextFieldTheme = createTheme({
//   components: {
//     MuiTextField: {
//       styleOverrides: {
//         root: {
//           fontSize: "1rem",
//           fontFamily,
//           "& .MuiOutlinedInput-root": {
//             "&.Mui-focused, &:hover": {
//               "& .MuiOutlinedInput-notchedOutline": {
//                 borderColor: "var(--main-color)", // Change the outline color
//                 borderWidth: "2px", // Increase the border width
//                 outline: "none",
//               },
//             },
//           },

//           // Customize the root style of the TextField
//           "& .MuiOutlinedInput-notchedOutline": {
//             borderColor: "var(--main-color)", // Change the border color
//             borderWidth: "2px",
//           },
//         },
//       },
//     },
//   },
// });

// const MUItextFieldErrorTheme = createTheme({
//   components: {
//     MuiTextField: {
//       styleOverrides: {
//         root: {
//           fontSize: "1rem",
//           fontFamily,
//           "& .MuiOutlinedInput-root": {
//             "&.Mui-focused, &:hover": {
//               "& .MuiOutlinedInput-notchedOutline": {
//                 borderColor: "var(--error-color)", // Change the outline color
//                 borderWidth: "2px", // Increase the border width
//                 outline: "none",
//               },
//             },
//           },

//           // Customize the root style of the TextField
//           "& .MuiOutlinedInput-notchedOutline": {
//             borderColor: "var(--error-color)", // Change the border color
//             borderWidth: "2px",
//           },
//         },
//       },
//     },
//   },
// });

// const JoyInputStyle = {
//   fontSize: "1rem",
//   fontFamily,
//   borderWidth: "2px",
//   borderColor: "var(--main-color)",
//   "--Input-focusedInset": "var(--main-color)",
//   "--Input-focusedThickness": "2px",
//   "--Input-focusedHighlight": "var(--main-color)",
//   "&::before": {
//     transition: "box-shadow .15s ease-in-out",
//   },
//   "&:focus-within": {
//     borderColor: "var(--main-color)",
//   },
//   "--Textarea-focusedInset": "var(--main-color)",
//   "--Textarea-focusedThickness": "2px",
//   "--Textarea-focusedHighlight": "var(--main-color)",
// };

// const JoyGrayShadeInputStyle = {
//   fontSize: "1rem",
//   fontFamily,
//   maxHeight: "100px",
//   borderWidth: "2px",
//   borderColor: "var(--mid-gray-color)",
//   "--Input-focusedInset": "var(--mid-gray-color)",
//   "--Input-focusedThickness": "2px",
//   "--Input-focusedHighlight": "var(--mid-gray-color)",
//   "&::before": {
//     transition: "box-shadow .15s ease-in-out",
//   },
//   "&:focus-within": {
//     borderColor: "var(--mid-gray-color)",
//   },
//   "--Textarea-focusedInset": "var(--mid-gray-color)",
//   "--Textarea-focusedThickness": "2px",
//   "--Textarea-focusedHighlight": "var(--mid-gray-color)",
//   ".MuiTextarea-textarea": {
//     overflow: "auto !important",
//   },
//   ".MuiTextarea-textarea::-webkit-scrollbar": {
//     width: "5px",
//   },
// };

// const JoyGrayShadeInputStyle2 = {
//   fontSize: "1rem",
//   fontFamily,
//   background: "var(--input-bg-faded)",
//   maxHeight: "100px",
//   borderWidth: "2px",
//   borderColor: "var(--mid-gray-color)",
//   "--Input-focusedInset": "var(--main-color)",
//   "--Input-focusedThickness": "2px",
//   "--Input-focusedHighlight": "var(--main-color)",
//   "&::before": {
//     transition: "box-shadow .15s ease-in-out",
//   },
//   "&:focus-within": {
//     borderColor: "var(--main-color)",
//   },
//   "--Textarea-focusedInset": "var(--main-color)",
//   "--Textarea-focusedThickness": "2px",
//   "--Textarea-focusedHighlight": "var(--main-color)",
//   ".MuiTextarea-textarea": {
//     overflow: "auto !important",
//   },
//   ".MuiTextarea-textarea::-webkit-scrollbar": {
//     width: "5px",
//   },
//   "&focus": {
//     boxShadow: "none",
//   },
// };

// const JoyInputError = {
//   fontSize: "1rem",
//   fontFamily,
//   maxHeight: "100px",
//   borderWidth: "2px",
//   borderColor: "var(--error-color)",
//   "--Input-focusedInset": "var(--error-color)",
//   "--Input-focusedThickness": "2px",
//   "--Input-focusedHighlight": "var(--error-color)",
//   "&::before": {
//     transition: "box-shadow .15s ease-in-out",
//   },
//   "&:focus-within": {
//     borderColor: "var(--error-color)",
//   },
//   "--Textarea-focusedInset": "var(--error-color)",
//   "--Textarea-focusedThickness": "2px",
//   "--Textarea-focusedHighlight": "var(--error-color)",
//   ".MuiTextarea-textarea": {
//     overflow: "auto !important",
//   },
//   ".MuiTextarea-textarea::-webkit-scrollbar": {
//     width: "5px",
//   },
// };

// const GoogleButtonTheme = createTheme({
//   palette: {
//     primary: {
//       main: "#ffff",
//       light: "#ffff",
//       dark: "#ffff",
//       contrastText: "var(--dark-text-color)",
//     },
//   },
//   typography: {
//     button: {
//       fontSize: "1rem",
//       fontWeight: 500,
//       textTransform: "capitalize",
//       fontFamily,
//     },
//   },
// });

// const FacebookButtonTheme = createTheme({
//   palette: {
//     primary: {
//       main: "#1877F2",
//       light: "#1877F2",
//       dark: "#1877F2",
//       contrastText: "var(--light-text-color)",
//     },
//   },
//   typography: {
//     button: {
//       fontSize: "1rem",
//       fontWeight: 500,
//       textTransform: "none",
//       fontFamily,
//     },
//   },
// });

// const AppleButtonTheme = createTheme({
//   palette: {
//     primary: {
//       main: "#000000",
//       light: "#000000",
//       dark: "#000000",
//       contrastText: "var(--light-text-color)",
//     },
//   },
//   typography: {
//     button: {
//       fontSize: "1rem",
//       fontWeight: 500,
//       textTransform: "none",
//       fontFamily,
//     },
//   },
// });

// const LightButtonTheme = createTheme({
//   palette: {
//     primary: {
//       main: "#ff9e0c33",
//       light: "#ff9e0c33",
//       dark: "#ff9d0a4d",
//       contrastText: "var(--main-color)",
//     },
//   },
//   typography: {
//     button: {
//       fontSize: "1rem",
//       fontWeight: 500,
//       textTransform: "none",
//       fontFamily,
//     },
//   },
// });

// const TabsStyle = createTheme({
//   palette: {
//     primary: {
//       main: "#ff9900f3",
//       light: "#ff9900c1",
//       dark: "#ff9900",
//       contrastText: "var(--light-text-color)",
//     },
//   },
//   typography: {
//     button: {
//       textTransform: "capitalize",
//       fontWeight: "bolder",
//       letterSpacing: "1px",
//       fontSize: "1rem",
//       fontFamily,
//     },
//   },
// });

// const TransParentButton = createTheme({
//   palette: {
//     primary: {
//       main: "#ffffff",
//       light: "#f6f6f7",
//       dark: "#f6f6f7",
//       contrastText: "var(--dark-text-color)",
//     },
//   },
//   typography: {
//     button: {
//       fontSize: "1rem",
//       fontWeight: 500,
//       textTransform: "capitalize",
//       fontFamily,
//     },
//   },
//   components: {
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           "&:disabled": {
//             opacity: 0.5, // Set the desired opacity here
//           },
//         },
//       },
//     },
//   },
// });

// const MenuButtonTheme = createTheme({
//   palette: {
//     primary: {
//       main: "rgba(99, 115, 129, 0.75)",
//       light: "rgba(99, 115, 129, 0.75)",
//       dark: "rgba(99, 115, 129, 0.75)",
//       contrastText: "rgba(99, 115, 129)",
//     },
//   },
//   typography: {
//     button: {
//       fontSize: "1rem",
//       fontWeight: 500,
//       textTransform: "capitalize",
//       fontFamily,
//     },
//   },
// });

// const MenuActiveButtonTheme = createTheme({
//   palette: {
//     primary: {
//       main: "rgba(255, 157, 10, 0.1)",
//       light: "rgba(255, 157, 10, 0.1)",
//       dark: "rgba(255, 157, 10, 0.15)",
//       contrastText: "var(--main-color)",
//     },
//   },
//   typography: {
//     button: {
//       fontSize: "1rem",
//       fontWeight: 500,
//       textTransform: "capitalize",
//       fontFamily,
//     },
//   },
// });

// const ButtonActiveTheme = createTheme({
//   palette: {
//     primary: {
//       main: "#8b5cf6",
//       light: "#8b5cf6c1",
//       dark: "#8b5cf6c1",
//       contrastText: "var(--light-text-color)",
//     },
//   },
//   typography: {
//     button: {
//       textTransform: "capitalize",
//       fontSize: "1rem",
//       fontWeight: 500,
//       letterSpacing: "1px",
//       fontFamily,
//     },
//   },
// });

// const JoyModalStyle = {
//   display: "flex",
//   fontFamily,
//   justifyContent: "center",
//   alignItems: "center",
//   backgroundColor: "var(--joy-palette-background-backdrop)",
//   opacity: 1,
//   backdropFilter: "blur(8px)",
//   transition: "opacity 400ms ease 0s, backdrop-filter 400ms ease 0s",
//   width: "100%",
// };

// const JoyModalSheetStyle = (mode: any) => ({
//   borderRadius: "md",
//   fontFamily,
//   px: 3,
//   py: 4,
//   background: mode === "dark" ? "var(--dark)" : "white",
//   boxShadow:
//     "inset 0px 12px 16px -3px rgba(0,0,0,0.1),  0px 6px 10px -3px rgba(0,0,0,0.1)",
//   border: "none",
//   outline: "none",
//   maxHeight: "90vh",
//   overflowY: "auto",
//   overflowX: "hidden",
//   maxWidth: "90vw",
//   width: {
//     xs: "90vw",
//     md: "80vw",
//     lg: "60vw",
//     xl: "40vw",
//   },
// });

// const JoyModalCloseStyle = {
//   boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2) !important",
//   borderRadius: "50%",
//   bgcolor: "background.body",
//   background: "white",
//   borderColor: "#f1f1f1 !important",
//   opacity: 1,
//   fontFamily,
// };

// const MuiPagenationStyle = {
//   fontFamily,
//   ".MuiPaginationItem-root.Mui-selected": {
//     backgroundColor: "var(--dark-color)",
//     color: "white",
//   },
// };

// const BreakPoints = createTheme({
//   breakpoints: {
//     values: {
//       xs: 0,
//       sm: 640,
//       md: 768,
//       lg: 1024,
//       xl: 1280,
//       xxl: 1536,
//     },
//   },
// });

// declare module "@mui/material/styles" {
//   interface BreakpointOverrides {
//     // xs: false; // removes the `xs` breakpoint
//     // sm: false;
//     // md: false;
//     // lg: false;
//     // xl: false;
//     xxl: true;

//     // mobile: true; // adds the `mobile` breakpoint
//     // tablet: true;
//     // laptop: true;
//     // desktop: true;
//   }
// }

// Step 1: Define breakpoints for Material-UI
// const muiTheme = createMuiTheme({
//   breakpoints: {
//     values: {
//       xs: 0,
//       sm: 640,
//       md: 768,
//       lg: 1024,
//       xl: 1280,
//       xxl: 1536,  // Custom breakpoint
//     },
//   },
// });

// // Step 2: Extend Joy UI theme to include the same breakpoints
// const joyTheme = createJoyTheme({
//   breakpoints: {
//     xs: 0,
//     sm: 640,
//     md: 768,
//     lg: 1024,
//     xl: 1280,
//     xxl: 1536,  // Ensure that Joy UI recognizes the custom breakpoints
//   },
// });

// // Step 3: Merge both themes (Material-UI and Joy UI)
// const MergedTheme = {
//   ...muiTheme,
//   ...joyTheme,
//   components: {
//     ...muiTheme.components,
//     ...joyTheme,
//   },
// };

// declare module '@mui/system' {
//   interface Breakpoints {
//     values: {
//       xs: number;
//       sm: number;
//       md: number;
//       lg: number;
//       xl: number;
//       xxl: number;  // Your custom breakpoint
//     };
//   }
// }

// declare module '@mui/material/styles' {
//   interface BreakpointOverrides {
//     xxl: true;  // Add 'xxl' breakpoint
//   }
// }

// declare module '@mui/joy/styles' {
//   interface BreakpointOverrides {
//     xxl: true;  // Add 'xxl' breakpoint
//   }
// }

const Theme = {
  // MuiPagenationStyle,
  // JoyGrayShadeInputStyle2,
  // MUItextFieldErrorTheme,
  // MUItextFieldTheme,
  // JoyInputError,
  // JoyInputStyle,
  // MenuButtonTheme,
  // MenuActiveButtonTheme,
  // LightButtonTheme,
  // TabsStyle,
  // ButtonTheme,
  // ButtonActiveTheme,
  // TransParentButton,
  // GoogleButtonTheme,
  // FacebookButtonTheme,
  // AppleButtonTheme,
  // JoyGrayShadeInputStyle,
  // TableTheme,
  // AccordionTheme,
  // JoyModalSheetStyle,
  // JoyModalCloseStyle,
  // JoyModalStyle,
};

const Themes = () => {
  const { isDark } = useContext(ThemeContext);

  const MuiTableBox = {
    boxShadow:
      "inset 0px 8px 12px -3px rgba(0,0,0,0.1),  0px 6px 10px -3px rgba(0,0,0,0.1)",
    borderRadius: "1rem",
    margin: "0.5rem",
    fontFamily: mainFontFamily,
    background: "none",
  };

  const TableTheme_2 = createTheme({
    components: {
      MuiTable: {
        styleOverrides: {
          root: {
            // border: isDark ? "1px solid  var(--gray)" : "none",
            boxShadow: isDark ? "0 0 4px rgba(255,255,255,.1)]" : "inherit",
            ".MuiTableCell-root": {
              fontSize: "1rem",
              fontFamily,
              borderColor: isDark ? "var(--gray)" : "var(--light)",
            },
            ".MuiTableRow-root.MuiTableRow-head": {
              background: isDark ? "var(--gray)" : "var(--light)",
            },
            ".MuiTableRow-root": {
              background: isDark ? "var(--dark)" : "white",
            },
            ".MuiTableCell-root p, .MuiTableCell-root": {
              color: isDark ? "white" : "initial",
            },
            ".MuiTableBody-root": {
              overflowX: "clip",
              width: "100%",
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          root: {
            border: "none",
            boxShadow: "none",
            ".MuiPaper-root": {
              boxShadow: isDark
                ? "none"
                : "inset 0px 6px 12px -4px rgba(0,0,0,0.1),  0px 6px 10px -3px rgba(0,0,0,0.1)",
              background: "transparent",
            },
          },
        },
      },
    },
  });

  const MuiAccordionTheme = createTheme({
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            background: isDark ? "var(--dark)" : "white",
            border: isDark ? "1px solid var(--gray)" : "none",
            boxShadow: "0 0 4px rgba(255,255,255,.1)]",
            ".MuiTypography-root p": {
              color: isDark ? "white" : "black",
            },
            ".MuiAccordionSummary-expandIconWrapper": {
              color: isDark ? "white" : "var(--gray-color)",
            },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            ".MuiTableHead-root": {
              color: isDark ? "white" : "black",
            },
            ".MuiTableCell-root": {
              fontSize: "1rem",
              fontFamily,
              border: "none",
              color: isDark ? "var(--light)" : "black",
            },
            ".MuiTableRow-root": {
              borderBottom:
                "1px solid " + (isDark ? "var(--gray)" : "var(--light)"),
            },
            ".MuiTableHead-root .MuiTableCell-root": {
              color: isDark ? "white" : "black",
            },
          },
        },
      },
    },
  });

  const AccordionTheme_2 = createTheme({
    components: {
      MuiTable: {
        styleOverrides: {
          root: {
            ".MuiTableCell-root": {
              fontSize: "1rem",
              fontFamily,
            },
          },
        },
      },
    },
  });

  const ButtonTheme_2 = createTheme({
    palette: {
      primary: {
        main: "#faca15",
        light: "#fff",
        dark: "rgb(254 240 138)",
        contrastText: "var(--dark-text-color)",
      },
    },
    typography: {
      button: {
        textTransform: "capitalize",
        fontSize: "1rem",
        fontWeight: 600,
        letterSpacing: "1px",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            border: "none",
            borderRadius: "var(--butn-border-radius)",
            fontSize: "1rem",
            boxShadow: "none",
            "&:hover, &:disabled": {
              boxShadow: "none",
            },
            "&:disabled": {
              opacity: 0.5, // Set the desired opacity here
              background: "#faca15",
              color: "var(--dark-text-color)",
            },
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            ".MuiButtonGroup-firstButton,  .MuiButtonGroup-middleButton": {
              borderColor: isDark
                ? "var(--dark-text-color)"
                : "var(--light-color)",
            },
          },
        },
      },
    },
  });

  const MUItextFieldTheme_2 = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontSize: "1rem",
            fontFamily,
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused, &:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "var(--main-color)", // Change the outline color
                  borderWidth: "2px", // Increase the border width
                  outline: "none",
                },
              },
            },

            // Customize the root style of the TextField
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "var(--main-color)", // Change the border color
              borderWidth: "2px",
            },
          },
        },
      },
    },
  });

  const MUItextFieldErrorTheme_2 = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontSize: "1rem",
            fontFamily,
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused, &:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "var(--error-color)", // Change the outline color
                  borderWidth: "2px", // Increase the border width
                  outline: "none",
                },
              },
            },

            // Customize the root style of the TextField
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "var(--error-color)", // Change the border color
              borderWidth: "2px",
            },
          },
        },
      },
    },
  });

  const JoyInputStyle_2 = {
    fontSize: "1rem",
    fontFamily,
    borderWidth: "2px",
    borderColor: "var(--main-color)",
    "--Input-focusedInset": "var(--main-color)",
    "--Input-focusedThickness": "2px",
    "--Input-focusedHighlight": "var(--main-color)",
    "&::before": {
      transition: "box-shadow .15s ease-in-out",
    },
    "&:focus-within": {
      borderColor: "var(--main-color)",
    },
    "--Textarea-focusedInset": "var(--main-color)",
    "--Textarea-focusedThickness": "2px",
    "--Textarea-focusedHighlight": "var(--main-color)",
  };

  const JoyGrayShadeInputStyle_2 = {
    fontSize: "1rem",
    fontFamily,
    maxHeight: "100px",
    borderWidth: "2px",
    background: isDark ? "var(--dark)" : "white",
    borderColor: isDark ? "Var(--gray)" : "var(--mid-gray-color)",
    color: isDark ? "white" : "var(--dark)",
    "--Input-focusedInset": "var(--mid-gray-color)",
    "--Input-focusedThickness": "2px",
    "--Input-focusedHighlight": "var(--mid-gray-color)",
    "&::before": {
      transition: "box-shadow .15s ease-in-out",
    },
    "&:focus-within": {
      // borderColor: "var(--mid-gray-color)",
      borderColor: "var(--main-color)",
    },
    // "--Textarea-focusedInset": "var(--mid-gray-color)",
    "--Textarea-focusedInset": "var(--main-color)",
    "--Textarea-focusedThickness": "2px",
    // "--Textarea-focusedHighlight": "var(--mid-gray-color)",
    "--Textarea-focusedHighlight": "var(--main-color)",
    ".MuiTextarea-textarea": {
      overflow: "auto !important",
    },
    ".MuiTextarea-textarea::-webkit-scrollbar": {
      width: "5px",
    },
  };

  const CheckBoxStyle = {
    fontSize: "1rem",
    fontFamily,
    color: isDark ? "white" : "var(--dark)",
  };

  const JoyGrayShadeInputStyle2_2 = {
    fontSize: "1rem",
    fontFamily,
    background: isDark ? "var(--dark)" : "var(--input-bg-faded)",
    maxHeight: "100px",
    borderWidth: "2px",
    color: isDark ? "white" : "black",
    boxShadow: "none",
    borderColor: isDark ? "var(--gray)" : "var(--mid-gray-color)",
    "--Input-focusedInset": "var(--main-color)",
    "--Input-focusedThickness": "2px",
    "--Input-focusedHighlight": "var(--main-color)",
    "&::before": {
      transition: "box-shadow .15s ease-in-out",
    },
    "&:focus-within": {
      borderColor: "var(--main-color)",
    },
    "--Textarea-focusedInset": "var(--main-color)",
    "--Textarea-focusedThickness": "2px",
    "--Textarea-focusedHighlight": "var(--main-color)",
    ".MuiTextarea-textarea": {
      overflow: "auto !important",
    },
    ".MuiTextarea-textarea::-webkit-scrollbar": {
      width: "5px",
    },
    "&focus": {
      boxShadow: "none",
    },
    ".MuiInput-input:-webkit-autofill": {
      color: isDark ? "white" : "black",
      WebkitTextFillColor: isDark ? "white" : "black",
      background: isDark ? "var(--dark)" : "var(--input-bg-faded)",
    },
    "& input::-webkit-calendar-picker-indicator, & input::-webkit-calendar-time-picker-indicator":
      {
        filter: isDark
          ? "invert(100%) brightness(100%)"
          : "invert(0%) brightness(100%)", // This will turn the icon to white
        cursor: "pointer",
      },
    "&.Mui-disabled, & .MuiInputBase-root.Mui-disabled, & .MuiInputBase-input.Mui-disabled":
      {
        borderColor: isDark ? "var(--gray)" : "var(--mid-gray-color)",
      },
    ".MuiAutocomplete-option": {
      background: isDark ? "var(--dark)" : "white",
      color: isDark ? "white" : "black",
    },
    // "&.MuiAutocomplete-option:not(.Mui-selected, [aria-selected='true]):hover":
    //   {
    //     backgroundColor: isDark ? "red" : "white",
    //     color: isDark ? "black" : "black",
    //   },
    // "& .MuiAutocomplete-listbox": {
    //   "& .MuiAutocomplete-option": {
    //     "&:hover": {
    //       backgroundColor: "blue", // Background color on hover
    //       color: "white", // Text color on hover
    //     },
    //   },
    // },
    /* Target options of Autocomplete */
    ".MuiAutocomplete-listbox .MuiAutocomplete-option:hover": {
      backgroundColor: "blue" /* Background color on hover */,
      color: "white" /* Text color on hover */,
    },
  };

  const slotPropsTheme = {
    listbox: {
      sx: {
        background: isDark ? "var(--dark)" : "white",
        border: isDark ? "var(--gray)" : "var(--gray-color)",
        boxShadow: isDark
          ? "rgba(255, 255, 255, 0.12) 0px 2px 4px 0px, rgba(255, 255, 255, 0.32) 0px 2px 8px 0px"
          : " rgba(0, 0, 0, 0.24) 0px 3px 8px",
        "& .MuiAutocomplete-option": {
          color: isDark ? "white" : "black",
          "&:hover": {
            background: isDark ? "var(--gray)" : "var(--light)",
          },
        },
      },
    },
  };

  const JoyInputError_2 = {
    fontSize: "1rem",
    fontFamily,
    maxHeight: "100px",
    borderWidth: "2px",
    background: isDark ? "var(--dark)" : "white",
    color: isDark ? "white" : "black",
    borderColor: "var(--error-color)",
    "--Input-focusedInset": "var(--error-color)",
    "--Input-focusedThickness": "2px",
    "--Input-focusedHighlight": "var(--error-color)",
    "&::before": {
      transition: "box-shadow .15s ease-in-out",
    },
    "&:focus-within": {
      borderColor: "var(--error-color)",
    },
    "--Textarea-focusedInset": "var(--error-color)",
    "--Textarea-focusedThickness": "2px",
    "--Textarea-focusedHighlight": "var(--error-color)",
    ".MuiTextarea-textarea": {
      overflow: "auto !important",
    },
    ".MuiTextarea-textarea::-webkit-scrollbar": {
      width: "5px",
    },
  };

  const MuiBreadCrumbsTheme = createTheme({
    components: {
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            ".MuiBreadcrumbs-li, .MuiBreadcrumbs-separator": {
              color: isDark ? "var(--light)" : "var(--gray-color)",
            },
          },
        },
      },
    },
  });

  const GoogleButtonTheme_2 = createTheme({
    palette: {
      primary: {
        main: "#ffff",
        light: "#ffff",
        dark: "#ffff",
        contrastText: "var(--dark-text-color)",
      },
    },
    typography: {
      button: {
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "capitalize",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            boxShadow: "none",
            // border: "1px solid " + (isDark ? "var(--light)" : "black"),
            border:
              "1px solid " +
              (isDark ? "var(--light)" : "var(--mid-gray-color)"),
            "&:hover, &:disabled": {
              boxShadow: "none",
            },
            "&:disabled": {
              opacity: 0.5, // Set the desired opacity here
              background: "white",
              color: "var(--dark-text-color)",
            },
          },
        },
      },
    },
  });

  const FacebookButtonTheme_2 = createTheme({
    palette: {
      primary: {
        main: "#1877F2",
        light: "#1877F2",
        dark: "#1877F2",
        contrastText: "var(--light-text-color)",
      },
    },
    typography: {
      button: {
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "none",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            boxShadow: "none",
            border: "1px solid #1877F2",
            "&:hover, &:disabled": {
              boxShadow: "none",
            },
            "&:disabled": {
              opacity: 0.5, // Set the desired opacity here
              background: "#1877F2",
              color: "var(--light-text-color)",
            },
          },
        },
      },
    },
  });

  const AppleButtonTheme_2 = createTheme({
    palette: {
      primary: {
        main: isDark ? "#fff" : "#000000",
        light: isDark ? "#fff" : "#000000",
        dark: isDark ? "#fff" : "#000000",
        contrastText: isDark ? "#000000" : "var(--light-text-color)",
      },
    },
    typography: {
      button: {
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "none",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            boxShadow: "none",
            fontSize: "1rem",
            border:
              "1px solid " +
              (isDark ? "var(--light)" : "var(--mid-gray-color)"),
            "&:hover, &:disabled": {
              boxShadow: "none",
            },
            "&:disabled": {
              opacity: 0.5, // Set the desired opacity here
              background: "white",
              color: "var(--dark-text-color)",
            },
          },
          text: {
            border: "none", // Ensure no border for text variant
            boxShadow: "none", // Remove shadow for text buttons
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: isDark ? "white" : "black", // Text color of the icon button
            "&:hover": {
              color: isDark ? "white" : "black", // Hover state color
            },
            "&:disabled": {
              color: isDark ? "white" : "black", // Disabled state color
            },
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            borderRadius: "var(--butn-border-radius)",
            border: "none",
            // border:
            //   "1px solid " +
            //   (isDark ? "var(--light)" : "var(--mid-gray-color)"),
          },
        },
      },
    },
  });

  const LightButtonTheme_2 = createTheme({
    palette: {
      primary: {
        main: "#ff9e0c33",
        light: "#ff9e0c33",
        dark: "#ff9d0a4d",
        contrastText: "var(--main-color)",
      },
    },
    typography: {
      button: {
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "none",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            boxShadow: "none",
            border: "1px solid #ff9e0c33",
            "&:hover": {
              boxShadow: "none",
            },
            ".MuiButton-outlined": {},
            "&:disabled": {
              opacity: 0.5, // Set the desired opacity here
              border: "1px solid #ff9d0a4d",
              color: "var(--main-color)",
              ".MuiButton-contained": {
                background: "#ff9e0c33",
              },
            },
          },
        },
      },
    },
  });

  const TabsStyle_2 = createTheme({
    palette: {
      primary: {
        main: "#ff9900f3",
        light: "#ff9900c1",
        dark: "#ff9900",
        contrastText: "var(--light-text-color)",
      },
    },
    typography: {
      button: {
        textTransform: "capitalize",
        fontWeight: "bolder",
        letterSpacing: "1px",
        fontSize: "1rem",
        fontFamily,
      },
    },
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            color: isDark ? "white" : "dark",
            // ".MuiTabs-scroller": {
            //   minWidth: "fit-content",
            // },
            // ".MuiTabs-root": {
            //   minWidth: "fit-content",
            // },
          },
        },
      },
    },
  });

  const TransParentButton_2 = createTheme({
    palette: {
      primary: {
        main: "#ffffff",
        light: "#f6f6f7",
        dark: "#f6f6f7",
        contrastText: "var(--dark-text-color)",
      },
    },
    typography: {
      button: {
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "capitalize",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            boxShadow: "none",
            // border:
            //   "1px solid " +
            //   (isDark ? "var(--light)" : "var(--dark-text-color)"),
            border:
              "1px solid " +
              (isDark ? "var(--light)" : "var(--mid-gray-color)"),
            "&.MuiButton-outlined": {
              color: isDark ? "var(--light)" : "var(--dark-text-color)", // Set the desired text color here
            },
            "&:hover, &:disabled": {
              boxShadow: "none",
            },
            "&:hover": {
              borderColor: isDark ? "var(--light)" : "var(--dark-text-color)",
            },
            "&:disabled": {
              opacity: 0.5, // Set the desired opacity here
              background: "white",
              color: isDark ? "var(--light)" : "var(--dark-text-color)", // Set the desired text color here
              border:
                "1px solid " +
                (isDark ? "var(--light)" : "var(--dark-text-color)"),
              "&.MuiButton-outlined": {
                background: "transparent",
              },
            },
          },
        },
      },
    },
  });

  const MenuButtonTheme_2 = createTheme({
    palette: {
      primary: {
        main: "rgba(99, 115, 129, 0.75)",
        light: "rgba(99, 115, 129, 0.75)",
        dark: "rgba(99, 115, 129, 0.75)",
        contrastText: "rgba(99, 115, 129)",
      },
    },
    typography: {
      button: {
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "capitalize",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            boxShadow: "none",
            "&:hover, &:disabled": {
              boxShadow: "none",
            },
          },
        },
      },
    },
  });

  const MenuActiveButtonTheme_2 = createTheme({
    palette: {
      primary: {
        main: isDark ? "var(--main-color)" : "rgba(255, 157, 10, 0.1)",
        light: isDark ? "var(--main-color)" : "rgba(255, 157, 10, 0.1)",
        dark: isDark ? "var(--dark-color)" : "rgba(255, 157, 10, 0.15)",
        contrastText: isDark ? "white" : "var(--main-color)",
      },
    },
    typography: {
      button: {
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "capitalize",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            boxShadow: "none",
            "&:hover, &:disabled": {
              boxShadow: "none",
            },
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            background: "none",
            boxShadow: "none",
          },
        },
      },
    },
  });

  const ButtonActiveTheme_2 = createTheme({
    palette: {
      primary: {
        main: "#8b5cf6",
        light: "#8b5cf6c1",
        dark: "#8b5cf6c1",
        contrastText: "var(--light-text-color)",
      },
    },
    typography: {
      button: {
        textTransform: "capitalize",
        fontSize: "1rem",
        fontWeight: 500,
        letterSpacing: "1px",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            fontSize: "1rem",
            boxShadow: "none",
            "&:hover, &:disabled": {
              boxShadow: "none",
            },
            "&:disabled": {
              backgroundColor: "var(--main-color)",
              color: "white",
              opacity: "0.4",
            },
          },
        },
      },
    },
  });

  const JoyModalStyle_2 = {
    display: "flex",
    fontFamily,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "var(--joy-palette-background-backdrop)",
    opacity: 1,
    backdropFilter: "blur(8px)",
    transition: "opacity 400ms ease 0s, backdrop-filter 400ms ease 0s",
    width: "100%",
    // transition: "all 0.5s ease-in-out", // Animate the backdrop opacity
    animation: "fadeIn 0.5s ease-in-out",
  };

  const JoyModalSheetStyle_2 = {
    borderRadius: "md",
    fontFamily,
    px: 3,
    py: 4,
    background: isDark ? "var(--dark)" : "white",
    // boxShadow: isDark
    //   ? " 0 0 16px 3px rgba(255,255,255,0.1)"
    //   : "inset 0px 12px 16px -3px rgba(0,0,0,0.1),  0px 6px 10px -3px rgba(0,0,0,0.1)",
    boxShadow: isDark
      ? " 0 0 16px 3px rgba(255,255,255,0.1)"
      : " 0px 12px 16px -3px rgba(0,0,0,0.1),  0px 6px 10px -3px rgba(0,0,0,0.1)",
    border: isDark ? "var(--gray)" : "none",
    outline: "none",
    maxHeight: "90vh",
    overflowY: "auto",
    overflowX: "hidden",
    maxWidth: "90vw",
    width: {
      xs: "90vw",
      md: "80vw",
      lg: "60vw",
      xl: "40vw",
    },
  };

  const JoyModalCloseStyle_2 = {
    boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2) !important",
    borderRadius: "50%",
    // bgcolor: "background.body",
    // background: isDark ? "var(--main-color)" : "white",
    background: "white",
    // borderColor: "#f1f1f1 !important",
    BorderColor: "var(--light) !important",
    opacity: 1,
    // "--Icon-color": "red",
    fontFamily,
    // ".MuiModalClose-colorContext": {
    //   fill: isDark ? "var(--main-color)" : "black",
    // },
    // ".MuiModalClose-root .MuiSvgIcon-root": {
    //   color: "red" /* Change this to your desired color */,
    // },
    // ".MuiModalClose-root": {

    // },
    // minWidth: "1rem",
    // minHeight: "1rem",
  };

  const MuiPagenationStyle_2 = {
    fontSize: "1rem",
    fontFamily,
    ".MuiPaginationItem-root.Mui-selected": {
      backgroundColor: "var(--dark-color)",
      color: "white",
    },
    ".MuiPaginationItem-root": {
      color: isDark ? "white" : "black",
      fontFamily,
      fontSize: "1rem",
    },
  };

  const MuiDrawerTheme = createTheme({
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            background: isDark ? "var(--dark)" : "white",
          },
        },
      },
    },
  });

  const MuiSliderTheme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          root: {
            ".MuiSlider-thumb": {
              background: "white",
            },
            ".MuiSlider-rail": {
              background: "var(--light)",
              height: "10px",
            },
            ".MuiSlider-track": {
              background: "var(--main-color)",
              height: "10px",
            },
            ".MuiSlider-thumb:hover": {
              boxShadow: isDark
                ? "0px 0px 0px 8px rgba(255, 255, 255, 0.16)"
                : "0px 0px 0px 8px rgba(0, 0, 0, 0.16)",
            },
          },
        },
      },
    },
  });

  return {
    MuiSliderTheme,
    MuiBreadCrumbsTheme,
    MuiDrawerTheme,
    MuiPagenationStyle_2,
    JoyGrayShadeInputStyle2_2,
    MUItextFieldErrorTheme_2,
    MUItextFieldTheme_2,
    JoyInputError_2,
    JoyInputStyle_2,
    MenuButtonTheme_2,
    MenuActiveButtonTheme_2,
    LightButtonTheme_2,
    TabsStyle_2,
    ButtonTheme_2,
    ButtonActiveTheme_2,
    TransParentButton_2,
    GoogleButtonTheme_2,
    FacebookButtonTheme_2,
    AppleButtonTheme_2,
    JoyGrayShadeInputStyle_2,
    TableTheme_2,
    AccordionTheme_2,
    JoyModalSheetStyle_2,
    JoyModalCloseStyle_2,
    JoyModalStyle_2,
    CheckBoxStyle,
    MuiTableBox,
    MuiAccordionTheme,
    slotPropsTheme,
  };
};

export default Theme;

export { Themes };
